<template>
  <div
    class="input_field"
    :class="{ 'input-field-light-error': socialAccountsValidation }"
  >
    {{ $route.name }}
    <template v-if="isWorkspaceOnHold">
      <div class="input_field">
        <div class="warning_box warning-alert">
          <p v-if="hasPermission('can_change_hold_status')"
            ><i class="fa fa-warning"></i> Note: You cannot select accounts
            because you've paused publishing for this workspace,
            <router-link :to="{ name: 'workspaces' }" class="ml-2"
              >Click here to resume publishing
            </router-link>
          </p>
          <p v-if="!hasPermission('can_change_hold_status')"
            ><i class="fa fa-warning"></i> Note: You cannot select accounts
            because the admin of this workspace has paused publishing for this
            workspace.
          </p>
        </div>
      </div>
    </template>

    <template v-if="!isWorkspaceOnHold">
      <template v-if="checkAccountDisable(type, false)">
        <template
          v-if="
            getPublishSelection.socialPlansDetail[0]['status'] === 'published'
          "
        >
          <label>Social Post is posted on that platform</label>
        </template>
        <template
          v-else-if="
            getPublishSelection.socialPlansDetail[0]['status'] === 'failed'
          "
        >
          <label>Social Post is failed on that platform</label>
        </template>
        <template
          v-else-if="
            getPublishSelection.socialPlansDetail[0]['status'] === 'rejected'
          "
        >
          <label>Social Post is Rejected on that platform</label>
        </template>
        <template v-else-if="checkSocialMissedReview()">
          <label>Social Post is Missed Reviewed on that platform</label>
        </template>
      </template>
      <template v-else>
        <label v-if="showLabel"
          >Please select account(s) from social networks below</label
        >
      </template>

      <div v-if="totalAddedAccountsCount > 0" class="social_accounts_list">
        <template v-if="showAllAccounts">
          <template v-for="(channel, index) in social_channels_list" :key="`channelList_${index}`">
            <template v-if="checkValidForYoutubeTiktok(channel)">
              <template v-for="account in getChannelItems(channel)" :key="account._id">
                <div
                  v-if="
                    checkApprover(account[channel.key]) &&
                    disableChannelInAutomation(channel, account)
                  "
                  class="checkbox_input_image checkbox_social_profile"
                  style="position: relative"
                >
                  <i
                    v-if="showExpiredWarning(account) &&
                      commonMethods.isFacebookGroup(account)"
                    v-tooltip.top="'Direct publishing is not available for Facebook Groups. Please install the ContentStudio app on your mobile device to post via push notification.'"
                    class="fas fa-exclamation warning_icon_sm"
                  >
                  </i>
                  <i
                    v-else-if="showExpiredWarning(account)"
                    v-tooltip.top="
                      'The access token for this social account has been invalidated, please reconnect to continue posting.'
                    "
                    class="fas fa-exclamation warning_icon_sm"
                  >
                  </i>
                  <i
                    v-if="
                      module === 'automation' && channel.name === 'pinterest'
                    "
                    v-tooltip.top="
                      'Pinterest has been removed from automation campaigns to comply with Pinterest\'s policies.'
                    "
                    class="fas fa-exclamation warning_icon_sm"
                  >
                  </i>
                  <i
                    v-if="
                      commonMethods.showInstagramWarning(
                        getMobileDevices,
                        account,
                      )
                    "
                    v-tooltip.top="
                      'Mobile Device is not connected, however you can still post via Instagram API'
                    "
                    class="fas fa-exclamation warning_icon_sm"
                  />
                  <i
                      v-if="channel.name === 'twitter' && !account.developer_app_id"
                      v-tooltip.top="
                      'For X (Twitter) automation, use a Custom Twitter App. This account is connected via ContentStudio app.'
                    "
                      class="fas fa-exclamation warning_icon_sm"
                  />

                  <input
                    :id="'selection_' + account[channel.key]"
                    v-model="getAccountSelection[channel.name]"
                    :value="account[channel.key]"
                    type="checkbox"
                    :disabled="
                      (module === 'automation' &&
                        channel.name === 'pinterest') ||
                      checkAccountDisable(type) ||
                      getUserRole === 'Approver' ||
                      showExpiredWarning(account) ||
                      ($route.name !== 'composerSocial' &&
                        $route.name !== 'media-library' &&
                        channel.name === 'youtube') ||
                        (channel.name === 'twitter' && !account.developer_app_id)
                    "
                    @change="
                      accountSelectionChanges(
                        `${channel.name}_tab`,
                        account[channel.key],
                      )
                    "
                  />

                  <label
                    v-tooltip="{
                      content: channelTooltipHTML(channel.name, account),
                      classes: 'team_tooltip',
                      allowHTML: true,
                      theme: 'light',
                    }"
                    :for="'selection_' + account[channel.key]"
                    class="checkbox_right"
                    data-cy="account-selection"
                  >
                    <individual-channel-tooltip
                      :account="account"
                      :type="channel.name"
                    ></individual-channel-tooltip>
                  </label>
                </div>
              </template>
            </template>
          </template>

          <div
            v-if="!checkAccountDisable(type) && getUserRole !== 'Approver'"
            class="selection_option"
          >
            <span
              class="checkAllAccountSelection"
              data-cy="all-accounts"
              @click="checkAllAccountSelection(true)"
              >Select all</span
            >
            |
            <span
              class="unCheckAllAccountSelection"
              data-cy="none-accounts"
              @click="checkAllAccountSelection(false)"
              >None</span
            >
          </div>
        </template>

        <template v-if="!showAllAccounts">
          <div
            data-cy="posting-platform-selection"
            class="prevent_close_dropdown social_dropdown platform_filters_planner platform_filters_dd dropdown option_dropdown default_style_dropdown"
          >
            <div
              data-display="static"
              style="max-height: 37px"
              class="dropdown_header d-flex align-items-center"
              data-toggle="dropdown"
            >
              <div class="text d-inline-block align-middle">
                <template v-if="accountSelectionCount < 1">
                  <i class="align-middle far fa-user icon_first"></i>
                  <span class="align-middle" data-cy="social-dropdown"
                    >Select Account(s)</span
                  >
                </template>
                <!-- for the blog channels tooltip -->
                <div class="circle_boxes_inline">
                  <!-- for the social channels tooltip -->
                  <template v-for="(channel, index) in social_channels_list" :key="`social_${index}`">
                    <template v-if="checkValidForYoutubeTiktok(channel)">
                    <template v-for="(account, i) in getChannelItems(channel)" :key="index + '_' + i">
                      <template v-if="disableChannelInAutomation(channel, account)">
                        <span
                          v-if="filterPlatformVisibleStatus(account)"
                          v-tooltip="{
                            content: channelTooltipHTML(channel.name, account),
                            classes: 'team_tooltip',
                            allowHTML: true,
                            theme: 'light',
                          }"
                        >
                          <individual-channel-tooltip
                            :account="account"
                            :type="channel.name"
                            :flex_class="'d-flex align-items-start'"
                          ></individual-channel-tooltip>
                        </span>
                      </template>
                    </template>
                    </template>
                  </template>

                  <div
                    v-if="accountSelectionCount > 5"
                    class="profile_picture d-flex align-items-start"
                  >
                    <div class="picture_block picture_block_background">
                      <div class="img text"
                        >+{{ accountSelectionCount - 5 }}</div
                      >
                    </div>
                  </div>
                </div>
              </div>
              <span class="ml-auto">
                <i class="dropdown_arrow icon_last cs-angle-down"></i>
              </span>
            </div>

            <div
              class="dropdown-menu platform_filters_dropdown_inner dropdown-menu-left"
            >
              <ul class="inner">
                <template v-if="filteredplatformsCount > 0 || search !== ''">
                  <div class="search-bar-input m-3">
                    <div class="search-bar-inner">
                      <input
                        id="platform_selection_search_input"
                        v-model="search"
                        class="full_label"
                        type="text"
                        placeholder="Search by name"
                      />
                      <div class="search_btn">
                        <i class="icon-Search"></i>
                      </div>

                      <button
                        v-if="search !== ''"
                        class="clear-search-input"
                        @click="clearInput"
                      >
                        <i class="cs-cross"></i>
                      </button>
                    </div>
                  </div>

                  <hr class="w-100" />

                  <div
                    v-if="search === '' && getUserRole !== 'Approver'"
                    class="select_all"
                  >
                    <div class="search_inner">
                      <div class="field_group">
                        <div class="checkbox_container">
                          <label
                            for="platform_selection_check_all"
                            class="checkbox_right"
                          >
                            <span class="checkAllAccountSelection"
                              >Select All</span
                            >
                            <span class="unCheckAllAccountSelection"></span>
                            <input
                              id="platform_selection_check_all"
                              v-model="check_all"
                              data-cy="platform_selection_check_all"
                              type="checkbox"
                              @change="checkAllAccountSelection(check_all)"
                            />
                            <span class="check"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-for="(channel, channelIndex) in social_channels_list" :key="`channelIndex_${channelIndex}`">
                  <template v-for="(account, key) in getChannelItems(channel)" :key="key + '_' + channel.name">
                    <template
                      v-if="
                        checkValidForYoutubeTiktok(channel)"
                    >
                      <li
                        v-if="
                          showPlatformSelection(account) &&
                          checkApprover(account[channel.key]) &&
                          disableChannelInAutomation(channel, account)
                        "
                        :data-key="key + '_' + channel.name"
                        class="list_item_li"
                      >
                        <div class="field_group">
                          <div class="checkbox_container">
                            <i
                                v-if="showExpiredWarning(account) && commonMethods.isFacebookGroup(account)"
                                v-tooltip.top="'Direct publishing is not available for Facebook Groups. Please install the ContentStudio app on your mobile device to post via push notification.'"
                                class="fas fa-exclamation icon_right mt-2"
                            >
                            </i>
                            <i
                              v-else-if="showExpiredWarning(account)"
                              v-tooltip.top="
                                'The access token for this social account has been invalidated, please reconnect to continue posting.'
                              "
                              class="fas fa-exclamation icon_right mt-2"
                            >
                            </i>
                            <i
                              v-if="
                                module === 'automation' &&
                                channel.name === 'pinterest'
                              "
                              v-tooltip.top="
                                'Pinterest has been removed from automation campaigns to comply with Pinterest\'s policies.'
                              "
                              class="fas fa-exclamation icon_right mt-2"
                            >
                            </i>
                            <i
                              v-if="
                                commonMethods.showInstagramWarning(
                                  getMobileDevices,
                                  account,
                                )
                              "
                              v-tooltip.top="
                                'Mobile Device is not connected, however you can still post via Instagram API'
                              "
                              class="fas fa-exclamation warning_icon_sm_2"
                            />
                            <i
                                v-if="channel.name === 'twitter' && !account.developer_app_id"
                                v-tooltip.top="
                      'For X (Twitter) automation, use a Custom Twitter App. This account is connected via ContentStudio app.'
                    "
                                class="fas fa-exclamation icon_right mt-2"
                            />
                            <div
                              v-if="
                                showExpiredWarning(account) ||
                                (module === 'automation' &&
                                  channel.name === 'pinterest') ||
                                  (channel.name === 'twitter' && !account.developer_app_id)
                              "
                            >
                              <label
                                :for="
                                  'platform_selection_' + account[channel.key]
                                "
                                class="checkbox_right"
                                data-cy="select-account"
                              >
                                <input
                                  v-if="getUserRole !== 'Approver'"
                                  :id="
                                    'platform_selection_' + account[channel.key]
                                  "
                                  :data-cy="
                                    'platform_selection_' + account[channel.key]
                                  "
                                  :value="account[channel.key]"
                                  type="checkbox"
                                  :disabled="true"
                                />
                                <input
                                  v-else
                                  :id="
                                    'platform_selection_' + account[channel.key]
                                  "
                                  v-model="getAccountSelection[channel.name]"
                                  :data-cy="
                                    'platform_selection_' + account[channel.key]
                                  "
                                  :value="account[channel.key]"
                                  type="checkbox"
                                  :disabled="(getUserRole !== 'Approver')"
                                  @change="
                                    accountSelectionChanges(
                                      `${channel.name}_tab`,
                                      account[channel.key],
                                    )
                                  "
                                />
                                <!--                              <span class="check"></span>-->
                                <individual-channel-dropdown
                                  :account="account"
                                  :type="channel.name"
                                  :account_name="platformText(account)"
                                  :account_type="account.type"
                                  :integration="'social'"
                                ></individual-channel-dropdown>
                              </label>
                            </div>
                            <div v-else>
                              <label
                                :for="
                                  'platform_selection_' + account[channel.key]
                                "
                                class="checkbox_right"
                                data-cy="select-account"
                              >
                                <input
                                  :id="
                                    'platform_selection_' + account[channel.key]
                                  "
                                  v-model="getAccountSelection[channel.name]"
                                  :data-cy="
                                    'platform_selection_' + account[channel.key]
                                  "
                                  :value="account[channel.key]"
                                  type="checkbox"
                                  :disabled="
                                    checkAccountDisable(type) ||
                                    getUserRole === 'Approver' ||
                                    ($route.name !== 'composerSocial' &&
                                      $route.name !== 'media-library' &&
                                      channel.name === 'youtube')
                                  "
                                  @change="
                                    accountSelectionChanges(
                                      `${channel.name}_tab`,
                                      account[channel.key],
                                    )
                                  "
                                />
                                <span
                                  class="check"
                                  :class="{
                                    'border-0': getUserRole === 'Approver',
                                  }"
                                ></span>
                                <individual-channel-dropdown
                                  :account="account"
                                  :type="channel.name"
                                  :account_name="platformText(account)"
                                  :account_type="account.type"
                                  :integration="'social'"
                                ></individual-channel-dropdown>
                              </label>
                            </div>
                          </div>
                        </div>
                        <!--                      <div class="mx-12" style="border-bottom: 1.5px solid #807c7c;"></div>-->
                      </li>
                    </template>
                  </template>
                </template>

                <li v-if="filteredplatformsCount === 0" class="p-3 text-center">
                  <p v-if="search"
                    >No results found for your search query, please try again</p
                  >
                  <div v-else>
                    <p class="mb-3 mt-2"
                      >You have not connected any account yet.</p
                    >
                    <template v-if="hasPermission('can_save_social')">
                      <router-link
                        :to="{ name: 'social' }"
                        class="btn btn-studio-theme-space"
                      >
                        <span>Connect an account</span>
                      </router-link>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <div v-if="getAccountSelection.twitter.length > 0">
          <CstAlert type="warn" class="text-left !py-2.5 mt-4">Your X (Twitter) Custom App has a daily posting limit. Exceeding these limits will result in failed posts. Free App allows ~17 posts/day; Basic App allows ~1,600 posts/day.</CstAlert>
        </div>
      </div>

      <div v-else>
        <span>You have not connected any account yet.</span>
        <a
          class="ml-2"
          href=""
          style="text-decoration: underline"
          @click.prevent="GoToSocial"
          >Connect an account</a
        >
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import {
  socialChannelsArray,
  socialChannelsNameArray,
} from '@common/constants/common-attributes'
import { getPlatformName } from '@common/lib/integrations'
import { commonMethods } from '@common/store/common-methods'
import {
  social,
  sharingTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { EventBus } from '@common/lib/event-bus'
import CstAlert from "@ui/Alert/CstAlert.vue";

export default {
  components: {CstAlert},
  props: {
    showPublishAs: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    module: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
    type: {},
    socialAccountsValidation: {
      type: Boolean,
      default: false,
    },
    blog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      check_all: false,
      social_channels_list: socialChannelsArray,
      commonMethods,
      excludedChannels: ['youtube', 'tiktok', 'bluesky'],
      threadsNotAllowedOn: ['saveRssAutomation', 'saveArticleSocialAutomation', 'saveVideoSocialAutomation']
    }
  },

  computed: {
    ...mapGetters([
      'getAccountSelection',
      'getBlogPostingDetails',
      'getPublishSelection',
      'getWorkspaces',
      'getMobileDevices',
      'getFacebookPostedAs',
      'getSocialSharingFirstComment',
    ]),
    // Checking if the user has connected any accounts or not, if not, we will show the message to connect the accounts
    totalAddedAccountsCount() {
      let count = 0
      socialChannelsArray.forEach((item) => {
        const accountsGetter = this.$store.getters[item.getter]
        // eslint-disable-next-line no-prototype-builtins
        count += accountsGetter.hasOwnProperty('items')
          ? accountsGetter.items && accountsGetter.items.length
          : accountsGetter.length
      })
      console.log(`[accounts connected total] ${count}`)
      return count
    },

    showAllAccounts() {
      if (this.showPublishAs === true) return false
      return this.totalAddedAccountsCount <= 15
    },

    filteredplatformsCount() {
      let platformsCount = 0
      this.social_channels_list.forEach((item) => {
        const getter = this.$store.getters[item.getter]
        // eslint-disable-next-line no-prototype-builtins
        const itemsList = getter.hasOwnProperty('items') ? getter.items : getter
        platformsCount += itemsList.filter((account) =>
          this.showPlatformSelection(account)
        ).length
      })
      return platformsCount
    },

    accountSelectionCount() {
      let count = 0
      const accounts = this.$store.getters.getAccountSelection
      socialChannelsNameArray.forEach((channel, index) => {
        count += accounts[channel]?.length
      })
      return count
    },

    isWorkspaceOnHold() {
      return !!(
        this.getWorkspaces.activeWorkspace &&
        this.getWorkspaces.activeWorkspace.on_hold
      )
    },
    accountSelection() {
      return [
        ...this.getAccountSelection.facebook,
        ...this.getAccountSelection.gmb,
        ...this.getAccountSelection.instagram,
        ...this.getAccountSelection.linkedin,
        ...this.getAccountSelection.pinterest,
        ...this.getAccountSelection.tumblr,
        ...this.getAccountSelection.twitter,
        ...this.getAccountSelection.youtube,
        ...this.getAccountSelection.tiktok,
      ]
    },
  },
  watch: {
    accountSelectionCount() {
      if (this.accountSelectionCount > 0) {
        this.$emit('account-selected', false)
      } else {
        this.$emit('account-selected', true)
      }
    },
    $route: {
      immediate: true,
      handler(to) {
        if(this.module === 'automation') {
          if (this.threadsNotAllowedOn.includes(to.name)) {
          if (!this.excludedChannels.includes('threads')) {
            this.excludedChannels.push('threads');
          }
        } else {
            const index = this.excludedChannels.indexOf('threads');
            if (index > -1) {
            this.excludedChannels.splice(index, 1);
          }
          }
        }
      }
    }
  },
  mounted() {
    /* eslint-disable */
    this.$nextTick(() => {
      if (Object.keys(this.getFacebookPostedAs).length === 0) {
        this.getFacebookAccounts.items.forEach((account) => {
          if (commonMethods.isFacebookGroupWithPostedAs(account)) {
            account.posted_as.forEach((posted_as) => {
              if (posted_as.default === true) {
                this.changeFBGroupPostedAs(posted_as.id, account.facebook_id)
              }
            })
          }
        })
      }
    })
    /* eslint-enable */
  },

  methods: {
    ...mapActions([]),
    checkValidForYoutubeTiktok(channel) {
      console.log('Method::checkValidForYoutube ~ route name -> ', channel.name)
      if (channel.name === 'pinterest' && this.$route.name !== 'saveBulkCsvAutomation') return false
      if (!this.excludedChannels.includes(channel.name)) return true
      return !!(
        this.$route.name === 'composerSocial' ||
        ((this.$route.name === 'media-library' ||
          this.$route.name === 'feed_view' ||
          this.$route.name === 'list_plans' ||
          this.$route.name === 'calender_plans') &&
          !this.blog)
      )
    },
    disableChannelInAutomation(channel, account) {
      if (this.module === 'automation' ||
        this.$route.name === 'saveBulkCsvAutomation') {
        if (this.excludedChannels.includes(channel.name)
        ) {
          return false
        }
      }
      // if(channel.name === 'twitter') {
      //   // Enabled twitter automation for twitter accounts with custom apps
      //   return !!account.developer_app_id
      // }
      return true
      /* return ((channel.name !== 'youtube' || channel.name !== 'tiktok') && this.module !== 'automation'); */
    },
    getInstagramWarningText(account) {
      return `<div class='team_detail_tooltip'>
                  <div class='t_inner'>
                      <div class='t_content'>
                          <p style="margin-top: 0;font-size: 0.875rem;font-weight: normal;">Unable to post on ${account.name}, Mobile Device or Zapier needs to be connected in order to post on Instagram.</p>
                      </div>
                  </div>
              </div>`
    },
    /** all button for the social channels.
     *
     * @param status boolean
     */
    checkAllAccountSelection(status) {
      console.debug(
        'Method::checkAllAccountSelection',
        status,
        this.$route.name
      )
      const selection = {}

      // iterate over the social channels array with their getter.

      socialChannelsArray.forEach((channel, index) => {
        console.log('[select all] social channels', channel, index, status)

        // automations are not allowed on pinterest, YouTube, Twitter and TikTok
        if (this.module === 'automation' && (
            (channel.name === 'pinterest' && this.$route.name !== 'saveBulkCsvAutomation') ||
            channel.name === 'twitter' || this.excludedChannels.includes(channel.name))) return

        selection[channel] = []
        if (
          this.$route.name !== 'composerSocial' &&
          this.$route.name !== 'list_plans' &&
          this.$route.name !== 'calender_plans' &&
          this.$route.name !== 'media-library' &&
          channel.name === 'youtube'
        )
          return
        // if the status is on, the put all of them items to the local selection.
        if (status) {
          let accounts = this.$store.getters[channel.getter]
          // eslint-disable-next-line no-prototype-builtins
          accounts = accounts.hasOwnProperty('items')
            ? accounts.items
            : accounts
          if (channel.name === 'twitter' && accounts.length)
            accounts = [accounts[0]]
          if (channel.name === 'youtube' && accounts.length)
            accounts = [accounts[0]]
          const validAccounts = []
          accounts.forEach((item) => {
            if (!this.showExpiredWarning(item)) validAccounts.push(item)
          })
          selection[channel.name] = validAccounts

          document
            .querySelector('.checkAllAccountSelection')
            .classList.add('disabled')
          document
            .querySelector('.unCheckAllAccountSelection')
            .classList.remove('disabled')
        } else {
          if (!this.getCommonBoxOptions.status) {
            this.changeSharingBoxOption()
          }
          document
            .querySelector('.checkAllAccountSelection')
            .classList.remove('disabled')
          document
            .querySelector('.unCheckAllAccountSelection')
            .classList.add('disabled')
        }
      })

      // set the values
      this.$store.commit(social.SET_ACCOUNT_SELECTION, selection)
    },

    changeFBGroupPostedAs(postedId, accountId) {
      this.$store.commit('setFacebookPostedAs', {
        postedId,
        accountId,
      })
    },

    GoToSocial() {
      this.$router.push({ name: 'social' })
      // eslint-disable-next-line no-undef
      $('.modal').modal('hide')
    },
    checkApprover(accountKey) {
      console.log('method:checkApprover ~ accountKey -> ', accountKey)
      if (this.getUserRole === 'Approver') {
        return !!this.accountSelection.includes(accountKey)
      }
      return true
    },

    checkAccountDisable(type, message = true) {
      console.debug(type, message)
      console.debug('checkAccountDisable', this.getBlogPostingDetails.type)
      // type && type == 'composer' &&
      if (
        (this.getBlogPostingDetails.type === 'Composer Article' ||
          this.getBlogPostingDetails.type === 'Discovery Article' ||
          this.getBlogPostingDetails.type === 'Automation Article' ||
          this.getBlogPostingDetails.type === 'Discovery Video' ||
          this.getBlogPostingDetails.type === 'Automation Video') &&
        this.getPublishSelection.socialPlansDetail &&
        this.getPublishSelection.socialPlansDetail.length > 0 &&
        (this.getPublishSelection.socialPlansDetail[0].status === 'published' ||
          this.getPublishSelection.socialPlansDetail[0].status === 'failed' ||
          this.getPublishSelection.socialPlansDetail[0].status === 'rejected' ||
          (this.getPublishSelection.socialPlansDetail[0].status === 'review' &&
            this.getPublishSelection.socialPlansDetail[0].execution_time &&
            this.getPublishSelection.socialPlansDetail[0].execution_time.date <
              moment.utc().format('YYYY-MM-DD HH:mm:ss')))
      ) {
        return true
      } else if (
        type === 'socialComposer' &&
        message &&
        this.getPublishSelection.status === 'published'
      ) {
        return true
      } else if (message && this.getPublishSelection.queueStatus) {
        return true
      }
      return false
    },

    checkSocialMissedReview() {
      if (
        this.getPublishSelection.socialPlansDetail[0].status === 'review' &&
        this.getPublishSelection.socialPlansDetail[0].execution_time.date <
          moment.utc().format('YYYY-MM-DD HH:mm:ss')
      ) {
        return true
      }
      return false
    },
    accountSelectionChanges(tab, account) {
      console.debug('Method::accountSelectionChanges', tab, account)
      if (this.getPublishSelection.content_category_id) {
        EventBus.$emit('reset_content_category_selection')
      }
      this.$store.commit(sharingTypes.SET_SHARING_ACTIVE_TAB, tab)

      // if the tab is of twitter, we are only allowing individual account at the moment due to the twitter TOS
      // restriction for the duplicate content.

      // NOTE: remove this check once we add the variation part over here.

      if (tab === 'twitter_tab') {
        const selectedAccounts =
          this.getAccountSelection.twitter.indexOf(account) >= 0
            ? [account]
            : []
        console.log(`[account selection] twitter accounts: ${selectedAccounts}`)
        this.$store.commit(
          social.SET_TWITTER_ACCOUNT_SELECTION,
          selectedAccounts
        )
      }

      if (tab === 'youtube_tab') {
        const selectedAccounts =
          this.getAccountSelection.youtube.indexOf(account) >= 0
            ? [account]
            : []
        console.log(`[account selection] youtube accounts: ${selectedAccounts}`)
        this.$store.commit(
          social.SET_YOUTUBE_ACCOUNT_SELECTION,
          selectedAccounts
        )
        this.$store.commit(social.SET_YOUTUBE_PLAYLISTS, '')
      }

      if (this.getCommonBoxOptions.status) {
        this.setSharingBoxHtml(
          this.processSharingBoxHtml(
            this.getCommonSharingDetails.message,
            'Common'
          ),
          'Common'
        )
      }

      if (this.getAutomationScheduleOptions.time_type === 'Queue') {
        if (
          this.$route.name === 'saveArticleBlogAutomation' ||
          this.$route.name === 'saveArticleSocialAutomation' ||
          this.$route.name === 'saveVideoBlogAutomation' ||
          this.$route.name === 'saveVideoSocialAutomation'
        ) {
          this.$store.dispatch('fetchAutomationNextPosting', this.$route.name)
        }
      }
      this.allTextareaFocus()
    },

    platformText(platform) {
      console.debug('Method::platformText', platform)
      return getPlatformName(platform)
    },

    showPlatformSelection(account) {
      return (
        this.platformText(account)
          .toLowerCase()
          .indexOf(this.search.toLowerCase()) >= 0
      )
    },

    clearInput() {
      this.search = ''
    },

    filterPlatformVisibleStatus(platform) {
      let count = 0
      let status = false
      const accounts = this.$store.getters.getAccountSelection

      this.social_channels_list.forEach((channel) => {
        const getter = this.$store.getters[channel.getter]
        // eslint-disable-next-line no-prototype-builtins
        const channelItems = getter.hasOwnProperty('items')
          ? getter.items
          : getter

        if (!status) {
          channelItems.forEach((item) => {
            if (accounts[channel.name].includes(item[channel.key])) {
              count += 1
              if (platform[channel.key] === item[channel.key] && count < 6) {
                status = true
                return false
              }
            }
          })
        }
      })
      return status
    },

    showExpiredWarning(account) {
      return this.disableAccount(account)
    },
    checkForZapierAndPinterestAccount(account) {
      return (
        account &&
        account.linked_to &&
        account.linked_to.zapier &&
        account.board_id &&
        account.manually_added
      )
    },
  },
}
</script>
