import { cookieDomain } from '@src/config/api-utils'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
const vueCookie = require('vue-cookie')

const integrations = {
  /**
   * @deprecated
   * @returns {string[]}
   */
  socialPlatformNames() {
    return [
      'facebook',
      'twitter',
      'linkedin',
      'pinterest',
      'tumblr',
      'instagram',
      'gmb',
      'tiktok',
      'threads',
      'bluesky'
    ]
  },

  /**
   * @deprecated
   * @returns {string[]}
   */
  platformImage(account) {
    if (account) {
      if (account.image) {
        if (account.image.large) {
          return account.image.large.url
        }
        return account.image
      }
      if (account.picture) {
        return account.picture.url
      }
      if (account.profile_picture) {
        return account.profile_picture
      }
      if (account.imageUrl) {
        return account.imageUrl
      }
    }
    return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
  },

  /**
   * @deprecated
   * @returns {string[]}
   */
  pinterestProfileName(items, profile) {
    let name = ''
    items.forEach(function (account) {
      if (account.type === 'Profile' && account.pinterest_id === profile) {
        name = account.first_name + ' ' + account.last_name
        return false
      }
    })
    return name
  },

  /**
   * @deprecated
   * @returns {string[]}
   */
  getPlatformByID(items, key, value) {
    let platform = ''
    $(items).each(function (index, el) {
      if (el[key] === value) {
        platform = el
        return false
      }
    })

    return platform
  },
}
export default integrations

/**
 * @deprecated
 * @returns {string[]}
 */
function blogPlatformNames() {
  return ['wordpress', 'tumblr', 'medium', 'shopify', 'webflow']
}

/**
 *
 * @returns {string[]}
 */
function socialPlatformNames() {
  return [
    'facebook',
    'twitter',
    'linkedin',
    'pinterest',
    'tumblr',
    'instagram',
    'gmb',
    'youtube',
    'tiktok',
    'threads',
    'bluesky'
  ]
}

function platformImage(platform, type = '') {
  if (platform) {
    if (platform.image) {
      if (!platform.image.large) return platform.image
      if (platform.image.large.url) return platform.image.large.url
    }
    if (platform.platform_logo) return platform.platform_logo
    if (platform.picture) return platform.picture.url
    if (platform.profile_picture) return platform.profile_picture
    if (platform.imageUrl) return platform.imageUrl
    if (platform.location_name || type === 'gmb')
      return 'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
  }
  return 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
}

function pinterestProfileName(items, profile) {
  const item = items.find((item) => {
    return (
      item.type.toLowerCase() === 'profile' && item.pinterest_id === profile
    )
  })
  if (item) {
    if (!item.manually_added) return item.username
    return `${item.first_name} ${item.last_name}`
  }
}

function getPlatformByIdentifier(items, identifier, value) {
  const platform = items.find((item) => {
    return item[identifier] === value
  })
  return platform || ''
}

function getPlatformName(platform) {
  let name = ''
  if (platform) {
    if (platform.platform_name) return platform.platform_name
    if (platform.location_name) {
      name = platform.location_name + ' - ' + platform.locality
      return platform.postal_code ? name + ' - ' + platform.postal_code : name
    }
    // if (platform.pinterest_id) {
    //   return platform.first_name + ' ' + platform.last_name
    // }
    if(platform.account_name) return platform.account_name
    if (platform.name) {
      name = platform.name
      name = name.replace('profile_', '')
      name = name.replace('blog_', '')
      return name
    }
    if (platform.url) return platform.url
    if (platform.platform_url) return platform.platform_url
    if(platform.display_name) return platform.display_name

  }
  return name
}

function getPlatformIdentifierValue(platform, type) {
  if (platform) {
    switch (type) {
      case 'facebook':
      case 'twitter':
      case 'linkedin':
      case 'instagram':
        return platform[type + '_id']
      case 'pinterest':
        return platform.board_id
      case 'gmb':
        return platform.name
      case 'youtube':
      case 'tiktok':
      case 'tumblr':
      case 'webflow':
      case 'threads':
      case 'bluesky':
        return platform.platform_identifier
    }
  }
  return ''
}

function redirectOnboardingConnectorRequest(details, link, workspace) {
  details.workspace_id = workspace._id
  details.workspace_slug = workspace.slug
  vueCookie.set('connector_details', JSON.stringify(details), {
    expires: '1D',
    domain: cookieDomain,
  })
  window.location.href = link
  // window.open(link, 'targetWindow', 'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=550,height=550')
}

function getPlatformMutatedType(type, platform) {
  return type === 'tumblr' && !platform ? 'tumblr_blog' : type
}

function getPlatformItemClasses(type) {
  switch (type) {
    case 'tumblr':
    case 'tumblr_blog':
      return ['tum']
    case 'medium':
      return ['med']
    case 'shopify':
      return ['shopify']
    case 'facebook':
      return ['fb']
    case 'twitter':
      return ['tw']
    case 'linkedin':
      return ['lin']
    case 'pinterest':
      return ['pin']
    case 'instagram':
      return ['ins']
    case 'youtube':
      return ['you']
    case 'gmb':
      return ['google_b']
    case 'webflow':
      return ['wf']
    case 'threads':
      return ['ths']
    case 'bluesky':
      return ['bls']
  }
}

function getPlatformIconClasses(type) {
  switch (type) {
    case 'wordpress':
      return ['cs-wordpress']
    case 'tumblr':
    case 'tumblr_blog':
      return ['fab fa-tumblr']
    case 'medium':
      return ['fab fa-medium-m']
    case 'facebook':
      return ['fab fa-facebook-f']
    case 'twitter':
      return ['fab fa-twitter']
    case 'linkedin':
      return ['fab fa-linkedin-in']
    case 'pinterest':
      return ['fab fa-pinterest-p']
    case 'instagram':
      return ['fab fa-instagram']
    case 'youtube':
      return ['fab fa-youtube']
    case 'gmb':
      return ['fab fa-google']
    case 'shopify':
      return ['shopify_icon']
    case 'webflow':
      return ['webflow_icon']
    case 'threads':
      return ['threads_icon']
    case 'bluesky':
      return ['bluesky_icon']
  }
}

function getPlatformDetails(item, type, platform) {
  if (platform) return platform
  return blogIntegrationsNames.includes(type)
    ? getPlatformByIdentifier(
        this.$store.getters.getBlogs[type].items,
        'platform_identifier',
        item.blog_selection[type].website
      )
    : platform
}

export {
  blogPlatformNames,
  socialPlatformNames,
  platformImage,
  pinterestProfileName,
  getPlatformByIdentifier,
  getPlatformName,
  getPlatformIdentifierValue,
  getPlatformMutatedType,
  getPlatformItemClasses,
  getPlatformIconClasses,
  getPlatformDetails,
  redirectOnboardingConnectorRequest,
}
