<script setup>
import { inject, onMounted, ref, nextTick, computed} from 'vue'
import { useRoute } from 'vue-router'
import CstButton from '@ui/Button/CstButton'
import SaveSocialAccounts from '@src/modules/integration/components/dialogs/SaveSocialAccounts'
import AccountsNotAllowedModal from '@src/modules/integration/components/modals/AccountsNotAllowedModal.vue'
import { useSteps, useOnboarding } from '@src/modules/account/composables/useUserOnboarding'
import WelcomeOnboard from "@modules/account/views/onboarding/WelcomeOnboard.vue";
import {userMaven} from "@src/tracking/userMavenMixin";
import VideoIntro from '@/src/modules/account/views/onboarding/VideoIntro.vue'
import SocialConnect from '@/src/modules/account/views/onboarding/SocialConnect.vue'
import useSocialAccountModal from '@/src/modules/account/composables/useSocialAccountsModal'
import useWorkspace from '@/src/modules/common/composables/useWorkspace'
import { useStore } from '@/src/store/base'
import {timezones} from "@modules/setting/config/timezone";
import {EventBus} from "@common/lib/event-bus";

// declarations
const store = useStore()
const route = useRoute()
const steps = ref(store.getters.getActiveWorkspace?.onboarding_steps ?? {})
const signup_on_boarding = ref(store.getters.getProfile?.signup_on_boarding ?? {})
const { currentStep, stepsList, handleSteps } = useSteps()
const { userCredentials, localTimezone, onboardingLoader, resetUserCredentials } = useOnboarding()
let modalTimeout
const root = inject('root')
const { $bvModal } = root
const { onboardingStepsCompleted, isActiveUserSuperAdmin } = useWorkspace()
const {
  hasConnectedAccounts,
  fetching,
  buttonText,
  OPTIONS,
  selectedOption,
  processReconnectResponse,
} = useSocialAccountModal()

const childRef = ref(null)

// methods
const handleButtonClick = async () => {

  userMaven.track('onboarding_completed')
  _cio.track('onboarding_completed')

  if (!signup_on_boarding.value?.is_completed) {
    signup_on_boarding.value.is_completed = true
  }

  if(selectedOption.value === OPTIONS.VIDEO) {
    selectedOption.value = OPTIONS.SOCIAL_CONNECT
    $bvModal.hide('get-started-modal')
  }

  if(buttonText.value === 'Skip & Continue' || buttonText.value === `Let's Go`) {
    if(!steps.value?.watch_video?.status) {
      selectedOption.value = OPTIONS.VIDEO
    }
    await handleSteps(true, currentStep.value)
  }

  // In case of other team members we will show the video modal
  if(!isActiveUserSuperAdmin() && !steps.value?.watch_video?.status) {
    selectedOption.value = OPTIONS.VIDEO
  }

  if (selectedOption.value === OPTIONS.SOCIAL_CONNECT) {
    selectedOption.value = OPTIONS.SOCIAL_CONNECT
    $bvModal.hide('get-started-modal')
    return 0
  }

  if (selectedOption.value === OPTIONS?.VIDEO) {
    if (!steps.value.watch_video) {
      steps.value.watch_video = {}
    }
    steps.value.watch_video.status = true
    fetching.value = true
    onboardingStepsCompleted('watch_video')
    onboardingStepsCompleted('accounts_connection_modal_closed')
    fetching.value = false
  }
}

const onHide = () => {
  selectedOption.value = OPTIONS.SOCIAL_CONNECT
}

const showNextModal = () => {
  $bvModal.hide('get-welcome-modal')
  nextTick(() => {
    $bvModal.show('get-started-modal')
  })
}

const closeWelcomeModal = () => {
  clearTimeout(modalTimeout)
  showNextModal()
}

/**
 * This method is responsible for user onboarding state computation
 */
const computeUserOnboardingState = async () => {
  console.log('computeUserOnboardingState 1', signup_on_boarding.value, selectedOption.value )
  resetUserCredentials()

  stepsList.currentStepIndex = 0

  localTimezone.value = store.getters.getActiveWorkspace.timezone
  const fullName = (`${store.getters.getProfile?.firstname || ''} ${store.getters.getProfile?.lastname || ''}`)

  const selectedTimezone = timezones.find((timezone) => timezone.value === store.getters.getActiveWorkspace?.timezone)

  userCredentials.timezone = selectedTimezone?.value || ''
  userCredentials.firstname = userCredentials.firstname || fullName?.length > 1 ? fullName : ''
  userCredentials.lastname = userCredentials.lastname || store.getters.getProfile.lastname
  userCredentials.phone_no = userCredentials.phone_no || store.getters.getProfile.phone_no
  userCredentials.business_type = userCredentials.business_type || store.getters.getProfile.business_type

  if (signup_on_boarding.value && 'is_completed' in signup_on_boarding.value) {
    if (signup_on_boarding.value.is_completed && userCredentials?.firstname?.length) {
      stepsList.currentStepIndex = stepsList.steps.findIndex(step => step.key === 'social_connect');
      return;
    }

    const { credentials, business_type, social_connect } = signup_on_boarding.value.steps || {};

    if (credentials && !business_type) {
      stepsList.currentStepIndex = stepsList.steps.findIndex(step => step.key === 'business_type');
      $bvModal.show('get-started-modal')
      return
    } else if (business_type) {
      stepsList.currentStepIndex = stepsList.steps.findIndex(step => step.key === 'social_connect');
      $bvModal.show('get-started-modal')
      return
    } else if (!social_connect && business_type) {
      stepsList.currentStepIndex = stepsList.steps.findIndex(step => step.key === 'social_connect');
      $bvModal.show('get-started-modal')
      return;
    }


    $bvModal.show('get-welcome-modal')
    modalTimeout = setTimeout(showNextModal, 3000)
    console.log('computeUserOnboardingState 2', signup_on_boarding.value, stepsList.currentStepIndex, selectedOption.value )


  }
}

const modalDialogClass = computed(() => {
  const baseClasses = '!max-w-[60rem] 2xl:!max-w-[73rem] get-started-modal'
  return selectedOption.value !== OPTIONS.VIDEO
      ? `${baseClasses} hide-modal-close-button`
      : baseClasses
})

onMounted(async () => {
  EventBus.$on('workspace-changed', async () => {
    steps.value = store.getters.getActiveWorkspace?.onboarding_steps ?? {}
  })

  await computeUserOnboardingState()

  const response = await store.dispatch('checkConnectorState', {
    hash: route.hash,
  })

  await processReconnectResponse(response)
})

// Book a demo
const handleBookADemo = () => {
  window.open('https://contentstudio.io/book-a-demo', '_blank')
}
</script>

<template>
  <AccountsNotAllowedModal />
  <SaveSocialAccounts />
  <WelcomeOnboard :on-close="closeWelcomeModal" />
  <b-modal
      id="get-started-modal"
      body-class="!p-0"
      :dialog-class="modalDialogClass"
      size="xl"
      centered
      no-close-on-backdrop
      hide-footer
      no-close-on-esc
      @hide="onHide"
  >
    <div class="bg-white h-[32rem] 2xl:h-[41rem] rounded-tl-2xl rounded-tr-2xl">
      <!-- Body -->
      <VideoIntro v-if="selectedOption === OPTIONS.VIDEO" video-id="uAuiDkRGYhI" />

      <div v-else class="h-full">
        <!-- Dynamic Steps component importing -->
        <div v-if="signup_on_boarding?.steps && stepsList.currentStepIndex <= 2" class="flex items-center h-full rounded-md">
          <component :is="currentStep.component" ref="childRef" />
        </div>
        <div v-else class="h-full rounded-md">
          <component :is="SocialConnect" />
        </div>
        <!-- Dynamic Steps component importing end -->

      </div>
    </div>

    <!-- Steps progress -->
    <div class="flex justify-between items-center">
      <template v-if="signup_on_boarding?.steps && !signup_on_boarding?.is_completed">
        <div
            v-for="(step, index) in stepsList.steps.length"
            :key="index"
            :class="[
                  stepsList.currentStepIndex >= index ? 'bg-[#2A70FC]' : 'bg-gray-300',
                ]"
            class="h-[0.27rem] flex-grow transition-colors duration-300 ease-in-out">
        </div>
      </template>
    </div>
    <!-- Steps progress end -->
    <!-- Footer -->
    <div v-if="signup_on_boarding?.steps && !signup_on_boarding?.is_completed"  class="flex items-center justify-between h-[5rem] gap-x-2 px-[2rem] bg-white rounded-bl-[1rem] rounded-br-[1rem]">
      <CstButton
          variant="primary"
          class="
        !bg-[#202324]
        !text-white
        !border-black-500 !border-2
        !rounded-lg
        !text-xs
        2xl:!text-sm
        !px-4
        font-normal
      "
          @click="handleBookADemo"
      >
        <div class="flex gap-[0.625rem]">
          <img
              class="h-4 w-4"
              :src="require('@assets/img/onboarding/calendar.svg')"
              alt="calendar"
          />
          <p class="-mt-[0.8px]"> Book a demo </p>
        </div>
      </CstButton>
      <div class="flex items-center gap-x-3">
        <CstButton
            v-if="stepsList.currentStepIndex > 0"
            size="default"
            variant="outlined"
            class="h-[2.6rem] px-[2rem] flex items-center !rounded-lg !border !border-[#D2D5DF] !bg-[#FBFBFB]"
            @click="handleSteps(false, currentStep)"
        >
          <p class="leading-4 font-normal text-[#454545]">Back</p>
        </CstButton>
        <CstButton
            v-if="stepsList.currentStepIndex < 2"
            size="default"
            variant="primary"
            text="Next"
            class="h-[2.6rem] px-[2rem] flex align-items-center !rounded-lg !border-primary-cs"
            :disabled="onboardingLoader"
            :loading="onboardingLoader"
            @click.prevent="handleSteps(true, currentStep)"
        />
        <CstButton
            v-else
            size="default"
            :variant="hasConnectedAccounts ? 'outlined' : 'primary'"
            :text="fetching ? 'Please wait...' : buttonText"
            class="h-[2.6rem] px-[2rem] flex align-items-center !rounded-lg !border-primary-cs"
            :disabled="fetching"
            @click.prevent="handleButtonClick"
        />
      </div>
    </div>
    <div v-else class="flex items-center justify-center border-top h-[5rem] gap-x-2 px-[2rem] bg-white rounded-bl-[1rem] rounded-br-[1rem]">
      <CstButton
          size="default"
          variant="primary"
          text="Continue"
          class="h-[2.6rem] px-[2rem] flex align-items-center !rounded-lg !border-primary-cs"
          :disabled="fetching"
          @click.prevent="handleButtonClick"
      />
    </div>
  </b-modal>
</template>

<style>
#get-started-modal___BV_modal_outer_ {
  z-index: 1051 !important;
}

#save-social-accounts___BV_modal_outer_ {
  z-index: 1052 !important;
}
#add-twitter-account-modal___BV_modal_outer_ {
  z-index: 1053 !important;
}

#add-bluesky-modal___BV_modal_outer_ {
  z-index: 1053 !important;
}

#accounts_not_allowed_modal___BV_modal_outer_ {
  z-index: 1053 !important;
}

#removeIntegration___BV_modal_outer_ {
  z-index: 1053 !important;
}
</style>

<style lang="scss">
.get-started-modal {
    .modal-content {
      background: transparent !important;
    }

    .modal-header {
      padding: 0 !important;
      background: transparent !important;
      border: none !important;
      position: relative;

      .close {
        position: absolute;
        background: white !important;
        margin: 0 !important;
        right: -10px;
        top: -10px;
        z-index: 9999;
        border-radius: 100% !important;
        width: 29px !important;
        height: 10px !important;
        padding-bottom: 16px !important;
      }
    }
}

.hide-modal-close-button {
  .modal-header {
    .close {
      display: none;
    }
  }
}
</style>
