import { useStore } from '@state/base'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
import { computed } from 'vue'
import GridIcon from '@assets/img/analytics/sidebar/grid.svg'
import YoutubeIcon from '@assets/img/integration/youtube.png'
import TwitterIconX from '@assets/img/integration/twitter-x-square.svg'
import TiktokIcon from '@assets/img/integration/tiktok-icon.svg'
import UserMavenIcon from '@assets/img/integration/usermaven_icon.svg'
import DownloadIcon from '@assets/img/analytics/sidebar/download_reports.svg'
import ScheduleIcon from '@assets/img/analytics/sidebar/scheduled_report.svg'
import SettingsIcon from '@assets/img/analytics/sidebar/setting_reports.svg'
import LineChartIcon from '@assets/img/analytics/sidebar/line-chart.svg'

/**
 * A composable function for managing analytics routes.
 * @returns {{
 *   socialAnalyticsRoutes: any,
 *   competitorAnalyticsRoutes: any,
 *   manageReportRoutes: any,
 *   performanceReportRoutes: any,
 *   webAnalyticsRoutes: any,
 * }}
 */
export default function useAnalyticsRoutes() {
  const { getters } = useStore()
  const {
    analyticsEnabledTwitterAccounts,
    getAllPinterestProfiles,
  } = useComposerHelper()

  const defaultConfig = computed(
    () => socialRouteConfigurations.value?.overview
  )

  // Configuration for social analytics routes
  const socialRouteConfigurations = computed(() => ({
    overview: {
      title: 'Overview',
      icon: getSidebarIcons('overview'),
      iconWrapperClass:
        'bg-[#E6F0FC] rounded-md inline-flex justify-center items-center mr-4',
      routeName: 'analytics_overview_v3',
      getParams: () => ({}),
      isNew: false,
      show: true,
    },
    facebook: {
      title: 'Facebook',
      icon: getSidebarIcons('facebook'),
      iconWrapperClass: '',
      routeName: 'facebook_analytics_v3',
      getParams: getFacebookParams,
      isNew: true,
      show: true,
    },
    instagram: {
      title: 'Instagram',
      icon: getSidebarIcons('instagram'),
      iconWrapperClass: '',
      routeName: 'instagram_analytics_v3',
      getParams: getInstagramParams,
      isNew: true,
      show: true,
    },
    twitter: {
      title: 'X (Twitter)',
      icon: getSidebarIcons('twitter'),
      iconWrapperClass: '',
      routeName: 'twitter_analytics_v3',
      getParams: getTwitterParams,
      isNew: false,
      show: !!analyticsEnabledTwitterAccounts.value?.length,
    },
    linkedin: {
      title: 'LinkedIn',
      icon: getSidebarIcons('linkedin'),
      iconWrapperClass: '',
      routeName: 'linkedin_analytics_v3',
      getParams: getLinkedinParams,
      isNew: true,
      show: true,
    },
    tiktok: {
      title: 'TikTok',
      icon: getSidebarIcons('tiktok'),
      iconWrapperClass:
        'rounded-md inline-flex justify-center items-center mr-4',
      routeName: 'tiktok_analytics_v3',
      getParams: getTiktokParams,
      isNew: false,
      show: true,
    },
    youtube: {
      title: 'Youtube',
      icon: getSidebarIcons('youtube'),
      iconWrapperClass:
        'rounded-md inline-flex justify-center items-center mr-4',
      routeName: 'youtube_analytics_v3',
      getParams: getYoutubeParams,
      isNew: false,
      show: true,
    },
    pinterest: {
      title: 'Pinterest',
      icon: getSidebarIcons('pinterest'),
      iconWrapperClass: '',
      routeName: 'pinterest_analytics_v3',
      getParams: getPinterestParams,
      isNew: false,
      show: true,
    },
  }))

  // Configuration for competitor analytics routes
  const competitorRouteConfigurations = computed(() => ({
    facebook: {
      title: 'Facebook',
      icon: getSidebarIcons('facebook'),
      iconWrapperClass: '',
      routeName: 'facebook_competitor_overview_v3',
      getParams: () => ({}),
      isNew: false,
      show: true,
    },
    instagram: {
      title: 'Instagram',
      icon: getSidebarIcons('instagram'),
      iconWrapperClass: '',
      routeName: 'instagram_competitor_overview_v3',
      getParams: () => ({}),
      isNew: false,
      show: true,
    },
  }))

  // Configuration for manage report routes
  const reportRouteConfigurations = computed(() => ({
    schedule: {
      title: 'Schedule Reports',
      icon: getSidebarIcons('schedule'),
      iconWrapperClass:
        'bg-[#F1FAFF] rounded-md inline-flex justify-center items-center mr-4',
      routeName: 'my_report_v3',
      getParams: () => ({}),
      isNew: false,
      show: true,
    },
    download: {
      title: 'Download Reports',
      icon: getSidebarIcons('download'),
      iconWrapperClass:
        'bg-[#F1FAFF] rounded-md inline-flex justify-center items-center mr-4',
      routeName: 'download_reports_v3',
      getParams: () => ({}),
      isNew: false,
      show: true,
    },
    settings: {
      title: 'Reports Settings',
      icon: getSidebarIcons('settings'),
      iconWrapperClass:
        'bg-[#F1FAFF] rounded-md inline-flex justify-center items-center mr-4',
      routeName: 'reports_setting_v3',
      getParams: () => ({}),
      isNew: false,
      show: true,
    },
  }))

  // Configuration for performance report routes
  const performanceRouteConfigurations = computed(() => ({
    labelsAndCampaigns: {
      title: 'Campaign & Label',
      icon: getSidebarIcons('labelsAndCampaigns'),
      iconWrapperClass:
        'bg-[#2bca7a14] rounded-md inline-flex justify-center items-center mr-4',
      routeName: 'campaign_and_label',
      getParams: () => ({}),
      isNew: false,
      show: true,
    },
  }))

    // Configuration for performance report routes
    const webAnalyticsRoutesConfigurations = computed(() => ({
      userMaven: {
        title: 'Usermaven',
        icon: getSidebarIcons('usermaven'),
        iconWrapperClass:
          'bg-[#7d47eb0d] rounded-md inline-flex justify-center items-center mr-4',
        routeName: 'user_maven',
        getParams: () => ({}),
        isNew: false,
        show: true,
      },
    }))

  // Computed property to generate social analytics routes based on social route configurations
  const socialAnalyticsRoutes = computed(() =>
    Object.keys(socialRouteConfigurations.value)?.map((key) => {
      // Destructuring properties from social route configuration object
      const { title, icon, iconWrapperClass, isNew, routeName, show } =
        socialRouteConfigurations.value[key]

      return {
        // Returning a route object containing title, icon, wrapper class, etc.
        title,
        icon,
        iconWrapperClass,
        isNew,
        routeName,
        show,
        // Getting link for the route using configured route name and params
        getLink: getPathAndRouteName(socialRouteConfigurations.value, key),
      }
    })
  )

  // Computed property to generate competitor analytics routes based on competitor route configurations
  const competitorAnalyticsRoutes = computed(() =>
    Object.keys(competitorRouteConfigurations.value)?.map((key) => {
      // Destructuring properties from competitor route configuration object
      const { title, icon, iconWrapperClass, isNew, routeName, show } =
        competitorRouteConfigurations.value[key]

      return {
        // Returning a route object containing title, icon, wrapper class, etc.
        title,
        icon,
        iconWrapperClass,
        isNew,
        routeName,
        show,
        // Getting link for the route using configured route name and params
        getLink: getPathAndRouteName(competitorRouteConfigurations.value, key),
      }
    })
  )

  // Computed property to generate manage report routes based on report route configurations
  const manageReportRoutes = computed(() =>
    Object.keys(reportRouteConfigurations.value)?.map((key) => {
      // Destructuring properties from report route configuration object
      const { title, icon, iconWrapperClass, isNew, routeName, show } =
        reportRouteConfigurations.value[key]

      return {
        // Returning a route object containing title, icon, wrapper class, etc.
        title,
        icon,
        iconWrapperClass,
        isNew,
        routeName,
        show,
        // Getting link for the route using configured route name and params
        getLink: getPathAndRouteName(reportRouteConfigurations.value, key),
      }
    })
  )

  // Computed property to generate performance report routes based on report route configurations
  const performanceReportRoutes = computed(() =>
    Object.keys(performanceRouteConfigurations.value)?.map((key) => {
      // Destructuring properties from report route configuration object
      const { title, icon, iconWrapperClass, isNew, routeName, show } =
        performanceRouteConfigurations.value[key]

      return {
        // Returning a route object containing title, icon, wrapper class, etc.
        title,
        icon,
        iconWrapperClass,
        isNew,
        routeName,
        show,
        // Getting link for the route using configured route name and params
        getLink: getPathAndRouteName(performanceRouteConfigurations.value, key),
      }
    })
  )

  // Computed property to generate performance report routes based on report route configurations
  const webAnalyticsRoutes = computed(() =>
    Object.keys(webAnalyticsRoutesConfigurations.value)?.map((key) => {
      // Destructuring properties from report route configuration object
      const { title, icon, iconWrapperClass, isNew, routeName, show } =
      webAnalyticsRoutesConfigurations.value[key]

      return {
        // Returning a route object containing title, icon, wrapper class, etc.
        title,
        icon,
        iconWrapperClass,
        isNew,
        routeName,
        show,
        // Getting link for the route using configured route name and params
        getLink: getPathAndRouteName(webAnalyticsRoutesConfigurations.value, key),
      }
    })
  )

  /**
   * Retrieves the route name and params for the given platform name.
   * @param {Object} objectArray - The object array containing route configurations.
   * @param {string} name - The name of the platform.
   * @returns {{name: string, params: {}|{name: string}}} - The route name and parameters.
   */
  function getPathAndRouteName(objectArray, name) {
    const config = objectArray[name] ?? defaultConfig.value
    const params = config.getParams()
    return params
      ? { name: config.routeName, params }
      : { name: config.routeName }
  }

  /**
   * Retrieves sidebar icons based on the given name.
   * @param {string} name - The name of the icon.
   * @returns {string} - The HTML representation of the icon.
   */
  function getSidebarIcons(name = '') {
    switch (name) {
      case 'overview':
        return `
              <img
                src="${GridIcon}"
                alt="Grid"
                class="w-6 h-6"
              />`
      case 'facebook':
        return `
                    <i
              class="h-8 w-8 rounded-md fab fa-facebook-square inline-flex justify-center items-center text-[#02B2FF] text-xl"
            ></i>
        `
      case 'instagram':
        return `
                        <i
              class="h-8 w-8 text-[20px] rounded-md fab fa-instagram inline-flex justify-center items-center text-[#EE446E] text-xl"
            ></i>
            `
      case 'twitter':
        return `
                          <img
                src="${TwitterIconX}"
                alt="twitter_logo"
                class="w-6 h-6"
              />
            `
      case 'linkedin':
        return `
                        <i
              class="h-8 w-8 rounded-md fab fa-linkedin-in inline-flex justify-center items-center text-[#02B2FF] text-xl"
            ></i>
            `
      case 'tiktok':
        return `
                          <img
                src="${TiktokIcon}"
                alt="tiktok_logo"
                class="h-[20px]"
              />
            `
      case 'youtube':
        return `
                          <img
                src="${YoutubeIcon}"
                alt="youtube_logo"
                class="h-[20px]"
              />
            `
      case 'pinterest':
        return `
                        <i
              class="h-8 w-8 rounded-md fab fa-pinterest inline-flex justify-center items-center text-[#EE446E] text-xl"
            ></i>
            `
      case 'schedule':
        return `
                        <img
              src="${ScheduleIcon}"
              alt="Grid"
              class="w-6 h-6"
            />
            `
      case 'download':
        return `
                            <img
                src="${DownloadIcon}"
                alt="Grid"
                class="w-6 h-6"
                />
                `
      case 'settings':
        return `
                            <img
                src="${SettingsIcon}"
                alt="Grid"
                class="w-6 h-6"
                />
                `
      case 'labelsAndCampaigns':
        return `
              <img
                src="${LineChartIcon}"
                alt="Grid"
                class="w-6 h-6"/>
            `
      case 'usermaven':
        return `
                          <img
              src="${UserMavenIcon}"
              alt="Grid"
              class="w-4 h-4"
              />`
      default:
        return ''
    }
  }

  // Functions for retrieving platform-specific parameters, if available. Else, return false (necessary for route configurations).
  function getFacebookParams() {
    if (getters?.getFacebookPagesList?.length) {
      return {
        accountId: getters.getFacebookPagesList[0]?.facebook_id,
      }
    }
    return false
  }

  function getInstagramParams() {
    if (getters?.getInstagramAccounts?.items?.length) {
      return {
        accountId: getters.getInstagramAccounts.items[0]?.instagram_id,
      }
    }
    return false
  }

  function getTwitterParams() {
    if (analyticsEnabledTwitterAccounts.value?.length) {
      return {
        accountId: analyticsEnabledTwitterAccounts.value[0]?.twitter_id,
      }
    }
    return false
  }

  function getLinkedinParams() {
    if (getters?.getLinkedinCompanyPages?.length) {
      return {
        accountId: getters.getLinkedinCompanyPages[0]?.linkedin_id,
      }
    }
    return false
  }

  function getTiktokParams() {
    if (getters?.getTiktokAccounts?.items?.length) {
      return {
        accountId: getters.getTiktokAccounts.items[0]?.platform_identifier,
      }
    }
    return false
  }

  function getYoutubeParams() {
    if (getters?.getYoutubeAccounts?.items?.length) {
      return {
        accountId: getters.getYoutubeAccounts.items[0]?.platform_identifier,
      }
    }
    return false
  }

  function getPinterestParams() {
    if (getAllPinterestProfiles()?.length) {
      return {
        accountId: getAllPinterestProfiles()[0]?.profile_id,
      }
    }
    return false
  }

  return {
    socialAnalyticsRoutes,
    competitorAnalyticsRoutes,
    manageReportRoutes,
    performanceReportRoutes,
    webAnalyticsRoutes,
  }
}
