<script>
import { mapGetters } from 'vuex'
import AddPinterestModal from '@src/modules/integration/components/modals/AddPinterestModal'
import { platforms } from '@src/modules/integration/store/states/mutation-types'
import SaveSocialAccounts from '@src/modules/integration/components/dialogs/SaveSocialAccounts'
import AccountsNotAllowedModal from '@src/modules/integration/components/modals/AccountsNotAllowedModal.vue'
import { EventBus } from '@common/lib/event-bus'
import BulkReconnectedAccounts from '@src/modules/integration/components/dialogs/BulkReconnectedAccounts.vue'
import ManageAndCreateLink from '@modules/integration/components/platforms/social/ManageAndCreateLink.vue'
import CreateShareLinkModal from '@src/modules/integration/components/dialogs/CreateShareLinkModal.vue'
import SharedConnectLinkModal from '@src/modules/integration/components/dialogs/SharedConnectLinkModal.vue'
import { commonMethods } from '@common/store/common-methods'
import {useFeatures} from "@modules/billing/composables/useFeatures";
import TimeQueue from '../../dialogs/TimeQueue'
import SavePlatform from '../../dialogs/SavePlatform'
import RemoveIntegration from '../../dialogs/remove/RemoveIntegration'
import AddGroupModal from '../../modals/AddGroupModal'
import AccountListing from './AccountListing'
import SetDefaultLocationModal from '@common/components/dialogs/SetDefaultLocationModal.vue'
export default {
  components: {
    SharedConnectLinkModal,
    ManageAndCreateLink,
    CreateShareLinkModal,
    BulkReconnectedAccounts,
    AccountsNotAllowedModal,
    SaveSocialAccounts,
    RemoveIntegration,
    AccountListing,
    SavePlatform,
    TimeQueue,
    AddPinterestModal,
    AddGroupModal,
    SetDefaultLocationModal
  },
  setup(){
    const {canAccess} = useFeatures()
    const easyConnectAccess = canAccess('easy_connect')

    return {
      easyConnectAccess
    }
  },
  data() {
    return {
      gmbView: 'on',
      type: '',
      platform: {},
      buttons: [
        {
          text: 'EasyConnect Links',
          classes:
            'px-[1rem] py-[0.625rem] rounded border border-[#757A8A] text-[#757A8A] bg-white',
          clickEvent: (e) => {
            this.$bvModal.show('manage-shared-links-modal')
          },
        },
        {
          text: 'Create New Link',
          classes:
            'px-[1rem] py-[0.625rem] rounded border border-[#2961D2] bg-[#2961D2] text-white',
          clickEvent: (e) => {
            this.$bvModal.show('create-share-link-modal')
          },
        },
      ],
      platform_type : '',
      account_id: '',
      default_location:''
    }
  },
  computed: {
    ...mapGetters(['getProfile', 'getFetchAccountsStatus']),
    featureFlagYoutube() {
      const email = this.$store.getters.getProfile.email
      return (
        email.includes('@contentstudio.io') ||
        email.includes('@d4interactive.io')
      )
    },
  },
  watch: {
    'getActiveWorkspace._id'(val) {
      console.debug('Watch::Social-getActiveWorkspace._id', val.length)
      if (val.length > 0) {
        this.initializeSection()
      }
    },
  },
  created() {
    this.initializeSection()
  },
  mounted() {
    commonMethods.toggleAiChat(true)
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$route.hash) {
          const hash = this.$route.hash.replace('#', '')

          const element = document.querySelector(`#item-${hash}`)

          if (element) {
            const topPos = element.getBoundingClientRect().top - 100

            window.scrollTo({
              top: topPos,
              behavior: 'smooth',
            })
          }
        } else {
          const hash = this.$route.params.id
            ? `${this.$route.params.id}`
            : 'social_component'

          const element = document.querySelector(`#item-${hash}`)

          if (element) {
            const topPos = element.getBoundingClientRect().top - 100

            window.scrollTo({
              top: topPos,
              behavior: 'smooth',
            })
          }
        }
      }, 500)
    })

    EventBus.$on('add-default-location', (payload) => {

      console.log('add-default-location', payload)

      this.platform_type = payload.platform_type

      this.account_id = payload.account_id

      this.default_location = payload.default_location

      // this.platformType = payload.platform_type

    })
  },
  beforeUnmount() {
    console.debug('beforeUnmount social')
    commonMethods.toggleAiChat(false)
  },
  methods: {
    /**
     * This method is used to remove the integration from the child searchList
     * @param type
     */
    removeIntegration(type) {
      this.$refs[type][0].resetSearch()
    },
    async initializeSection() {
      console.debug('Social::initializeSection')
      await this.$store.dispatch('fetchSocialAccounts')
      const response = await this.$store.dispatch('checkConnectorState', {
        hash: `#${this.$route.params.id}`,
      })

      if (response?.data?.processType === 'bulk_reconnect') {
        await this.processBulkReconnectResponse(response)
      } else {
        await this.processReconnectResponse(response)
      }
    },
    getPlatforms() {
      return [
        'facebook',
        'instagram',
        'threads',
        'twitter',
        'linkedin',
        'pinterest',
        'gmb',
        'youtube',
        'tiktok',
        'tumblr',
        'bluesky'
      ]
    },
    /**
     * Normal connection & reconnection process.
     * Shows a modal containing accounts to be added in case of normal connection.
     * Shows a notification of success or failure in case of individual reconnection.
     * @param response
     * @returns {Promise<void>}
     */
    async processReconnectResponse(response) {
      if (response?.data) {
        if (response.data.limitExceed) {
          this.showUpgradeModal()
          return
        }
        if (response.data.status === true) {
          this.trackUserMaven('connected_social_accounts')
          switch (response.data.process) {
            case 'tiktok':
            case 'Twitter':
              if (
                !this.getActiveWorkspace.onboarding_steps.connect_social_account
                  .status
              ) {
                await this.onboardingStepsCompleted('connect_social_account')
              }
              break
            case 'Facebook':
            case 'Instagram':
            case 'Linkedin':
            case 'Pinterest':
            case 'tumblr':
            case 'Gmb':
            case 'Youtube':
              if (
                response.data.pages ||
                response.data.groups ||
                response.data.profile ||
                response.data.boards ||
                response.data.profiles ||
                response.data.blogs ||
                response.data.locations ||
                response.data.channels
              ) {
                this.$store.commit(
                  platforms.SET_CONNECTION_STATE,
                  response.data
                )
                this.$bvModal.show('save-social-accounts')
                return
              }
              break
          }
          if (response.data?.not_allowed?.length) {
            EventBus.$emit('showNotAllowedModal', {
              notAllowedIds: response.data.not_allowed,
              firstSectionItems: [response.data.profile || []],
              firstSectionHeader: 'Social Account',
            })
            return
          }
          await this.$store.dispatch('toastNotification', {
            message: response.data.message,
            type: 'success',
          })
        }
      }
    },
    /**
     * Bulk reconnection process.
     * Shows a modal of success and failed accounts connection.
     * @param response
     */
    processBulkReconnectResponse(response) {
      const {
        data: { connectionData },
      } = response
      if (connectionData) {
        this.trackUserMaven('connected_social_accounts')
        EventBus.$emit('toggleBulkReconnectedAccounts', {
          toggleValue: true,
          connectionData,
        })
        this.$bvModal.show('bulkReconnectedAccounts')
      }
    },
  },
}
</script>

<template>
  <div
    id="social_component"
    class="setting_groups_component setting_social_component"
  >
    <AddPinterestModal />
    <div class="component_inner">
      <SetDefaultLocationModal :defaultLocation="default_location" :platform="platform_type" :accountId="account_id" />
      <SaveSocialAccounts />
      <AddGroupModal></AddGroupModal>
      <AccountsNotAllowedModal></AccountsNotAllowedModal>
      <SavePlatform></SavePlatform>
      <TimeQueue></TimeQueue>
      <RemoveIntegration :type="type" :platform="platform" @remove-integration="removeIntegration"></RemoveIntegration>
      <!--BulkReconnectAccountsModal::start-->
      <BulkReconnectedAccounts></BulkReconnectedAccounts>
      <CreateShareLinkModal :is-edit="false" />
      <SharedConnectLinkModal />
      <!--BulkReconnectAccountsModal::end-->
      <ManageAndCreateLink
          :img-src="require('@assets/img/icons/chain-link.svg')"
          :buttons="buttons"
          :access="easyConnectAccess"
          main-text="EasyConnect"
          secondary-text="Generate a secure link and share it with your clients for effortless social account connection."
          learn-more-link="https://docs.contentstudio.io/article/1005-how-to-use-and-set-up-easyconnect"
      />
      <div class="social_block">
        <template v-for="account in getPlatforms()" :key="account">
          <div :id="`item-${account}`">
            <AccountListing :ref="account" :type="account"></AccountListing>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
