import { mapGetters, mapActions } from 'vuex'
import debounce from 'lodash/debounce'
import axios from 'axios'
import twitterText from 'twitter-text'
import {
  facebookPostLimit,
  fetchSharingMentionSuggestionsUrl,
  fetchSharingHashTagsSuggestionsUrl,
  linkedinPostLimit,
  pinterestPostLimit,
  twitterPostLimit,
  gmbPostLimit,
  instagramPostLimit,
  youtubePostLimit,
  tiktokPostLimit, threadsPostLimit,
  blueskyPostLimit
} from '@src/modules/publish/config/api-utils'
import {
  commonTypes,
  loadersTypes,
  sharingTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { parseDescriptionHtml } from '@common/lib/helper'
export const sharingMessageMixin = {
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getCommonSharingDetails',
      'getFacebookSharingDetails',
      'getBlueskySharingDetails',
      'getTwitterSharingDetails',
      'getJWTToken',
      'getLinkedinSharingDetails',
      'getPinterestSharingDetails',
      'getTumblrSharingDetails',
      'getCommonSharingCancelledLink',
      'getFacebookSharingCancelledLink',
      'getSocialSharingSelection',
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getAccountSelection',
      'getCommonBoxOptions',
      'getAxiosRequests',
      'getInstagramSharingDetails',
      'getWorkspaces',
      'getYoutubeSharingDetails',
      'getTiktokSharingDetails',
      'getPublishSelection',
    ]),
  },
  methods: {
    ...mapActions([
      'setCommonSharingLink',
      'setFacebookSharingLink',
      'setTwitterSharingLink',
      'setPinterestSharingLink',
      'setFetchCommonShortLinksLoaders',
      'setFetchFacebookShortLinksLoaders',
      'setFetchTwitterShortLinksLoaders',
      'setFetchLinkedinShortLinksLoaders',
      'setFetchPinterestShortLinksLoaders',
      'setFetchTumblrShortLinksLoaders',
      'setSharingActiveTab',
      'setSharingMentionsKeyword',
      'setSharingMentionsType',
      'setCommonFacebookSharingLink',
      'setCommonTwitterSharingLink',
      'setCommonLinkedinSharingLink',
      'setCommonPinterestSharingLink',
      'setCommonTumblrSharingLink',
      'setFetchInstagramShortLinksLoaders',
      'setCommonInstagramSharingLink',
      'setSocialSharingTagsSuggestionsItems',
      'onPasteFromClipboard',
    ]),

    /**
     * checks for the event type and fetches images/videos from clipboard in case of 'paste' event.
     * @param event
     * @param type
     */
    sharingMessageChanges(event, type) {
      console.debug('Method:sharingMessageChanges', event, type)
      let isImage = false
      console.log(isImage)
      if (event.keyCode === 32 || event.type === 'paste') {
        // var msg = document.getElementById('common_sharing_message').value
        isImage = this.checkClipBoardForImageAndVideos(event, type)
        // this.captureImagesFromClipboard(event, type);
        // this.setSharingMessage(type, msg)
      }

      if (event.keyCode === 32 || event.type === 'paste') {
        this.sharingLinkChecking(event, type, this)
      } else {
        console.log('Method:sharingMessageChanges ~ else')
        this.setSharingMessage(type, event.target.value)
        // this.sharingMentionChecking(event, type, this)

        // this.sharingHashtagChecking(event, type, this)

        // this.processMentionChecking(event, type)
        // this.processHashtagSuggestion(event, type)
      }
    },
    /**
     * finds images/videos from clipboard.
     * (called when 'paste' event is invoked)
     * @param event
     * @param type
     * @returns {boolean}
     */
    checkClipBoardForImageAndVideos(event, type) {
      if (event.type !== 'paste') return
      const itemImages = []
      // use event.originalEvent.clipboard for newer chrome versions
      const items = (event.clipboardData || event.originalEvent.clipboardData)
        .items
      for (let i = 0; i <= items.length - 1; i++) {
        if (
          items[i].type.indexOf('image') === 0 ||
          items[i].type.indexOf('video') === 0
        ) {
          itemImages.push(items[i])
        }
      }

      if (itemImages.length > 0) {
        this.onPasteFromClipboard({
          event,
          items: itemImages,
          type,
        })
      }
      return itemImages > 0
    },

    sharingLinkChecking: debounce(function (event, type, stateObject) {
      console.debug('Method::sharingLinkChecking', type)
      stateObject.processLinkChecking(event, type)
    }, 100),

    sharingMentionChecking: debounce(function (event, type, stateObject) {
      console.debug('Method::sharingMentionChecking', type)
      stateObject.processMentionChecking(event, type)
    }, 100),

    sharingHashtagChecking: debounce(function (event, type, stateObject) {
      console.debug('Method::sharingHashtagChecking', type)
      stateObject.processHashtagSuggestion(event, type)
    }, 100),

    processLinkChecking(event, type) {
      console.debug('Method:processLinkChecking', event, type)
      const message = event.target.value
      this.$store.commit(sharingTypes.SET_MENTIONS_DROPDOWN_STATUS, false)

      const urls = this.getLinks(message)
      console.debug('Method:processLinkChecking', urls)
      if (urls) {
        if (
          this.getWorkspaces.activeWorkspace.utmStatus &&
          this.checkLongLinkExistence(urls)
        )
          return this.processWithUTM(type, message, urls)
        this.setSharingMessage(type, message)
        if (
          this.getWorkspaces.activeWorkspace.shortener &&
          this.checkLongLinkExistence(urls)
        ) {
          this.fetchShortLinks(urls, type)
        } else {
          this.parseLinkPreview(urls, type)
        }
      }
    },

    processWithUTM(type, message, urls) {
      console.debug(
        'Method::processWithUTM',
        type,
        message,
        urls,
        this.getPublishSelection.utm.selected
      )
      const stateObject = this
      const selectedUtm = this.fetchActiveUtm
      const finalURLs = []
      urls.forEach(function (url, index) {
        if (!stateObject.isShortLink(url)) {
          if (
            url.indexOf('utm_medium') === -1 &&
            url.indexOf('utm_source') === -1 &&
            url.indexOf('utm_content') === -1
          ) {
            const response = stateObject.fetchUtmBasedLinks(url, selectedUtm)
            message = message.replace(urls[index], response[type.toLowerCase()])

            if (type === 'Common') {
              if (response.auto_add_social_channel === false) {
                finalURLs.push(response.common)
              }
              for (const prop in response) {
                // skip loop if the property is from prototype
                if (!(prop in response)) continue

                if (
                  response.auto_add_social_channel === true &&
                  prop !== 'auto_add_social_channel'
                )
                  finalURLs.push(response[prop])
                stateObject.setCommonSharingLink(response[type.toLowerCase()])
                switch (prop) {
                  case 'facebook':
                  case 'twitter':
                  case 'linkedin':
                  case 'pinterest':
                  case 'tumblr':
                  case 'instagram':
                  case 'youtube': {
                    stateObject.$store.commit(
                      'setCommon' +
                        prop.charAt(0).toUpperCase() +
                        prop.slice(1) +
                        'SharingLink',
                      response[prop]
                    )
                    break
                  }
                  case 'gmb': {
                    stateObject.$store.commit(
                      commonTypes.SET_GMB_SHARING_LINK,
                      response[prop]
                    )
                    break
                  }
                }
                if (stateObject.getWorkspaces.activeWorkspace.shortener)
                  stateObject.setFetchCommonShortLinksLoaders(true)
              }
            } else {
              finalURLs.push(response[type.toLowerCase()])
            }
          }
        }
      })
      this.setSharingMessage(type, message)
      this.getWorkspaces.activeWorkspace.shortener &&
      this.checkLongLinkExistence(finalURLs)
        ? this.fetchShortLinks(finalURLs, type)
        : this.parseLinkPreview(finalURLs, type)
    },
    processSharingBoxHtml(description, type) {
      console.debug('Method:processTabHtml', type)

      const processedHtml = {
        allowed: '',
        exceed: '',
      }
      let htmlValue = description

      let limit = ''

      if (
        type === 'Twitter' ||
        (type === 'Common' && this.getAccountSelection.twitter.length)
      ) {
        // for handling links characters
        limit = twitterPostLimit
        const urls = this.getLinks(description)
        if (urls) {
          // eslint-disable-next-line no-undef
          $(urls).each(function (index, el) {
            limit = limit + el.length - 23
          })
        }
      } else if (
        type === 'Pinterest' ||
        (type === 'Common' && this.getAccountSelection.pinterest.length)
      ) {
        limit = pinterestPostLimit
      } else if (
        type === 'Linkedin' ||
        (type === 'Common' && this.getAccountSelection.linkedin.length)
      ) {
        limit = linkedinPostLimit
      } else if (
        type === 'Gmb' ||
        (type === 'Common' && this.getAccountSelection.gmb.length)
      ) {
        limit = gmbPostLimit
      } else if (
        type === 'Instagram' ||
        (type === 'Common' && this.getAccountSelection.instagram.length)
      ) {
        limit = instagramPostLimit
      } else if (
        type === 'Facebook' ||
        (type === 'Common' && this.getAccountSelection.facebook.length)
      ) {
        limit = facebookPostLimit
      } else if (
        type === 'Youtube' ||
        (type === 'Common' && this.getAccountSelection.youtube.length)
      ) {
        limit = youtubePostLimit
      }

      if (limit) {
        htmlValue = parseDescriptionHtml(htmlValue, limit)
      }

      processedHtml.allowed = htmlValue

      return processedHtml
    },

    setSharingBoxHtml(description, type) {
      console.debug('Method:setSharingBoxHtml', description.allowed)

      let selector = ''
      switch (type) {
        case 'Common':
          selector = document.getElementById('clone_txt_common_tab')
          break
        case 'Facebook':
          selector = document.getElementById('clone_txt_facebook_tab')
          break
        case 'Twitter':
          selector = document.getElementById('clone_txt_twitter_tab')
          break
        case 'Linkedin':
          selector = document.getElementById('clone_txt_linkedin_tab')
          break
        case 'Pinterest':
          selector = document.getElementById('clone_txt_pinterest_tab')
          break
        case 'Tumblr':
          selector = document.getElementById('clone_txt_tumblr_tab')
          break
        case 'Instagram':
          selector = document.getElementById('clone_txt_instagram_tab')
          break
        case 'Youtube':
          selector = document.getElementById('clone_txt_youtube_tab')
          break
        case 'Gmb':
          selector = document.getElementById('clone_txt_gmb_tab')
          break
      }

      if (selector) {
        selector.childNodes[0].innerHTML = description.allowed
        if (type === 'Common') {
          if (description.exceed) {
            selector.childNodes[1].innerHTML = description.exceed
            selector.childNodes[1].style.display = 'inline'
          } else {
            selector.childNodes[1].style.display = 'none'
          }
        } else {
          selector.childNodes[1].innerHTML = description.allowed
          if (selector.childNodes[2]) {
            if (description.exceed) {
              selector.childNodes[2].innerHTML = description.exceed
              selector.childNodes[2].style.display = 'inline'
            } else {
              selector.childNodes[2].style.display = 'none'
            }
          }
        }
      }
    },

    processMentionChecking(event, type) {
      console.debug(
        'Method:processMentionChecking',
        event,
        type,
        this.getAccountSelection
      )

      if (
        (type === 'Common' || type === 'Facebook' || type === 'Twitter') &&
        (this.getAccountSelection.twitter.length ||
          this.getAccountSelection.facebook.length)
      ) {
        const message = event.target.value

        // Fixing Sentry Issue: https://sentry-onpremise.contentstudio.io/organizations/d4interactive/issues/1320094/?environment=production&project=24&query=is%3Aunresolved&sort=freq&statsPeriod=14d
        let mentionPosition = 0
        // eslint-disable-next-line no-undef
        if ($(event.target) && typeof $(event.target).caret === 'function') {
          // eslint-disable-next-line no-undef
          mentionPosition = $(event.target).caret('position')
        }

        this.$store.commit(
          sharingTypes.SET_MENTIONS_DROPDOWN_LEFT_POSITION,
          mentionPosition.left
        )
        this.$store.commit(
          sharingTypes.SET_MENTIONS_DROPDOWN_TOP_POSITION,
          mentionPosition.top + 30
        )

        // checking cursor position base on type
        let cursorPosition = 0
        switch (type) {
          case 'Common':
            cursorPosition = message.slice(
              0,
              document.getElementById('common_sharing_message').selectionStart
            ).length

            break
          case 'Facebook':
            cursorPosition = message.slice(
              0,
              document.getElementById('facebook_sharing_message').selectionStart
            ).length
            break
          case 'Twitter':
            cursorPosition = message.slice(
              0,
              document.getElementById('twitter_sharing_message').selectionStart
            ).length
            break
        }

        const mentions = this.getMentions(message)

        if (mentions) {
          // finding the mention at the position of cursor
          const mention = mentions.find((mention) => {
            let status = false
            // finding starting and ending position of the mention in the message.
            const indices = this.getIndicesOf(mention, message)

            if (indices && indices.length) {
              // using the mention if the cursor position is between the starting and ending ranges
              for (const indice in indices) {
                if (
                  cursorPosition >= indices[indice].start &&
                  cursorPosition <= indices[indice].end
                ) {
                  status = true
                  return true
                }
              }
              return status
            }
          })

          if (mention && mention.length > 2) {
            this.$store.commit(sharingTypes.SET_MENTIONS_DROPDOWN_STATUS, true)
            switch (type) {
              case 'Common':
                if (
                  this.getSocialSharingSelection.mentionsSuggestions.type ===
                  'Facebook'
                ) {
                  this.$store.commit(
                    loadersTypes.SET_FETCH_FACEBOOK_MENTIONS_SUGGESTIONS_LOADER,
                    true
                  )
                  this.sharingMentionDebounce(mention, type, this)
                } else if (
                  this.getSocialSharingSelection.mentionsSuggestions.type ===
                  'Twitter'
                ) {
                  this.$store.commit(
                    loadersTypes.SET_FETCH_TWITTER_MENTIONS_SUGGESTIONS_LOADER,
                    true
                  )
                  this.sharingMentionDebounce(mention, type, this)
                }
                break
              case 'Facebook':
                this.$store.commit(
                  loadersTypes.SET_FETCH_FACEBOOK_MENTIONS_SUGGESTIONS_LOADER,
                  true
                )
                this.sharingMentionDebounce(mention, type, this)
                break
              case 'Twitter':
                this.$store.commit(
                  loadersTypes.SET_FETCH_TWITTER_MENTIONS_SUGGESTIONS_LOADER,
                  true
                )
                this.sharingMentionDebounce(mention, type, this)
                break
            }
            return false
          }
        }
      }
      this.$store.commit(sharingTypes.SET_MENTIONS_DROPDOWN_STATUS, false)
    },

    processHashtagSuggestion(event, type) {
      console.debug('Method:processHashtagSuggestion', event, type)
      try {
        const message = event.target.value
        // eslint-disable-next-line no-undef
        const mentionPosition = $(event.target).caret('position')
        this.$store.commit(
          sharingTypes.SET_HASHTAGS_DROPDOWN_LEFT_POSITION,
          mentionPosition.left
        )
        this.$store.commit(
          sharingTypes.SET_HASHTAGS_DROPDOWN_TOP_POSITION,
          mentionPosition.top + 30
        )
        this.$store.commit(sharingTypes.SET_HASHTAGS_ACTIVE_BOX, type)

        // checking cursor position base on type
        let cursorPosition = 0
        switch (type) {
          case 'Common':
            cursorPosition = message.slice(
              0,
              document.getElementById('common_sharing_message').selectionStart
            ).length
            break
          case 'Facebook':
            cursorPosition = message.slice(
              0,
              document.getElementById('facebook_sharing_message').selectionStart
            ).length
            break
          case 'Twitter':
            cursorPosition = message.slice(
              0,
              document.getElementById('twitter_sharing_message').selectionStart
            ).length
            break
          case 'Linkedin':
            cursorPosition = message.slice(
              0,
              document.getElementById('linkedin_sharing_message').selectionStart
            ).length
            break
          case 'Pinterest':
            cursorPosition = message.slice(
              0,
              document.getElementById('pinterest_sharing_message')
                .selectionStart
            ).length
            break
          case 'Tumblr':
            cursorPosition = message.slice(
              0,
              document.getElementById('tumblr_sharing_message').selectionStart
            ).length
            break
          case 'Instagram':
            cursorPosition = message.slice(
              0,
              document.getElementById('instagram_sharing_message')
                .selectionStart
            ).length
            break
          case 'Youtube':
            cursorPosition = message.slice(
              0,
              document.getElementById('youtube_sharing_message').selectionStart
            ).length
            break
          case 'Gmb':
            cursorPosition = message.slice(
              0,
              document.getElementById('gmb_sharing_message').selectionStart
            ).length
            break
        }

        const hashTags = this.getHashTags(message)

        if (hashTags) {
          // finding the hashtag at the position of cursor
          const hashTag = hashTags.find((hashtagPosition) => {
            let status = false
            // finding starting and ending position of the hashtag in the message.
            const indices = this.getIndicesOf(hashtagPosition, message)
            console.debug('hashtagPosition', hashtagPosition)
            if (indices && indices.length) {
              // using the hashtag if the cursor position is between the starting and ending ranges
              for (const indice in indices) {
                if (
                  cursorPosition >= indices[indice].start + 1 &&
                  cursorPosition <= indices[indice].end
                ) {
                  status = true
                  return true
                }
              }
              return status
            }
          })

          if (hashTag && hashTag.length > 2) {
            this.$store.commit(sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS, true)
            this.$store.commit(
              loadersTypes.SET_FETCH_HASHTAGS_SUGGESTIONS_LOADER,
              true
            )
            this.sharingHashTagsDebounce(hashTag, type, this)
            return false
          }
        }

        this.$store.commit(sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS, false)
      } catch (e) {
        console.log(e instanceof TypeError) // true
        console.log(e.message) // "null has no properties"
      }
    },
    // onBlurHideHashtag() {
    //    this.$store.commit(sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS, false)
    // },

    hideHashtag() {
      this.$store.commit(sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS, false)
    },

    sharingMentionDebounce: debounce(function (description, type, stateObject) {
      console.debug('sharingMentionDebounce', description, type)

      let mentionType = ''
      if (type === 'Common') {
        mentionType =
          stateObject.getSocialSharingSelection.mentionsSuggestions.type
        stateObject.setSharingMentionsKeyword(description)
      } else {
        mentionType = type
      }

      const CancelToken = axios.CancelToken
      for (
        let i = 0;
        i < stateObject.getAxiosRequests.fetchSharingMention.length;
        i++
      ) {
        const remove = stateObject.getAxiosRequests.fetchSharingMention[i]
        remove()
      }

      axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + stateObject.getJWTToken,
      }

      let cancel

      axios
        .post(
          fetchSharingMentionSuggestionsUrl,
          {
            keyword: description.replace('@', ''),
            type: mentionType,
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          },
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            }),
          }
        )
        .then(
          (response) => {
            if (
              (response.data.suggestions &&
                response.data.suggestions.facebook &&
                response.data.suggestions.facebook.length > 0) ||
              (response.data.suggestions &&
                response.data.suggestions.twitter &&
                response.data.suggestions.twitter.length > 0)
            ) {
              if (type === 'Common') {
                if (
                  stateObject.getSocialSharingSelection.mentionsSuggestions
                    .type === 'Facebook'
                ) {
                  stateObject.$store.commit(
                    sharingTypes.SET_FACEBOOK_MENTIONS_SUGGESTIONS,
                    response.data.suggestions.facebook
                  )
                } else {
                  stateObject.$store.commit(
                    sharingTypes.SET_TWITTER_MENTIONS_SUGGESTIONS,
                    response.data.suggestions.twitter
                  )
                }
              } else if (type === 'Facebook') {
                stateObject.$store.commit(
                  sharingTypes.SET_FACEBOOK_MENTIONS_SUGGESTIONS,
                  response.data.suggestions.facebook
                )
              } else {
                stateObject.$store.commit(
                  sharingTypes.SET_TWITTER_MENTIONS_SUGGESTIONS,
                  response.data.suggestions.twitter
                )
              }
            } else {
              this.$store.commit(
                sharingTypes.SET_MENTIONS_DROPDOWN_STATUS,
                false
              )
            }

            if (type === 'Common') {
              if (
                stateObject.getSocialSharingSelection.mentionsSuggestions
                  .type === 'Facebook'
              ) {
                stateObject.$store.commit(
                  loadersTypes.SET_FETCH_FACEBOOK_MENTIONS_SUGGESTIONS_LOADER,
                  false
                )
              } else {
                stateObject.$store.commit(
                  loadersTypes.SET_FETCH_TWITTER_MENTIONS_SUGGESTIONS_LOADER,
                  false
                )
              }
            } else if (type === 'Facebook') {
              stateObject.$store.commit(
                loadersTypes.SET_FETCH_FACEBOOK_MENTIONS_SUGGESTIONS_LOADER,
                false
              )
            } else {
              stateObject.$store.commit(
                loadersTypes.SET_FETCH_TWITTER_MENTIONS_SUGGESTIONS_LOADER,
                false
              )
            }
          },
          (response) => {
            console.debug('Exception in sharingMentionDebounce ', response)
            if (type === 'Common') {
              if (
                stateObject.getSocialSharingSelection.mentionsSuggestions
                  .type === 'Facebook'
              ) {
                stateObject.$store.commit(
                  sharingTypes.SET_FACEBOOK_MENTIONS_SUGGESTIONS,
                  []
                )
              } else {
                stateObject.$store.commit(
                  sharingTypes.SET_TWITTER_MENTIONS_SUGGESTIONS,
                  []
                )
              }
            } else if (type === 'Facebook') {
              stateObject.$store.commit(
                sharingTypes.SET_FACEBOOK_MENTIONS_SUGGESTIONS,
                []
              )
            } else {
              stateObject.$store.commit(
                sharingTypes.SET_TWITTER_MENTIONS_SUGGESTIONS,
                []
              )
            }
          }
        )

      stateObject.getAxiosRequests.fetchSharingMention.push(cancel)
    }, 250),

    sharingHashTagsDebounce: debounce(function (
      description,
      type,
      stateObject
    ) {
      console.debug('sharingHashTagsDebounce', description, type)

      const CancelToken = axios.CancelToken
      for (
        let i = 0;
        i < stateObject.getAxiosRequests.fetchSharingHashTags.length;
        i++
      ) {
        const remove = stateObject.getAxiosRequests.fetchSharingHashTags[i]
        remove()
      }

      axios.defaults.headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + stateObject.getJWTToken,
      }

      let cancel

      axios
        .post(
          fetchSharingHashTagsSuggestionsUrl,
          {
            keyword: description.replace('#', ''),
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          },
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            }),
          }
        )
        .then(
          (response) => {
            if (response.data.suggestions) {
              const tags = Object.keys(response.data.suggestions).map((key) => {
                return response.data.suggestions[key]
              })
              if (tags && tags.length > 0) {
                stateObject.$store.commit(
                  sharingTypes.SET_HASHTAGS_SUGGESTIONS,
                  tags
                )
              } else
                stateObject.$store.commit(
                  sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS,
                  false
                )
            } else
              stateObject.$store.commit(
                sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS,
                false
              )
            stateObject.$store.commit(
              loadersTypes.SET_FETCH_HASHTAGS_SUGGESTIONS_LOADER,
              false
            )
          },
          (response) => {
            console.debug('Exception in sharingHashTagsDebounce ', response)
            stateObject.$store.commit(sharingTypes.SET_HASHTAGS_SUGGESTIONS, [])
          }
        )

      stateObject.getAxiosRequests.fetchSharingHashTags.push(cancel)
    },
    250),

    fetchMentionSuggestions(type) {
      console.debug('Method::fetchSocialMentions')

      this.setSharingMentionsType(type)

      if (type === 'Facebook') {
        this.$store.commit(
          loadersTypes.SET_FETCH_FACEBOOK_MENTIONS_SUGGESTIONS_LOADER,
          true
        )
        this.sharingMentionDebounce(
          this.getSocialSharingSelection.mentionsSuggestions.keyword,
          'Common',
          this
        )
      } else if (type === 'Twitter') {
        this.$store.commit(
          loadersTypes.SET_FETCH_TWITTER_MENTIONS_SUGGESTIONS_LOADER,
          true
        )
        this.sharingMentionDebounce(
          this.getSocialSharingSelection.mentionsSuggestions.keyword,
          'Common',
          this
        )
      } else if (type === 'Linkedin') {
        this.$store.commit(
          loadersTypes.SET_FETCH_TWITTER_MENTIONS_SUGGESTIONS_LOADER,
          true
        )
        this.sharingMentionDebounce(
          this.getSocialSharingSelection.mentionsSuggestions.keyword,
          'Common',
          this
        )
      }
    },

    facebookUsernameFromLink(link) {
      link = link.replace('https://www.facebook.com/', '')
      return link.replace('/', '')
    },

    addSharingMention(suggestion, type) {
      console.debug('Method:addSharingMention', suggestion, type)
      console.log(suggestion, type)
      let orignalTxt = ''
      let cursorPosition = 0
      let message = ''

      if (type === 'Common') {
        orignalTxt = this.getCommonSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('common_sharing_message').selectionStart
        ).length
      } else if (type === 'Facebook') {
        orignalTxt = this.getFacebookSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('facebook_sharing_message').selectionStart
        ).length
      } else {
        orignalTxt = this.getTwitterSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('twitter_sharing_message').selectionStart
        ).length
      }

      const mentions = this.getTextMentions(orignalTxt)

      if (mentions) {
        const beforeCursorPositionText = orignalTxt.substring(0, cursorPosition)
        const afterCursorPositionText = orignalTxt.substring(cursorPosition)

        let description = beforeCursorPositionText
        const spacePosition = afterCursorPositionText.indexOf(' ')
        const lineBreakPosition = afterCursorPositionText.indexOf('\n')
        // for including text after current position to space so that full tag can be used for query
        if (spacePosition > -1) {
          description =
            description + afterCursorPositionText.substring(0, spacePosition)
        } else {
          description = description + afterCursorPositionText
        }

        const tagStartPosition = description.lastIndexOf('@')
        message = description.substring(0, tagStartPosition)

        description = description.substring(tagStartPosition)
        const tagEndPosition = description.indexOf(' ')

        if (tagEndPosition !== -1) {
          message = message + description.substring(tagEndPosition)
        }

        if (suggestion.username) {
          message = message + '@' + suggestion.username + ' '
          // cursorPosition = cursorPosition + suggestion.username.length + 2// plus 2 for space and @
        } else {
          message = message + suggestion.name + ' '
          // cursorPosition = cursorPosition + suggestion.name.length + 1// plus 1 for space
        }

        console.debug(
          'cursorPosition sub',
          afterCursorPositionText.substring(spacePosition),
          spacePosition,
          lineBreakPosition
        )

        // Hack for line break and space case
        if (spacePosition > lineBreakPosition) {
          message =
            message + afterCursorPositionText.substring(lineBreakPosition)
        } else if (spacePosition < lineBreakPosition) {
          message = message + afterCursorPositionText.substring(spacePosition)
        }

        // message = message + afterCursorPositionText.substring(spacePosition-1)
        console.debug('cursorPosition message', message)
        cursorPosition = cursorPosition + (message.length - orignalTxt.length)
        this.setSharingMessage(type, message, cursorPosition)
        if (type === 'Common') {
          if (
            this.getSocialSharingSelection.mentionsSuggestions.type ===
            'Facebook'
          ) {
            this.getCommonSharingDetails.mentions.push(suggestion)
          }
        } else if (type === 'Facebook') {
          this.getFacebookSharingDetails.mentions.push(suggestion)
        }
      }

      this.$store.commit(sharingTypes.SET_MENTIONS_DROPDOWN_STATUS, false)
      this.$store.commit(sharingTypes.SET_FACEBOOK_MENTIONS_SUGGESTIONS, [])
      this.$store.commit(sharingTypes.SET_TWITTER_MENTIONS_SUGGESTIONS, [])
      // this.allTextareaFocus()
    },

    addSharingHashtags(suggestion, type) {
      console.debug('Method:addSharingHashtags', suggestion, type)

      let orignalTxt = ''
      let cursorPosition = 0
      let message = ''

      if (type === 'Common') {
        orignalTxt = this.getCommonSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('common_sharing_message').selectionStart
        ).length
      } else if (type === 'Facebook') {
        orignalTxt = this.getFacebookSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('facebook_sharing_message').selectionStart
        ).length
      } else if (type === 'Twitter') {
        orignalTxt = this.getTwitterSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('twitter_sharing_message').selectionStart
        ).length
      } else if (type === 'Pinterest') {
        orignalTxt = this.getPinterestSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('pinterest_sharing_message').selectionStart
        ).length
      } else if (type === 'Instagram') {
        orignalTxt = this.getInstagramSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('instagram_sharing_message').selectionStart
        ).length
      } else if (type === 'Tumblr') {
        orignalTxt = this.getTumblrSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('tumblr_sharing_message').selectionStart
        ).length
      } else if (type === 'Linkedin') {
        orignalTxt = this.getLinkedinSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('linkedin_sharing_message').selectionStart
        ).length
      } else if (type === 'Gmb') {
        orignalTxt = this.getGmbSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('gmb_sharing_message').selectionStart
        ).length
      } else if (type === 'Youtube') {
        orignalTxt = this.getYoutubeSharingDetails.message
        cursorPosition = orignalTxt.slice(
          0,
          document.getElementById('youtube_sharing_message').selectionStart
        ).length
      } else return false

      const hastags = this.getTextHashTags(orignalTxt)

      if (hastags) {
        const beforeCursorPositionText = orignalTxt.substring(0, cursorPosition)
        const afterCursorPositionText = orignalTxt.substring(cursorPosition)

        let description = beforeCursorPositionText
        const spacePosition = afterCursorPositionText.indexOf(' ')
        const lineBreakPosition = afterCursorPositionText.indexOf('\n')

        // for including text after current position to space so that full tag can be used for query
        if (spacePosition > -1) {
          description =
            description + afterCursorPositionText.substring(0, spacePosition)
        } else {
          description = description + afterCursorPositionText
        }

        const tagStartPosition = description.lastIndexOf('#')
        message = description.substring(0, tagStartPosition)

        description = description.substring(tagStartPosition)
        const tagEndPosition = description.indexOf(' ')

        if (tagEndPosition !== -1) {
          message = message + description.substring(tagEndPosition)
        }

        if (suggestion.name) {
          message = message + '#' + suggestion.name + ' '
          // cursorPosition = cursorPosition + suggestion.name.length + 2// plus 2 for space and @
        }

        // Hack for line break and space case
        if (spacePosition > lineBreakPosition) {
          message =
            message + afterCursorPositionText.substring(lineBreakPosition)
        } else if (spacePosition < lineBreakPosition) {
          message = message + afterCursorPositionText.substring(spacePosition)
        }

        cursorPosition = cursorPosition + (message.length - orignalTxt.length)
        this.setSharingMessage(type, message, cursorPosition)
      }

      this.$store.commit(sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS, false)
      this.$store.commit(sharingTypes.SET_HASHTAGS_SUGGESTIONS, [])
    },

    focusSharingBox(type) {
      console.debug('Method:focusSharingBox', type)

      switch (type) {
        case 'Facebook':
          this.setSharingActiveTab('facebook_tab')
          break
        case 'Twitter':
          this.setSharingActiveTab('twitter_tab')
          break
        case 'Linkedin':
          this.setSharingActiveTab('linkedin_tab')
          break
        case 'Pinterest':
          this.setSharingActiveTab('pinterest_tab')
          break
        case 'Tumblr':
          this.setSharingActiveTab('tumblr_tab')
          break
        case 'Instagram':
          this.setSharingActiveTab('instagram_tab')
          break
        case 'Youtube':
          this.setSharingActiveTab('youtube_tab')
          break
        case 'Tiktok':
          this.setSharingActiveTab('tiktok_tab')
          break
        case 'Gmb':
          this.setSharingActiveTab('gmb_tab')
          break
      }
    },
    remainingCharactersCommon() {
      if (this.getAccountSelection.bluesky.length) {
        return {
          remaining:
              blueskyPostLimit - this.getCommonSharingDetails.message.length,
          type: 'Bluesky',
        }
      }
      if (this.getAccountSelection.threads.length) {
        return {
          remaining:
              threadsPostLimit - this.getCommonSharingDetails.message.length,
          type: 'Threads',
        }
      }
      if (this.getAccountSelection.twitter.length) {
        return {
          remaining:
            twitterPostLimit -
            twitterText.parseTweet(this.getCommonSharingDetails.message)
              .weightedLength,
          type: 'Twitter',
        }
      }
      if (this.getAccountSelection.pinterest.length)
        return {
          remaining:
            pinterestPostLimit - this.getCommonSharingDetails.message.length,
          type: 'Pinterest',
        }
      if (this.getAccountSelection.linkedin.length)
        return {
          remaining:
            linkedinPostLimit - this.getCommonSharingDetails.message.length,
          type: 'Linkedin',
        }
      if (this.getAccountSelection.gmb.length)
        return {
          remaining: gmbPostLimit - this.getCommonSharingDetails.message.length,
          type: 'Gmb',
        }
      if (this.getAccountSelection.instagram.length)
        return {
          remaining:
            instagramPostLimit - this.getCommonSharingDetails.message.length,
          type: 'Instagram',
        }
      if (this.getAccountSelection.youtube.length)
        return {
          remaining:
            youtubePostLimit - this.getCommonSharingDetails.message.length,
          type: 'Youtube',
        }
      if (this.getAccountSelection.facebook.length)
        return {
          remaining:
            facebookPostLimit - this.getCommonSharingDetails.message.length,
          type: 'Facebook',
        }
      if (this.getAccountSelection.tiktok.length)
        return {
          remaining:
            tiktokPostLimit - this.getCommonSharingDetails.message.length,
          type: 'Tiktok',
        }

      return { remaining: 0, type: '' }
    },
    remainingCharacters(type) {
      console.debug('Method:remainingCharacters')

      if (type === 'Common') {
        if (this.getAccountSelection.bluesky.length)
          return blueskyPostLimit - this.getCommonSharingDetails.message.length
        if (this.getAccountSelection.threads.length)
          return threadsPostLimit - this.getCommonSharingDetails.message.length
        if (this.getAccountSelection.twitter.length) {
          const remaining =
            twitterPostLimit -
            twitterText.parseTweet(this.getCommonSharingDetails.message)
              .weightedLength
          /* let urls = this.getLinks(this.getCommonSharingDetails.message)
          if (urls) {
            $(urls).each(function (index, el) {
              remaining = (remaining + el.length) - 23
            })
          } */
          return remaining
        }
        if (this.getAccountSelection.pinterest.length)
          return (
            pinterestPostLimit - this.getCommonSharingDetails.message.length
          )
        if (this.getAccountSelection.linkedin.length)
          return linkedinPostLimit - this.getCommonSharingDetails.message.length
        if (this.getAccountSelection.gmb.length)
          return gmbPostLimit - this.getCommonSharingDetails.message.length
        if (this.getAccountSelection.instagram.length)
          return (
            instagramPostLimit - this.getCommonSharingDetails.message.length
          )
        if (this.getAccountSelection.youtube.length)
          return youtubePostLimit - this.getCommonSharingDetails.message.length
        if (this.getAccountSelection.facebook.length)
          return facebookPostLimit - this.getCommonSharingDetails.message.length
        if (this.getAccountSelection.tiktok.length)
          return tiktokPostLimit - this.getCommonSharingDetails.message.length
        return 0
      }
      if (type === 'Bluesky')
        return blueskyPostLimit - this.getBlueskySharingDetails.message.length
      if (type === 'Threads')
        return threadsPostLimit - this.getFacebookSharingDetails.message.length
      if (type === 'Facebook')
        return facebookPostLimit - this.getFacebookSharingDetails.message.length
      if (type === 'Twitter') {
        const remaining =
          twitterPostLimit -
          twitterText.parseTweet(this.getTwitterSharingDetails.message)
            .weightedLength
        /* let urls = this.getLinks(this.getTwitterSharingDetails.message)
        if (urls) {
          $(urls).each(function (index, el) {
            remaining = (remaining + el.length) - 23
          })
        } */
        return remaining
      }
      if (type === 'Linkedin')
        return linkedinPostLimit - this.getLinkedinSharingDetails.message.length
      if (type === 'Pinterest')
        return (
          pinterestPostLimit - this.getPinterestSharingDetails.message.length
        )
      if (type === 'Gmb')
        return gmbPostLimit - this.getGmbSharingDetails.message.length
      if (type === 'Instagram')
        return (
          instagramPostLimit - this.getInstagramSharingDetails.message.length
        )
      if (type === 'Youtube')
        return youtubePostLimit - this.getYoutubeSharingDetails.message.length
      if (type === 'Tiktok')
        return tiktokPostLimit - this.getTiktokSharingDetails.message.length

      return 0
    },

    processSharingPreviewDesciption(description, type) {
      console.debug('Method:setProcessedTabHtml')
      const processedHtml = this.processSharingBoxHtml(description, type)
      return processedHtml.allowed
    },

    // old package code will remove in future
    addSharingEmoji(event, type) {
      console.debug(
        'Method::addSharingEmoji',
        type,
        event.currentTarget.children[0].attributes.alt.value
      )

      const emojiUnicode = event.currentTarget.children[0].attributes.alt.value

      const stateObject = this
      setTimeout(function () {
        let selector = ''
        switch (type) {
          case 'Common':
            selector = document.getElementById('common_sharing_message')
            break
          case 'Facebook':
            selector = document.getElementById('facebook_sharing_message')
            break
          case 'Twitter':
            selector = document.getElementById('twitter_sharing_message')
            break
          case 'Linkedin':
            selector = document.getElementById('linkedin_sharing_message')
            break
          case 'Pinterest':
            selector = document.getElementById('pinterest_sharing_message')
            break
          case 'Tumblr':
            selector = document.getElementById('tumblr_sharing_message')
            break
          case 'Instagram':
            selector = document.getElementById('instagram_sharing_message')
            break
          case 'Youtube':
            selector = document.getElementById('youtube_sharing_message')
            break
          case 'Gmb':
            selector = document.getElementById('gmb_sharing_message')
            break
        }

        const cursorPositionStart = selector.selectionStart
        selector.value =
          selector.value.substring(0, cursorPositionStart) +
          emojiUnicode +
          selector.value.substring(selector.selectionEnd)
        selector.selectionStart = cursorPositionStart + 2
        selector.selectionEnd = cursorPositionStart + 2
        stateObject.setSharingMessage(type, selector.value)
      }, 200)
    },

    addSharingEmojiFast(event, type) {
      console.debug('Method::addSharingEmojiFast', type, event)
      const emojiUnicode = event.native
      console.debug('Method::addSharingEmojiNew', emojiUnicode)
      const stateObject = this
      setTimeout(function () {
        let selector = ''
        switch (type) {
          case 'Common':
            selector = document.getElementById('common_sharing_message')
            break
          case 'Facebook':
            selector = document.getElementById('facebook_sharing_message')
            break
          case 'Twitter':
            selector = document.getElementById('twitter_sharing_message')
            break
          case 'Linkedin':
            selector = document.getElementById('linkedin_sharing_message')
            break
          case 'Pinterest':
            selector = document.getElementById('pinterest_sharing_message')
            break
          case 'Tumblr':
            selector = document.getElementById('tumblr_sharing_message')
            break
          case 'Instagram':
            selector = document.getElementById('instagram_sharing_message')
            break
          case 'Youtube':
            selector = document.getElementById('youtube_sharing_message')
            break
          case 'Gmb':
            selector = document.getElementById('gmb_sharing_message')
            break
          case 'comment':
            selector = document.getElementById('comment-box')
            break
          case 'comment-top':
            selector = document.getElementById('comment-box-top')
            break
          case 'comment-edit':
            selector = document.getElementById('comment-box-edit')
            break
          case 'comment-note':
            selector = document.getElementById('internal-note-box-top')
            break
        }
        if (type.includes('ThreadedTweets_')) {
          selector = document.getElementById(type)
        }
        const cursorPositionStart = selector.selectionStart
        selector.value =
          selector.value.substring(0, cursorPositionStart) +
          emojiUnicode +
          selector.value.substring(selector.selectionEnd)

        if (
          type === 'comment-box' ||
          type === 'comment-top' ||
          type === 'comment-edit' ||
          type === 'comment-note' ||
          type.includes('ThreadedTweets_')
        ) {
          selector.dispatchEvent(new Event('input'))
        }
        selector.selectionStart = cursorPositionStart + 2
        selector.selectionEnd = cursorPositionStart + 2
        if (!type.includes('ThreadedTweets_')) {
          stateObject.setSharingMessage(type, selector.value)
        }
      }, 200)
    },

    addSocialSharesTags(tag, index) {
      console.debug('Method:addSocialSharesTags', tag, index)

      let description = ''

      description = this.getCommonSharingDetails.message + ' ' + tag
      this.setSharingMessage('Common', description)

      if (this.getFacebookSharingDetails.message) {
        description = this.getFacebookSharingDetails.message + ' ' + tag
        this.setSharingMessage('Facebook', description)
      }

      if (this.getTwitterSharingDetails.message) {
        description = this.getTwitterSharingDetails.message + ' ' + tag
        this.setSharingMessage('Twitter', description)
      }

      if (this.getLinkedinSharingDetails.message) {
        description = this.getLinkedinSharingDetails.message + ' ' + tag
        this.setSharingMessage('Linkedin', description)
      }

      if (this.getPinterestSharingDetails.message) {
        description = this.getPinterestSharingDetails.message + ' ' + tag
        this.setSharingMessage('Pinterest', description)
      }

      if (this.getTumblrSharingDetails.message) {
        description = this.getTumblrSharingDetails.message + ' ' + tag
        this.setSharingMessage('Tumblr', description)
      }

      if (this.getInstagramSharingDetails.message) {
        description = this.getInstagramSharingDetails.message + ' ' + tag
        this.setSharingMessage('Instagram', description)
      }

      if (this.getYoutubeSharingDetails.message) {
        description = this.getYoutubeSharingDetails.message + ' ' + tag
        this.setSharingMessage('Youtube', description)
      }

      if (this.getGmbSharingDetails.message) {
        description = this.getGmbSharingDetails.message + ' ' + tag
        this.setSharingMessage('Gmb', description)
      }
      const tagArray = this.getSocialSharingSelection.tagsSuggestions.items
      const finalArray = []
      let loopIndex = 0

      tagArray.forEach(function (element) {
        if (loopIndex !== index) {
          finalArray.push(element)
        }
        loopIndex++
      })

      this.setSocialSharingTagsSuggestionsItems(finalArray)
      // this.allTextareaFocus()
    },

    zapierAttachmentError(details) {
      return {
        is_video: false, // details.video && details.video.link,
        multiple_images:
          typeof details.image !== 'undefined' && details.image.length > 10,
      }
    },

    /**
     * adding formating to the selected string for the social boxes
     * @param type
     * @param variant
     */
    sharingMessageFormatting(type, variant) {
      console.debug('Method::sharingMessageFormatting', type, variant)

      const element = document.getElementById(
        type.toLowerCase() + '_sharing_message'
      )
      if (element) {
        const message = element.value
        const startPosition = element.selectionStart
        const endPosition = element.selectionEnd
        const seletedText = message.substring(startPosition, endPosition)
        const formattedText = this.toUnicodeVariant(seletedText, variant)
        console.debug('sharingMessageFormatting', seletedText, formattedText)
        const replacedText =
          message.substring(0, startPosition) +
          formattedText +
          message.substring(endPosition)
        this.setSharingMessage(type, replacedText)
      }
    },
  },
}
