<template>
  <div class="h-full" :class="customClass">
    <div class="w-full cst-drop-shadow bg-white rounded-xl border border-[#DBDBDB] mb-2">
      <div class="flex items-center px-3 py-3 font-bold">
        <img
            class="rounded-full object-cover border border-solid border-black"
            width="40"
            height="40"
            :src="account?.platform_logo"
            alt=""
            @error="
              $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
            "
        />
        <div class="px-2 font-bold">
          {{ account?.platform_name }}
        </div>
        <div
            class="ml-auto inline-flex justify-center items-center w-8 h-8 rounded-lg bg-gray-100"
        >
          <img
              src="@assets/img/integration/threads-rounded.svg"
              alt=""
              class="w-6"
          />
        </div>
      </div>
      <div
          v-if="getDescription"
          class="items-center px-3 py-1 pb-3"
      >
      <span
          class="whitespace-pre-wrap leading-4"
          v-html="getDescription"
      ></span>
        <span
            v-if="getDescription.length > charLimit && loadMore"
            class="text-blue-300 cursor-pointer"
            @click="changeLoadMore()"
        >
            Load More</span
        >
        <span
            v-if="getDescription.length > charLimit && !loadMore"
            class="text-blue-300 cursor-pointer"
            @click="changeLoadMore()"
        >
            Show Less</span
        >
      </div>
      <ThreadsMultimediaPreview
          v-if="shouldRenderThreadsPreview"
          :class="{ 'max-w-[350px] mx-auto': type === 'planner' }"
          :detail="detail"
          :hide-footer="hideFooter"
          @displayFile="displayFile"
      />
      <!-- Link Preview -->
      <ThreadsLinkPreview v-else-if="detail.url && detail.multimedia.length > 0" :detail="detail" :isMainPreview="false" />

      <div class="flex items-center px-3 py-2 reaction-icons space-x-5 text-xl mt-2">
        <i class="far fa-heart"></i>
        <i class="far fa-comment"></i>
        <i class="far fa-paper-plane"></i>
      </div>

      <!-- ThreadsMultiPreview --->
      <div v-if="multiThreads?.length">
        <hr/>
        <div v-for="(thread, index) in multiThreads" :key="index">
          <ThreadsMultiPreview :account="account" :detail="thread" :multiThreadsDetailItem="multiThreadsDetails[index]" />
        </div>
      </div>

    </div>
  </div>
  <VueEasyLightbox
      :visible="visible"
      :imgs="detail.image"
      :index="selectedImageIndex"
      @hide="visible = false"
  />
</template>

<script setup>
import {ref, computed, inject} from 'vue'
import VueEasyLightbox from 'vue-easy-lightbox'
import ThreadsMultimediaPreview from '@src/modules/composer_v2/components/SocialPreviews/ThreadsMultimediaPreview'
import ThreadsLinkPreview from '@src/modules/composer_v2/components/SocialPreviews/ThreadsLinkPreview'
import {EventBus} from '@common/lib/event-bus'
import {parseDescriptionHtml} from '@common/lib/helper'
import ThreadsMultiPreview from "@modules/composer_v2/components/SocialPreviews/ThreadsMultiPreview.vue";

// Props
const props = defineProps({
  customClass: {
    type: String,
    default: '',
  },
  account: {
    type: [Object, Array],
    default: () => ({}),
    required: true,
  },
  detail: {
    type: Object,
    default: () => ({}),
    required: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
    required: false,
  },
  multiThreads: {
    type: Array,
    default: () => [],
    required: false,
  },
  type: {
    type: String,
    default: 'composer',
  },
  whiteBg: {
    type: Boolean,
    default: true,
  },
  multiThreadsDetails: {
    type: Array,
    default: () => [],
    required: false,
  }
})

// Reactive data
const loadMore = ref(true)
const charLimit = ref(500)
const visible = ref(false)
const selectedImageIndex = ref(0)
const root = inject('root')
const {$bvModal} = root

// Computed properties
const shouldRenderThreadsPreview = computed(() => {
  return (props.detail?.multimedia?.length || props.detail?.image?.length || props.detail?.video.link) && !props.detail.url
})

const getDescription = computed(() => {
  let msg = props.detail.message || ''
  msg = parseDescriptionHtml(msg, 5000)
  if (msg && msg.length > charLimit.value && loadMore.value) {
    msg = msg.substr(0, charLimit.value).concat('...')
    return msg
  }
  return msg
})

// Methods
const changeLoadMore = () => {
  loadMore.value = !loadMore.value
}

const getHostOnly = (url) => {
  const urlParts = /^(?:\w+:\/\/)?([^/]+)([^?]*)\??(.*)$/.exec(url)
  return urlParts[1] ? urlParts[1] : ''
}

const displayFile = (type, media, index) => {
  EventBus.$emit('displayFile', {
    type,
    media,
    index,
  })
  $bvModal.show('display-file-modal')
}
</script>
