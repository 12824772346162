<template>
  <div>
    <header class="h-[60px] bg-[#005fd0] flex items-center px-4 py-2">
      <a
        :href="isWhiteLabelDomain ? location : 'https://app.contentstudio.io/'"
        target="_blank"
        class="flex items-center justify-center gap-2"
      >
        <img
          :src="
            isWhiteLabelDomain
              ? appData.brandLogo
              : 'https://contentstudio.io/images/Logo%20White.svg'
          "
          :alt="appData.businessName"
          height="30"
        />
      </a>
    </header>

    <div
      v-if="state.loading && !state.loadingMore"
      class="p-12 my-20 w-128 mx-auto text-center text-lg"
    >
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>
    <template v-else-if="!state.isError && state.token">
      <main class="px-4 py-6 bg-white">
        <div
          class="
            flex flex-row
            items-center
            justify-between
            border-0
            h-16
            mb-4
            relative
          "
        >
          <div class="flex flex-col justify-start gap-y-2 mt-2">
            <p class="capitalize cs-modal-title ml-6">{{ plans.title }}</p>
            <div>
              <!-- Bulk Actions-->
              <CstDropdown
                  v-if="state.currentView === 'list'"
                  class="ml-6 my-2"
                  button-classes="flex justify-between bg-main-gray gap-y-4"
                  size="small"
                  disabled-text="Select two or more posts to perform bulk operations"
                  :disabled="state.selectedPlans.length < 2"
              >
                <template v-slot:selected> Select Action </template>
                <template v-slot>
                  <CstDropdownItem @click="handleBulkAction('approve')">
                    Approve
                  </CstDropdownItem>
                  <CstDropdownItem @click="handleBulkAction('reject')">
                    Reject
                  </CstDropdownItem>
                  <CstDropdownItem @click="handleBulkAction('comment')">
                    Comment
                  </CstDropdownItem>
                </template>
              </CstDropdown>

              <!-- Filters -->
              <CstDropdown
                  class="ml-6 my-2"
                  container-classes="w-[200px] !left-0"
                  size="small"
              >
                <template v-slot:selected>
                    <i class="far fa-filter"></i>
                </template>

                <template v-slot>
                  <CstDropdownItem v-for="filter in state.filters" :key="filter.key" :close-on-click='false'>
                    <div class="flex justify-between items-center">
                      <CstSimpleCheckBox
                          v-model="state.selectedFilters"
                          :name="filter.key"
                          :value="filter.key"
                          :reverse="true"
                      >
                        <template v-slot:label class="pt-1">{{ filter.value }}</template>
                      </CstSimpleCheckBox>
                    </div>
                  </CstDropdownItem>

                </template>
              </CstDropdown>
            </div>
          </div>
          <div class="flex flex-col items-center gap-y-2">
            <!-- View Switcher -->
            <div class="cst-switch">
              <div
                class="
                  inline-flex
                  justify-between
                  items-center
                  cursor-pointer
                  p-1
                  rounded-xl
                  bg-gray-200
                "
              >
                <a v-tooltip="'Calendar View'" @click="switchView('calendar')">
                  <div
                    :class="{
                      'bg-white text-primary-variant-1':
                        state.currentView === 'calendar',
                    }"
                    class="
                      p-2
                      h-9
                      w-9
                      flex
                      justify-center
                      items-center
                      rounded-md
                    "
                  >
                    <img
                      v-if="state.currentView === 'calendar'"
                      src="@assets/img/icons/planner/planner_header/planner-calendar-view-active.svg"
                      alt="Active Calendar View Icon"
                    />
                    <img
                      v-else
                      src="@assets/img/icons/planner/planner_header/planner-calendar-view.svg"
                      alt="Inactive Calendar View Icon"
                    />
                  </div>
                </a>
                <a v-tooltip="'List View'" @click="switchView('list')">
                  <div
                    :class="{
                      'bg-white text-primary-variant-1':
                        state.currentView === 'list',
                    }"
                    class="
                      p-2
                      h-9
                      w-9
                      flex
                      justify-center
                      items-center
                      rounded-md
                    "
                  >
                    <img
                      v-if="state.currentView === 'list'"
                      src="@assets/img/icons/planner/planner_header/planner-list-view-active.svg"
                      alt="Active List View Icon"
                    />
                    <img
                      v-else
                      src="@assets/img/icons/planner/planner_header/planner-list-view.svg"
                      alt="Inactive List View Icon"
                    />
                  </div>
                </a>
              </div>
            </div>

            <!-- Count -->
            <div class="flex justify-end space-x-3 items-center">
              <span class="font-weight-500 opacity-70">
                Showing {{ filteredPlans?.length }} of {{ plans.total }} post(s)
              </span>
            </div>
          </div>
        </div>

        <!-- Calendar View -->
        <div
          v-if="state.currentView === 'calendar'"
          class="planner-calender-main"
        >
          <transition name="fade-list-item">
            <template v-if="!filteredPlans.length">
              <div class="warning_box text-center mx-0">
                <p>No posts found.</p>
              </div>
            </template>
          </transition>

          <FullCalendar
            class="planner-app-calendar"
            ref="external-calendar"
            :options="{
              ...state.calendarOptions,
              timeZone: plans.timezone,
              events: filteredPlans, // Use the computed property here
            }"
          >
            <template v-slot:eventContent="arg">
              <ExternalCalendarEvent
                :event="arg.event"
                :time-zone="plans.timezone"
                @on-lightbox="onLightbox"
                @preview-plan="previewPlan"
              />
            </template>
          </FullCalendar>
        </div>

        <!-- List View -->
        <div v-else>
          <div
            v-dragscroll
            class="
              f-table
              bg-white
              w-full
              shadow-sm
              select-none
              text-sm text-left text-gray-900
              overflow-auto
            "
            @scroll="handleScroll"
          >
            <!--   Header   -->
            <div
              class="
                flex flex-nowrap flex-row
                sticky
                top-0
                w-full
                bg-[#f3f4f4]
                z-20
              "
            >
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  !w-[50px]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                  sticky
                  left-0
                  flex
                  items-center
                "
              >
                <CstSimpleCheckBox
                  type="checkbox"
                  :checked="isAllSelected"
                  @change="toggleSelectAll"
                />
              </div>

              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  !w-[400px]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                  sticky
                  left-0
                  flex
                  items-center
                "
              >
                Post
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Accounts
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Status
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Post Type
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Created By
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Created At
              </div>
              <div
                class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                Last Updated At
              </div>
              <div
                  class="
                  f-table-cell
                  bg-[#f3f4f4]
                  px-6
                  py-3
                  cursor-pointer
                  capitalize
                  font-weight-500
                "
              >
                External Actions
              </div>
              <!--    Actions      -->
              <div
                  class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-weight-500 !w-[120px] flex-1"
              ></div>
            </div>

            <!--   Body   -->
            <div>
              <DataRow
                v-for="(plan, i) in filteredPlans"
                :key="i"
                :plan="plan"
                :selected="state?.selectedPlans?.includes(plan._id)"
                :timezone="plans?.timezone"
                @on-plan-select="onPlanSelect"
                @on-plan-status-click="onPlanStatusClick"
                @on-lightbox="onLightbox"
                @preview-plan="previewPlan"
              />

              <!--    Load More      -->
              <div
                v-if="state.loadingMore"
                class="flex flex-nowrap flex-row w-full f-table-row"
              >
                <div class="f-table-cell px-6 py-4 sticky left-0">
                  <div class="flex items-center text-base">
                    <i class="fad fa-spinner-third fa-spin"></i>
                    <span class="ml-3">Loading more...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </template>
    <template v-else>
      <div
        class="
          rounded-xl
          bg-white
          shadow-sm
          p-12
          my-20
          w-128
          mx-auto
          text-center text-lg
        "
      >
        The link is invalid or disabled by the user.
      </div>
    </template>

    <PublicPasswordProtectedModal
      :link-id="state.linkId"
      @on-verify="onVerify"
    />

    <ExternalActionsModal :token="state.token" />

    <VueEasyLightbox
      :visible="lightBox.imageVisible"
      :imgs="lightBox.media"
      @hide="handleHide"
    ></VueEasyLightbox>

    <DisplayFile></DisplayFile>

    <b-modal
      id="post-status-modal"
      size="lg"
      centered
      :no-close-on-backdrop="true"
      hide-header
      hide-footer
      dialog-class="max-w-7xl"
    >
      <PlannerPostStatus
        v-if="state.focusedPlan"
        :item="state.focusedPlan"
        modal-id="post-status-modal"
      />
    </b-modal>

    <PlannerPostPreview
      :plan="state.currentPlanInPreview"
      :token="state.token"
      :disable-left="planPreviewDisableLeft()"
      :disable-right="planPreviewDisableRight()"
      :external-post-preview="true"
      @next-plan="previewNextPlan"
      @previous-plan="previewPreviousPlan"
      @approve-with-comment="approveWithComment"
      @reject-with-comment="rejectWithComment"
    />
  </div>
</template>

<script>
import { defineComponent, inject, onMounted, reactive } from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import ExternalCalendarEvent from '@src/modules/planner_v2/components/ExternalCalendarEvent'
import { dragscroll } from 'vue-dragscroll'
import PublicPasswordProtectedModal from '@src/modules/planner_v2/components/PublicPasswordProtectedModal'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import {
  ACTION_TYPES,
  serviceShareLink,
} from '@src/modules/planner_v2/services'
import DataRow from '@src/modules/planner_v2/components/DataRow'
import ExternalActionsModal from '@src/modules/planner_v2/components/ExternalActionsModal'
import VueEasyLightbox from 'vue-easy-lightbox'
import { EventBus } from '@common/lib/event-bus'
import DisplayFile from '@src/modules/publish/components/dialogs/DisplayFile'
import PlannerPostStatus from '@src/modules/planner_v2/components/PlannerPostStatus'
import PlannerPostPreview from '@src/modules/planner_v2/components/PlannerPostPreview_v2.vue'
import { useStore } from '@state/base'
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'
import { computed } from 'vue'
import CstSimpleCheckBox from '@ui/CheckBox/CstSimpleCheckbox.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
export default defineComponent({
  name: 'SharePlans',
  components: {
    CstDropdownItem,
    CstDropdown,
    PlannerPostPreview,
    PublicPasswordProtectedModal,
    DataRow,
    ExternalActionsModal,
    VueEasyLightbox,
    DisplayFile,
    PlannerPostStatus,
    FullCalendar,
    ExternalCalendarEvent,
    dayGridPlugin,
    interactionPlugin,
    momentTimezonePlugin,
    CstSimpleCheckBox,
  },
  directives: {
    dragscroll,
  },
  setup() {
    const root = inject('root')
    const { $bvModal, $route } = root
    const { dispatch,commit } = useStore()
    const { appData, isWhiteLabelDomain } = useWhiteLabelApplication()

    const location = window.location?.origin
    const state = reactive({
      linkId: '',
      isPasswordProtected: false,
      isError: false,
      loading: false,
      loadingMore: false,
      token: '',
      selectedAll: false,
      selectedPlans: [],
      focusedPlan: {},
      currentPlanIndex: -1,
      currentPlanInPreview: {},
      currentView: 'list',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, momentTimezonePlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek',
        },
        editable: false,
        dayMaxEvents: 10,
        weekends: true,
        contentHeight: 'auto',
        expandRows: false,
        dayHeaderFormat: { weekday: 'long' },
        views: {
          week: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
          day: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
        },
        firstDay: 1,
      },
      showBulkActions: false,
      selectedFilters: [],
      filters: [
          { key: 'all', value: 'All' },
          { key: 'approved', value: 'Approved' },
          { key: 'rejected', value: 'Rejected' },
          {key:'commented', value: 'Commented'},
      ],
    })

    const plans = reactive({
      title: '',
      data: [],
      page: 1,
      total: 0,
      isNextPageExists: false,
    })

    const lightBox = reactive({
      imageVisible: false,
      videoVisible: false,
      media: [],
    })



    const getCurrentUser = () => {
      //check local storage for email
      return  localStorage.getItem('external_email') || ''
    }

    const filteredPlans = computed(() => {
      // If no filters selected or "all" is selected, return all plans
      if (state.selectedFilters.length === 0 || state.selectedFilters.includes('all')) {
        return plans.data;
      }

      // Filter plans based on selected filters
      return plans.data.filter((plan) => {
        return state.selectedFilters.some((filter) => {
          switch (filter) {
            case 'approved':
              return plan?.external_actions?.some(action =>
                  action.type === 'approve' && action.email === getCurrentUser()
              );

            case 'rejected':
              return plan.external_actions?.some(action =>
                  action.type === 'reject' && action.email === getCurrentUser()
              );

            case 'commented':
              return plan.external_comments?.some(comment =>
                  comment.email === getCurrentUser()
              );

            default:
              return false;
          }
        });
      });
    });

    const isAllSelected = computed(() => {
      return (
        filteredPlans.value.length > 0 &&
        state.selectedPlans.length === filteredPlans.value.length
      )
    })

    const toggleSelectAll = () => {
      if (isAllSelected.value) {
        state.selectedPlans = []
      } else {
        state.selectedPlans = filteredPlans.value.map((plan) => plan._id)
      }
      state.showBulkActions = state.selectedPlans.length > 0
    }

    const handleFilterChange = (filters) => {
      state.filters = filters
      state.selectedPlans = [] // Clear selections when filter changes
      state.showBulkActions = false
    }

    const switchView = (view) => {
      state.currentView = view

      // Reset states and fetch plans
      plans.data = []
      plans.page = 1
      plans.total = 0
      plans.isNextPageExists = false
      fetchPlans()
    }

    /**
     * Returns true/false if the previous button should be disabled.
     * @returns {boolean}
     */
    const planPreviewDisableLeft = () => {
      return state.currentPlanIndex === 0
    }

    /**
     * Returns true/false if the next button should be disabled.
     * @returns {boolean}
     */
    const planPreviewDisableRight = () => {
      return state.currentPlanIndex === filteredPlans.value.length - 1
    }

    onMounted(async () => {
      state.linkId = $route.params.id

      if (!state.linkId) {
        state.isError = true
        await dispatch('toastNotification', {
          message: 'The provided link is invalid',
          type: 'error',
        })
      }

      await getShareLink(state.linkId)

      EventBus.$on('post-status-modal', (post) => {
        onPlanStatusClick(post)
      })

      EventBus.$on('external-actions::complete', async () => {
        // Reset selected plans
        state.selectedPlans = []
        state.showBulkActions = false

        // Reset page state and refetch plans
        plans.data = []
        plans.page = 1
        plans.total = 0
        plans.isNextPageExists = false

        await fetchPlans()
      })
    })

    /**
     * Method to handle scroll event
     */
    const handleScroll = () => {
      const element = document.querySelector('.f-table')
      if (element) {
        const { scrollTop, scrollHeight, clientHeight } = element
        const buffer = 10 // Adjust the buffer value as needed

        if (scrollTop + clientHeight >= scrollHeight - buffer) {
          fetchNextPage()
        }
        console.log('At Bottom')
      }
    }

    // Attach the event listener to

    /**
     * Method to get share link
     */
    const getShareLink = async (id) => {
      state.loading = true
      try {
        const response = await serviceShareLink(ACTION_TYPES.GET_LINK, {
          link_id: id,
        })
        plans.title = response?.name
        if (response.is_password_protected) {
          state.isPasswordProtected = true
          $bvModal.show('public-password-protected')
        } else {
          state.token = response.token
        }
      } catch (error) {
        state.isError = true
        await dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        state.loading = false

        if (state.token) {
          await fetchPlans()
        }
      }
    }

    /**
     * Method to verify password
     */
    const onVerify = (token) => {
      state.token = token
      $bvModal.hide('public-password-protected')

      fetchPlans()
    }

    /**
     * Method to fetch plans
     */
    const fetchPlans = async (page = 1) => {
      if (state.loading) return
      state.loading = true
      state.loadingMore = page > 1

      try {
        const response = await serviceShareLink(ACTION_TYPES.FETCH_PLANS, {
          token: state.token,
          page,
          limit: state.currentView === 'calendar' ? 500 : 10,
        })
        if (plans.data.length) {
          plans.data = [...plans.data, ...response.plans.data]
          state.selectedAll = false
        } else {
          plans.data = response.plans.data
        }

        plans.page = response.plans.current_page
        plans.total = response.plans.total
        plans.isNextPageExists = response.plans.next_page_url !== null
        plans.timezone = response.timezone
      } catch (error) {
        await dispatch('toastNotification', {
          message: error.message || UNKNOWN_ERROR,
          type: 'error',
        })
      } finally {
        state.loading = false
        state.loadingMore = false
      }
    }

    /**
     * Method to fetch next page
     */
    const fetchNextPage = () => {
      if (plans.isNextPageExists) {
        fetchPlans(plans.page + 1)
      }
    }

    /**
     * Method to select all plans
     */
    const selectAll = () => {
      if (state.selectedAll) {
        state.selectedPlans = plans.data.map((plan) => plan._id)
      } else {
        state.selectedPlans = []
      }
    }

    /**
     * Method to select plan
     */
    const onPlanSelect = (planId) => {
      if (state.selectedPlans.includes(planId)) {
        state.selectedPlans = state.selectedPlans
          .filter((id) => id !== planId)
          .map((id) => id)
      } else {
        state.selectedPlans.push(planId)
      }

      state.selectedAll = state.selectedPlans.length === plans.data.length
    }

    const handleBulkAction = (action) => {
      if (!state.selectedPlans.length) {
        dispatch('toastNotification', {
          message: 'Please select at least one plan',
          type: 'warning'
        })
        return
      }

      switch (action) {
        case 'approve':
          EventBus.$emit('external-actions::show', {
            type: 'approve',
            plans: state.selectedPlans
          })
          break

        case 'reject':
          EventBus.$emit('external-actions::show', {
            type: 'reject',
            plans: state.selectedPlans
          })
          break

        case 'comment':
          EventBus.$emit('external-actions::show', {
            type: 'comment',
            plans: state.selectedPlans
          })
          break
      }
    }

    const handleHide = () => {
      lightBox.imageVisible = false
    }

    /**
     * Method to show lightbox
     * @param type - type of media, can be image or video
     * @param media Array of urls with type image and single url with type video
     */
    const onLightbox = (type = 'image', media) => {
      if (type === 'image') {
        lightBox.imageVisible = true
        lightBox.media = media
      } else {
        lightBox.videoVisible = true
        EventBus.$emit('displayFile', {
          type,
          media,
          index: 0,
        })
        $bvModal.show('display-file-modal')
      }
    }

    /**
     * Method to show plan status
     * @param plan
     */
    const onPlanStatusClick = (plan) => {
      state.focusedPlan = plan
      $bvModal.show('post-status-modal')
    }

    const previewPlan = (plan) => {
      state.focusedPlan = plan
      const plantIndex = plans.data.findIndex(
        (item) => item._id === state.focusedPlan._id
      )
      state.currentPlanIndex = plantIndex
      state.currentPlanInPreview = plans.data[plantIndex]
      $bvModal.show('planner-post-preview')
    }

    /**
     * onClick event for previewing next plan
     */
    const previewNextPlan = () => {
      if (state.currentPlanIndex === filteredPlans.value.length - 1) return
      state.currentPlanInPreview = filteredPlans.value[++state.currentPlanIndex]
    }

    /**
     * onClick event for previewing previous plan
     */
    const previewPreviousPlan = () => {
      if (state.currentPlanIndex === 0) return
      state.currentPlanInPreview = filteredPlans.value[--state.currentPlanIndex]
    }

    /**
     * Event for displaying the approve modal
     * @param item
     */
    const approveWithComment = (item) => {
      console.log('METHOD::approveWithComment ~ item -> ', item)
      state.planForApproval = item
      EventBus.$emit('external-actions::show', {
        type: 'approve',
        plans: state.planForApproval,
      })
    }

    /**
     * Event for displaying the reject modal
     * @param item
     */
    const rejectWithComment = (item) => {
      console.log('METHOD::rejectWithComment ~ item -> ', item)
      state.planForReject = item
      EventBus.$emit('external-actions::show', {
        type: 'reject',
        plans: state.planForReject,
      })
    }

    return {
      plans,
      state,
      lightBox,
      appData,
      isWhiteLabelDomain,
      location,
      onVerify,
      fetchNextPage,
      selectAll,
      onPlanSelect,
      handleScroll,
      handleBulkAction,
      handleHide,
      onLightbox,
      onPlanStatusClick,
      previewPlan,
      previewNextPlan,
      previewPreviousPlan,
      approveWithComment,
      rejectWithComment,
      planPreviewDisableRight,
      planPreviewDisableLeft,
      switchView,
      isAllSelected,
      handleFilterChange,
      toggleSelectAll,
      filteredPlans,
    }
  },
})
</script>

<style lang="scss" scoped>
.f-table {
  border: 1px solid #eee;
  border-radius: 0.375rem;

  // 100vh - 60px (header) - 3rem (top + bottom paddings) - 2.75rem (Bulk Actions) - 1rem (Bulk Action top + bottom margins)
  height: calc(100vh - 60px - 3rem - 2.75rem - 1rem);
}

.f-table-cell {
  @apply w-[245px] flex-shrink-0;

  @media (max-width: 1280px) {
    @apply w-[200px];
  }
}

.f-table-cell-center-y {
  @apply flex items-center;
}


:deep(.fc-h-event) {
  border: none !important;
  background-color: transparent !important;
}

</style>
