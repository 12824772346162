import DownloadReports from '@src/modules/analytics/components/reports/DownloadReports'
import ReportsSettings from '@src/modules/setting/components/ReportSettings'

const MainAnalytics = () =>
  import(
    /* webpackChunkName: "analytics_v3" */ '@src/modules/analytics_v3/views/MainAnalytics.vue'
  )

const AnalyticsOverview = () =>
  import(
    /* webpackChunkName: "AnalyticsOverview" */ '@src/modules/analytics/views/overview/AnalyticsOverview'
  )

const FacebookOverview = () =>
  import(
    /* webpackChunkName: "FacebookAnalyticsOverview" */ '@src/modules/analytics/views/facebook_v2/MainComponent.vue'
  )
const InstagramOverview = () =>
  import(
    /* webpackChunkName: "InstagramAnalyticsOverview" */ '@src/modules/analytics/views/instagram_v2/MainComponent.vue'
  )
const TwitterOverview = () =>
  import(
    /* webpackChunkName: "TwitterAnalyticsOverview" */ '@src/modules/analytics/views/twitter/MainComponent.vue'
  )

const PinterestOverview = () =>
  import(
    /* webpackChunkName: "PinterestAnalyticsOverview" */ '@src/modules/analytics/views/pinterest/MainComponent'
  )
const LinkedinOverview = () =>
  import(
    /* webpackChunkName: "LinkedinAnalyticsOverview" */ '@src/modules/analytics/views/linkedin_v2/MainComponent.vue'
  )
const TiktokOverview = () =>
  import(
    /* webpackChunkName: "TiktokAnalyticsOverview" */ '@src/modules/analytics/views/tiktok/MainComponent'
  )
const YoutubeOverview = () =>
  import(
    /* webpackChunkName: "YoutubeAnalyticsOverview" */ '@src/modules/analytics/views/youtube/MainComponent'
  )
const MyReport = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics/components/reports/MyReport.vue'
  )

const FacebookCompetitorReport = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics_v3/views/facebook/FacebookCompetitorReport.vue'
  )

const InstagramCompetitorReport = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics_v3/views/instagram/InstagramCompetitorReport.vue'
  )

const CompetitorAnalyticsLanding = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics_v3/views/common/CompetitorAnalyticsLanding.vue'
  )

const ReportsList = () =>
  import(
    /* webpackChunkName: "analyze" */ '@src/modules/analytics_v3/views/common/ReportsList.vue'
  )

const LabelsAndCampaignsOverview = () =>
  import(
    /* webpackChunkName: "LabelsAndCampaignsOverview" */ '@src/modules/analytics/views/performance-report/label-and-campaign/MainComponent'
  )

const UserMavenOverview = () =>
  import(
    /* webpackChunkName: "LabelsAndCampaignsOverview" */ '@src/modules/analytics/views/web-analytics/usermaven/MainComponent.vue'
  )

export const analyticsRoutesV3 = {
  path: '/:workspace/analyze',
  name: 'analytics_v3',
  redirect: { name: 'analytics_overview_v3' },
  component: MainAnalytics,
  meta: {
    title: 'Analytics',
  },
  children: [
    {
      path: 'competitor-analytics',
      name: 'competitor_analytics',
      component: CompetitorAnalyticsLanding,
      meta: {
        title: 'Competitor Analytics',
      },
    },

    {
      path: 'instagram-competitor/:reportId',
      name: 'instagram_competitor_v3',
      component: InstagramCompetitorReport,
      meta: {
        title: 'Instagram Competitor',
      },
    },
    {
      path: 'instagram-competitor/',
      name: 'instagram_competitor_overview_v3',
      component: ReportsList,
      meta: {
        title: 'Instagram Competitor',
      },
    },
    {
      path: 'facebook/:accountId?',
      component: FacebookOverview,
      name: 'facebook_analytics_v3',
      meta: {
        title: 'Facebook | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'facebook-competitor/:reportId',
      component: FacebookCompetitorReport,
      name: 'facebook_competitor_v3',
      meta: {
        title: 'Facebook Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'facebook-competitor/',
      component: ReportsList,
      name: 'facebook_competitor_overview_v3',
      meta: {
        title: 'Facebook Competitors | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'twitter/:accountId?',
      component: TwitterOverview,
      name: 'twitter_analytics_v3',
      meta: {
        title: 'X (Twitter) | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'reports/',
      component: MyReport,
      name: 'my_report_v3',
      meta: {
        title: 'My Report | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'download_reports/',
      component: DownloadReports,
      name: 'download_reports_v3',
      meta: {
        title: 'Download Reports | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'reports_setting/',
      component: ReportsSettings,
      name: 'reports_setting_v3',
      meta: {
        title: 'Download Reports | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'overview/',
      component: AnalyticsOverview,
      name: 'analytics_overview_v3',
      meta: {
        title: 'Overview | Analytics',
      },
    },
    {
      path: 'pinterest/:accountId?',
      component: PinterestOverview,
      name: 'pinterest_analytics_v3',
      meta: {
        title: 'Pinterest | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'linkedin/:accountId?',
      component: LinkedinOverview,
      name: 'linkedin_analytics_v3',
      meta: {
        title: 'Linkedin | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'tiktok/:accountId?',
      component: TiktokOverview,
      name: 'tiktok_analytics_v3',
      meta: {
        title: 'TikTok | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'youtube/:accountId?',
      component: YoutubeOverview,
      name: 'youtube_analytics_v3',
      meta: {
        title: 'Youtube | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'instagram/:accountId?',
      component: InstagramOverview,
      name: 'instagram_analytics_v3',
      meta: {
        title: 'Instagram | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'campaign-and-label/',
      component: LabelsAndCampaignsOverview,
      name: 'campaign_and_label',
      meta: {
        title: 'Campaign & Label | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
    {
      path: 'user-maven/',
      component: UserMavenOverview,
      name: 'user_maven',
      meta: {
        title: 'Usermaven | Analytics',
      },
      // beforeEnter: (to, from, next) => analyticsFeatureLockCheck(to, from, next)
    },
  ],
}
