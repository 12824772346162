import { TEXT_IMAGE_PREVIEW_URL } from '@src/config/api-utils'

export default function useString() {
  function getNumberOfWordsFromString(str, wordsCount = 2) {
    if (countWords(str) > wordsCount) {
      return str.split(' ').slice(0, wordsCount).join(' ') + '...'
    }

    return str.split(' ').slice(0, wordsCount).join(' ')
  }

  function countWords(str) {
    return str.split(' ').length
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  /**
   * @description used throughout the app in case of fallback images, takes the letter and returns a thumbnail image for that.
   */
  function fallbackImagePreview(
    text,
    bgColor = '97d56c',
    textColor = 'green',
    fontSize = 30
  ) {
    return `${TEXT_IMAGE_PREVIEW_URL}/text_preview/?bg_color=%23${bgColor}&text_color=${textColor}&font_size=${fontSize}&text=${text}`
  }

  /**
   * Truncates a string to a specified length and adds ellipsis if necessary
   * @param {string} str - The input string to truncate
   * @param {number} num - The maximum length of the string before truncation
   * @returns {string|undefined} The truncated string with ellipsis, or the original string if shorter than num, or undefined if str is falsy
   */
  function truncateString(str, num) {
    if (str) {
      if (str.length > num) {
        return str.slice(0, num) + '...'
      } else {
        return str
      }
    }
  }

  return {
    countWords,
    getNumberOfWordsFromString,
    capitalizeFirstLetter,
    fallbackImagePreview,
    truncateString,
  }
}
