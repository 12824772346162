<template>
  <div class="cst-lite-editor color-border bg-cs-light-gray rounded-lg w-full">
    <span
      v-tooltip="'Remove'"
      class="float-right text-sm cursor-pointer rounded-lg bg-cs-secondary text-black-100 border-0 px-2 py-1"
      @click.prevent="removeThreadedTweet(tweetIndex)"
    >
      <i class="fas fa-close text-black-100" data-cy="close-tweet"></i>
    </span>
    <CstTextArea
      :key="getEditorKey()"
      ref="editorElement"
      placeholder="Add your content here ..."
      :initial-value="sharingDetails.message"
      :max-limit="maxLimit"
      :twitter-text-enable="twitterTextEnable"
      :type="type"
      :get-suggestions="getSuggestions"
      :get-suggestions-loader="
        () => {
          return `<div class='mention-loader mx-auto my-3'></div>`
        }
      "
      @change="liteEditorDebounceTextUpdate"
    />

    <div
      class="cst-lite-editor__toolbar rounded-b-2xl text-base flex justify-between bg-white px-2 py-2 select-none"
    >
      <div
        v-tooltip="'Add Media'"
        class="cst-editor__toolbar-btn cursor-pointer mx-1 px-2.5 py-2 flex items-center justify-center bg-cs-ultra-violet rounded-md hover-trigger relative"
      >
        <img src="@assets/img/composer/media-upload.svg" alt="" class="w-6" />
        <span class="ml-2 text-sm font-normal select-none text-black-200"
          >Upload</span
        >
        <div
          class="hover-target group absolute top-full left-0 bg-white rounded p-1 shadow text-sm w-44 z-50"
        >
          <div
            class="p-2 hover:bg-cs-secondary flex items-center"
            @click.prevent="triggerComposerImageUpload"
          >
            <div class="w-5 h-5 mr-2">
              <img
                src="@assets/img/composer/upload-image.svg"
                alt="upload image"
              />
            </div>
            <span class="pt-0.5"> Upload Image </span>
          </div>
          <div
            class="p-2 hover:bg-cs-secondary flex items-center"
            @click.prevent="triggerComposerVideoUpload"
          >
            <div class="w-5 h-5 mr-2">
              <img
                src="@assets/img/composer/upload-video.svg"
                alt="upload video"
              />
            </div>
            <span class="pt-0.5"> Upload Video </span>
          </div>
          <div
            class="p-2 hover:bg-cs-secondary flex items-center"
            @click.prevent="openMediaLibrary"
          >
            <div class="w-5 h-5 mr-2">
              <img
                src="@assets/img/composer/open-media-library.svg"
                alt="upload image"
              />
            </div>
            <span class="pt-0.5"> Open Media Library </span>
          </div>

          <form method="post" enctype="multipart/form-data" class="d-none">
            <input
              :id="getImageUploadInputId()"
              type="file"
              name="socialFile"
              accept="image/png,image/gif,image/jpeg,.gif,.jpeg,.jpg,.png,.heic"
              :multiple="
                [
                  'common',
                  'facebook',
                  'twitter',
                  'threadedTweets',
                  'linkedin',
                  'instagram',
                  'threads',
                  'bluesky'
                ].indexOf(type) >= 0
              "
              data-index=""
              @change="composerUploadImage($event)"
            />
          </form>

          <form method="post" enctype="multipart/form-data" class="d-none">
            <input
              :id="getVideoUploadInputId()"
              type="file"
              name="socialFileVideo"
              accept=".avi,.mov,.m4v,.mp4"
              data-index=""
              @change="composerUploadVideo($event)"
            />
          </form>
        </div>
      </div>

      <div class="flex items-center">
        <div v-if="hashtags" class="d-flex align-items-center">
          <p class="mr-2 chart_limit font-0-87rem">
            Hashtag Count:
            <span>{{ hashtags.length }}</span>
          </p>
        </div>
        <!-- Hashtag dropdown -->
        <div class="hashtag-dropdown relative mr-2">
          <div
            aria-expanded="true"
            aria-haspopup="true"
            @click="toggleDropdown('hashtag')"
          >
            <div
              v-tooltip.top="'Add Hashtags'"
              class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md"
            >
              <img
                src="@assets/img/composer/hashtag-icon.svg"
                alt=" "
                class="w-4 h-4"
              />
            </div>
          </div>
          <HashtagDropdown
            v-if="hashtagToggle"
            :is-open="hashtagToggle"
            :type="type"
            :apply-hashtag="applyHashtag"
            :tweet-index="tweetIndex"
            @handle-hashtags="handleHashtagsChanges"
            @handle-click="handleClickAwayEvents"
          >
          </HashtagDropdown>
        </div>
        <!-- Formatting option -->
        <div
          v-tooltip="'Format Text'"
          class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md hover-trigger relative mr-2"
        >
          <i class="fas fa-text w-4 h-4 text-cs-smoke"></i>

          <div
            class="hover-target group absolute w-40 top-full left-0 bg-white rounded p-0.5 shadow text-sm z-50"
          >
            <div
              unselectable="on"
              class="p-2 hover:bg-gray-100"
              @click="handleUnicodeVariant('bs')"
              ><b>Bold</b>
              Selected Text
            </div>
            <div
              unselectable="on"
              class="p-2 hover:bg-gray-100"
              @click="handleUnicodeVariant('is')"
              ><i>Italic</i>
              Selected Text
            </div>
          </div>
        </div>
        <!-- Emoji dropdown -->
        <div v-if="toolbar.emoji" class="emoji-dropdown relative mr-2">
          <div
            aria-expanded="true"
            aria-haspopup="true"
            @click="toggleDropdown('emoji')"
          >
            <div
              v-tooltip.top="'Add an emoji'"
              class="cst-editor__toolbar-btn cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md"
            >
              <img
                src="@assets/img/composer/emoji-icon.svg"
                alt=" "
                class="w-4 h-4"
              />
            </div>
          </div>
          <CstEmojiPicker
            v-if="emojiToggle"
            position="top-end"
            :is-open="emojiToggle"
            @on-select="onEmojiSelect"
            @handle-click="handleClickAwayEvents"
          >
          </CstEmojiPicker>
        </div>

        <template v-if="maxLimit">
          <div
            v-tooltip.bottom-end="
                  maxLimit >= characterCount
                    ? `Remaining Characters: ${maxLimit - characterCount}`
                    : `Extra Characters: ${characterCount - maxLimit}`"
            class="inline-flex gap-[10px] py-[3px] px-[10px] justify-center items-center rounded-full cursor-pointer min-w-[57px]"
            :class="characterCount > maxLimit ? 'bg-red-400 bg-opacity-10' : 'bg-[#3A74D3] bg-opacity-10'"
          >
            <span
              class="text-center text-sm leading-5"
              :class="characterCount > maxLimit ? 'text-red-400 ' : 'text-[#3A74D3]'"
            >
              {{ maxLimit - characterCount }}
            </span>
          </div>
        </template>
      </div>
    </div>

    <!-- Link Preview Box -->
    <transition name="slidedown">
      <template>
        <div
            v-if="(linkPreviewLoader || linkShortenerLoader) && !isMediaEnabled"
            class="p-3"
        >
          <div class="flex w-full">
            <SkeletonBox width="12rem" height="9rem" radius="12px" />

            <div class="p-3 w-full">
              <div class="mb-2">
                <SkeletonBox height="1.2rem" radius=".2rem" />
              </div>
              <div class="mb-1">
                <SkeletonBox radius=".2rem" />
              </div>
              <div class="mb-1">
                <SkeletonBox radius=".2rem" />
              </div>
              <SkeletonBox radius=".2rem" />
            </div>
          </div>
        </div>
        <div v-else-if="isLinkPreviewEnabled">
          <EditorLinkPreview
              :title="sharingDetails.title ? sharingDetails.title : ''"
              :url="sharingDetails.website ? sharingDetails.website : ''"
              :description="
              sharingDetails.description ? sharingDetails.description : ''
            "
              :image="sharingDetails?.multimedia?.length
                ? sharingDetails?.multimedia[0] :
                  sharingDetails?.image[0]
                ? sharingDetails?.image[0] : ''
            "
              @on-remove="() => removeLinkPreview(true, true)"
          />
        </div>
      </template>
    </transition>
    <!-- Media Type should be either image | video -->
    <transition name="slidedown">
      <div v-if="!isLinkPreviewEnabled && isMediaEnabled || mediaUploadLoader">
        <EditorMediaBox
          :type="getMediaType"
          :account-type="accountType"
          :media="getMedia"
          :accounts-data="accountsData"
          :media-loader="mediaUploadLoader"
          :progress-bar="progressBar"
          :video-errors="videoErrors"
          :has-custom-thumbnail="false"
          :sharing-details="sharingDetails"
          :update-image-loader="updateImageLoader"
          @on-sort="sortMedia"
          @on-edit="onEdit"
          @set-alt="setAlt"
          @remove="removeMediaAsset"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import CstTextArea from '@ui/TextArea/CstTextArea'
import EditorMediaBox from '@src/modules/composer_v2/components/EditorBox/EditorMediaBox'
import debounce from 'lodash/debounce'
import ComposerHelper from '@src/modules/composer_v2/mixins/ComposerHelper'
import { EventBus } from '@common/lib/event-bus'
import { mapGetters } from 'vuex'
import HashtagDropdown from '@src/modules/composer_v2/components/EditorBox/EditorOptions/HashtagDropdown'
import {toRaw} from "vue";
import {MULTIMEDIA_ALLOWED_PLATFORMS} from "@common/constants/composer";
import EditorLinkPreview from "@modules/composer_v2/components/EditorBox/EditorLinkPreview.vue";
import SkeletonBox from "@modules/analytics/views/common/SkeletonBox.vue";

export default {
  name: 'LiteEditorBox',
  components: {
    SkeletonBox, EditorLinkPreview,
    CstTextArea,
    HashtagDropdown,
    CstEmojiPicker: () => import('@ui/EmojiPicker/CstEmojiPicker'),
    EditorMediaBox,
  },
  mixins: [ComposerHelper],
  props: {
    tweetIndex: {
      type: Number,
      default: 0,
    },
    videoErrors: {
      type: Array,
      default: () => [],
    },
    accountType: {
      type: String,
      default: 'twitter'
    },
    widgets: {
      type: Object,
      default: () => {
        return {
          isThreadedTweetsAdded: false,
          isFirstCommentAdded: false,
          isLinkPreviewAdded: false,
          isMediaAdded: false,
        }
      },
    },
    toolbar: {
      type: Object,
      default: () => {
        return {
          threadedTweets: false,
          firstComment: false,
          media: true,
          replug: true,
          location: false,
          formatting: true,
          emoji: true,
          more: true,
        }
      },
    },
    maxLimit: {
      type: Number,
      default: 280,
    },
    twitterTextEnable: {
      type: Boolean,
      default: false,
    },
    sharingDetails: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'threaded-tweet',
      required: true,
    },
    applyHashtag: {
      type: Function,
      default: () => {},
    },
    accountsData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      liteEditorDebounceTextUpdate: () => null,
      templateText: '',
      characterCount: 0,
      hashtags: [],
      emojiToggle: false,
      hashtagToggle: false,
      mediaType: 'image',
      progressBar: {
        image: 0,
        numberOfImages: 0,
        video: 0,
      },
      mediaUploadLoader: false,
      updateImageLoader: { status: false, position: -1 },
      linkPreviewLoader: false,
      linkShortenerLoader: false,
      savedLinks: []
    }
  },
  computed: {
    ...mapGetters(['getPublishLoaders']),
    isLinkPreviewEnabled() {
      if (this.sharingDetails.url && this.sharingDetails.url !== '') {
        if (this.sharingDetails.image && this.sharingDetails.image.length)
          return true
        if (this.sharingDetails.title) return true
        if (this.sharingDetails.website) return true
        return !!this.sharingDetails.description
      }
      return false
    },
    getMedia() {

      if(this.sharingDetails?.multimedia?.length) {
        return this.sharingDetails.multimedia
      }

      if (this.sharingDetails.video && this.sharingDetails.video.link)
        return [this.sharingDetails.video]
      else return [].concat(this.sharingDetails.image)
    },

    getMediaType() {
      console.debug('getMediaType', this.progressBar)

      if(this.sharingDetails?.multimedia?.length) {
        return 'multimedia'
      }

      if (
        (this.sharingDetails.video && this.sharingDetails.video.link) ||
        (this.mediaUploadLoader && this.progressBar.video)
      ) {
        return 'video'
      }
      return 'image'
    },
    computeAddMediaType() {
      return this.type === 'multi-threads' ? 'multimedia' : this.getMediaType
    },
    percentage() {
      return Math.round((100 / this.maxLimit) * this.characterCount)
    },

    isMediaEnabled() {
      return (
        (this.sharingDetails?.multimedia && this.sharingDetails.multimedia.length > 0) ||
        (this.sharingDetails.image && this.sharingDetails.image.length > 0) ||
        (this.sharingDetails.video && this.sharingDetails.video.link)
      )
    },
  },

  watch: {
    maxLimit: function (val) {
      const editor = toRaw(this.$refs.editorElement.editor)
      editor.setMaxCharacterLimit(val)
    },
    'sharingDetails.message': {
      handler(newVal, oldVal) {
        if (this.sharingDetails.message !== this.templateText) {
          console.debug(
            'Update Editor Content thread ',
            this.sharingDetails.message,
            this.templateText
          )
          if (
            this.$refs &&
            this.$refs.editorElement &&
            this.$refs.editorElement.editor
          ){
              const editor = toRaw(this.$refs.editorElement.editor)
              editor.setContent(
                  this.sharingDetails.message
              )
          }
        }
      },
    },
  },

  created() {
    const self = this
    this.liteEditorDebounceTextUpdate = debounce(function ({
      templateText,
      text,
      links,
      count,
      lastChar,
      hashtags,
    }) {
      console.debug('Lite Editor Box Text Change', self.tweetIndex)
      self.templateText = templateText
      self.characterCount = count
      self.hashtags = hashtags
      if (links.length > 0) self.processEditorLinks(links, templateText)
      self.$emit('onEditorBoxTextChange', self.type, templateText, count)
    },
    500)
  },

  mounted() {
    console.debug('LiteEditorBox mounted', this.type, this.sharingDetails)
    this.savedLinks = this.sharingDetails.saved_urls
        ? this.sharingDetails.saved_urls
        : []
  },

  beforeUnmount() {
    console.debug('LiteEditorBox beforeUnmount', this.type)
  },

  methods: {
    /**
     * Remove the link preview from the editor
     */
    removeLinkPreview: function (removeImg = false, trigger = false) {
      this.$emit('removeLinkPreview', this.type, removeImg, trigger)
    },
    getTemplateText() {
      return this.templateText
    },
    processEditorLinks(links, text) {
      console.debug('Method:processEditorLinks', links, this.type)
      if (!this.linkPreviewLoader && !this.linkShortenerLoader)
        this.CSProcessLinks(
            this.type === 'threaded-tweet' ? 'twitter' : (this.type === 'bluesky-tweet' ? 'bluesky' : this.type),
            links,
            this.getTemplateText,
            this.getSharingDetail,
            this.cancelledLink,
            this.handleCallback,
            this.savedLinks,
            this.isMediaEnabled
        )
    },
    saveUrl(payload) {
      const { original, short } = payload
      const value = this.savedLinks.find(
          (item) => item.short === short || item.original === original
      )
      if (value) return
      this.savedLinks.push(payload)
      this.$emit('save-editor-url', this.type, this.savedLinks)
    },
    async getSuggestions(type, text, done) {
      const resSuggestions = await this.fetchComposerMentionSuggestions(text, [
        'twitter',
        'bluesky'
      ])
      if (
        resSuggestions &&
        resSuggestions.twitter &&
        resSuggestions.twitter.length > 0
      ) {
        const twitterMentions = resSuggestions.twitter.map((item) => ({
          name: item.name,
          username: `@${item.username}`,
          id: `@${item.username}`,
          tab: 'twitter',
          verification_status: item.is_verified,
          fan_count: item.followers_count,
          picture: item?.picture,
          selectable_field: 'username',
        }))
        if (twitterMentions && twitterMentions.length > 0) {
          done(twitterMentions)
          return
        }
      }

      if (
          resSuggestions &&
          resSuggestions.bluesky &&
          resSuggestions.bluesky.length > 0
      ) {
        const blueskyMentions = resSuggestions.bluesky.map((item) => ({
          name: item?.display_name,
          username: `@${item.handle}`,
          id: `@${item.did}`,
          tab: 'bluesky',
          picture: item?.avatar,
          selectable_field: 'username',
        }))
        if (blueskyMentions && blueskyMentions.length > 0) {
          done(blueskyMentions)
          return
        }
      }
      done([])
    },
    handleHashtagsChanges(flag, data) {
      this.$emit('handle-lite-editor-hashtags', flag, data)
    },
    toggleDropdown(type) {
      switch (type) {
        case 'emoji':
          this.emojiToggle = !this.emojiToggle
          break
        case 'hashtag':
          this.hashtagToggle = !this.hashtagToggle
          break
      }
    },
    handleClickAwayEvents(toggle, type) {
      console.log('handleClickAwayEvents', toggle, type)
      if (toggle) {
        switch (type) {
          case 'emoji':
            this.emojiToggle = false
            break
          case 'hashtag':
            this.hashtagToggle = false
            break
        }
      }
    },
    getEditorKey() {
      return this.type === 'threaded-tweet'
        ? `tweet_editor_${this.tweetIndex}`
        : this.type === 'multi-threads' ? `multi_threads_editor_${this.tweetIndex}`
        : this.type === 'bluesky-tweet' ? `bluesky_tweet_editor_${this.tweetIndex}`
        : `editor_${this.type}`
    },
    getImageUploadInputId() {
      return this.type === 'threaded-tweet'
        ? `tweet_image-upload-input-${this.tweetIndex}`
        : this.type === 'multi-threads' ? `threads_image-upload-input-${this.tweetIndex}`
        : this.type === 'bluesky-tweet' ? `bluesky_tweet_image-upload-input-${this.tweetIndex}`
        : `image-upload-input-${this.type}`
    },
    getVideoUploadInputId() {
      return this.type === 'threaded-tweet'
        ? `tweet_video-upload-input-${this.tweetIndex}`
        :  this.type === 'multi-threads' ? `threads_video-upload-input-${this.tweetIndex}`
        :  this.type === 'bluesky-tweet' ? `bluesky_tweet_video-upload-input-${this.tweetIndex}`
        : `video-upload-input-${this.type}`
    },

    getSharingDetail() {
      return this.sharingDetails
    },

    onChangeThreadDetails(threadDetails, index) {
      this.$emit('onChangeThreadDetails', threadDetails, index)
    },

    addThreadedTweet() {
      this.$emit('addThreadedTweet')
    },

    removeThreadedTweet(index) {
      this.$emit('removeThreadedTweet', index)
    },

    sortMedia(media, type) {
      if (type === 'image') {
        const details = { ...this.sharingDetails }
        details.image = [...media]
        this.$emit('handle-change', details, this.type)
      }
    },

    triggerComposerImageUpload() {
      document.getElementById(this.getImageUploadInputId()).click()
    },

    triggerComposerVideoUpload() {
      document.getElementById(this.getVideoUploadInputId()).click()
    },

    openMediaLibrary() {
      EventBus.$emit('show-media-library-modal', {
        source: this.type,
      details: {
        image: this.sharingDetails.image,
            video: this.sharingDetails.video,
            threadedTweetIndex: this.tweetIndex,
      },
        sideTabIndex: 1,
      })
    },

    handleUnicodeVariant(variant) {
      const editor = toRaw(this.$refs.editorElement.editor)
      editor.replaceSelected((selectedText) => {
        return this.toUnicodeVariant(selectedText, variant)
      })

      editor.view.focus()
    },

    onEmojiSelect: function (emojiUnicode) {
      const editor = toRaw(this.$refs.editorElement.editor)
      editor.insertText(emojiUnicode)
      editor.view.focus()
    },

    /**
     * This method will be called when user clicks on the cross button on media asset
     */
    removeMediaAsset: function (asset, mediaType) {
      this.$emit('removeMedia', this.type, mediaType, asset)
    },

    composerUploadImage(event) {
      this.CSUploadImage(
        event,
        this.type,
        this.getSharingDetail,
        this.cancelledLink,
        this.handleCallback
      )
    },

    /**
     * @description: This method is used upload video
     * @param event
     */
    composerUploadVideo(event) {
      this.CSUploadVideo(
        event,
        this.type,
        this.getSharingDetail,
        this.handleCallback,
      )
    },

    /**
     * @description: This method is used handle callback from mixin
     * @param {string} actionType - action type
     * @param {object} payload - action payload
     */
    handleCallback(actionType, payload) {
      console.log('handleCallback', actionType, payload)
      switch (actionType) {
        case 'details':
          this.sharingDetails[payload.propertyName] = payload.value
          break
        case 'local-property':
          if (payload.objectName)
            this[payload.objectName][payload.propertyName] = payload.value
          else this[payload.propertyName] = payload.value
          break
        case 'setLinkPreview':
          this.$emit('setLinkPreview', this.type, payload.value)
          break
        case 'handle-change':
          this.$emit('handle-change', this.sharingDetails, this.type)
          break
        case 'removeLinkPreview':
          this.removeLinkPreview()
          break
        case 'removeMedia':
          this.$emit(
            'removeMedia',
            this.type,
            payload.mediaType,
            payload.media,
            payload.removeAll
          )
          break
        case 'addMedia':
          this.$emit('addMedia', this.type, payload.media, this.computeAddMediaType)
          break
        case 'initializeVideoUpload':
          this.$emit('initializeVideoUpload', this.type)
          break
        case 'handle-url':
          this.saveUrl(payload)
          break
      }
    },

    setAlt(image, altText, index) {
      console.log('METHOD::setAltText ~ image, index -> ')
      if (!('alt_texts' in this.sharingDetails)) {
        this.sharingDetails.alt_texts = []
      }

      this.sharingDetails.alt_texts.push({ image, alt_text: altText })
    },

    onEdit(asset, index) {
      console.log('METHOD::onEdit ~ asset -> ', asset, index)
      // New Version Implementation.
      EventBus.$emit('enhanceImagePintura', {
        stateObject: this,
        type: this.type,
        image: asset,
        index,
        threadedTweetIndex: this.tweetIndex,
        modalVisible: true,
      })
    },
  },
}
</script>

<style lang="scss">
.cst-lite-editor {
  transition: all 0.2s ease;

  .cst-textarea {
    background-color: #fbfcfc;
    border: 0;
    box-shadow: none;
    border-radius: 0;
    font-size: 0.875rem;
  }

  &__toolbar,
  &__media {
    border-top: 1px solid #eef1f4;
  }
}
</style>
