import {
  socialChannelsArray,
  socialChannelsNameArray
} from '@common/constants/common-attributes'
import {
  social,
  getDefaultVideoSharingDetails,
  getDefaultSharingProgressBar
} from '../mutation-types'

const getDefaultGmbOptions = {
  topic_type: 'STANDARD',
  start_date: '',
  end_date: '',
  title: '',
  coupon_code: '',
  redeem_online_url: '',
  terms_conditions: '',
  action_type: ''
}

const getDefaultYoutubeOptions = {
  title: '',
  privacy_status: 'public',
  category: '',
  playlist: '',
  tags: [],
  license: 'youtube',
  embeddable: true,
  notify_subscribers: true,
  made_for_kids: false
}

const getDefaultCarouselPostCard = {
  name: '',
  description: '',
  link: '',
  picture: ''
}

export const getDefaultThreadedTweetsDetails = {
  upload_thread_tweets_sharing_video: false,
  uploadThreadedTweetsSharingImage: { status: false, position: -1 },
  fetchThreadedTweetsShortLinks: false,
  progressBar: getDefaultSharingProgressBar(),
  message: '',
  cancelledLink: '',
  image: [],
  video: getDefaultVideoSharingDetails(),
  url: '',
  website: '',
  image_suggestions: [],
  location: {
    id: '',
    name: ''
  }
}
const getDefaultTwitterOptions = {
  has_threaded_tweets: false,
  threaded_tweets: [{
    ...getDefaultThreadedTweetsDetails
  }],
  threaded_tweets_accounts: []
}

const getDefaultPinterestOptions = {
  postDescription: ''
}

const getDefaultFacebookOptions = {
  posted_as: {},
}

const getDefaultCarouselOptions = {
    is_carousel_post: false,
    cards: [],
    accounts: [],
    call_to_action: 'NO_BUTTON',
    end_card: false,
    end_card_url: '',
}

export default {
  state: {
    selection: {
      facebook: [],
      twitter: [],
      pinterest: [],
      linkedin: [],
      tumblr: [],
      instagram: [],
      gmb: [],
      youtube: [],
      tiktok: [],
      threads: [],
      bluesky: []
    },
    previewAccount: {
      facebook: {},
      twitter: {},
      linkedin: {},
      pinterest: {},
      tumblr: {},
      instagram: {},
      gmb: {},
      youtube: {},
      tiktok: {},
      threads: {},
      bluesky: {}
    },
    gmb_options: ({...getDefaultGmbOptions}),
    youtube_options: ({...getDefaultYoutubeOptions}),
    twitter_options: ({...getDefaultTwitterOptions}),
    pinterest_options: ({...getDefaultPinterestOptions}),
    facebook_options: ({...getDefaultFacebookOptions}),
    carousel_options: ({...getDefaultCarouselOptions}),
  },
  actions: {

    setAccountSelection ({ commit }, status) {
      commit('setAccountSelection', status)
    },

    setTwitterAccountSelection ({ commit }, status) {
      commit('setTwitterAccountSelection', status)
    },

    setLinkedinAccountSelection ({ commit }, status) {
      commit('setLinkedinAccountSelection', status)
    },

    setPinterestAccountSelection ({ commit }, status) {
      commit('setPinterestAccountSelection', status)
    },

    setTumblrAccountSelection ({ commit }, status) {
      commit('setTumblrAccountSelection', status)
    },

    setInstagramAccountSelection ({ commit }, status) {
      commit('setInstagramAccountSelection', status)
    },

    setFacebookPreviewAccount ({ commit }, account) {
      commit('setFacebookPreviewAccount', account)
    },

    setTwitterPreviewAccount ({ commit }, account) {
      commit('setTwitterPreviewAccount', account)
    },

    setLinkedinPreviewAccount ({ commit }, account) {
      commit('setLinkedinPreviewAccount', account)
    },

    setPinterestPreviewAccount ({ commit }, account) {
      commit('setPinterestPreviewAccount', account)
    },

    setTumblrPreviewAccount ({ commit }, account) {
      commit('setTumblrPreviewAccount', account)
    },

    setInstagramPreviewAccount ({ commit }, account) {
      commit('setInstagramPreviewAccount', account)
    },

    setYoutubePreviewAccount ({ commit }, account) {
      commit('setYoutubePreviewAccount', account)
    },
    setTiktokPreviewAccount ({ commit }, account) {
      commit('setTiktokPreviewAccount', account)
    },

    setHasThreadedTweets ({ commit }, hasThreadedTweets) {
      commit('setHasThreadedTweets', hasThreadedTweets)
    },
    setThreadedTweetsMessage ({ commit }, threadedTweetsMessage) {
      commit('setThreadedTweetsMessage', threadedTweetsMessage)
    },

    setThreadedTweetsSharingLink ({ commit }, url) {
      commit('setThreadedTweetsSharingLink', url)
    },

    setThreadedTweetsSharingVideo ({ commit }, { video, index }) {
      commit('setThreadedTweetsSharingVideo', { video, index })
    },

    setThreadedTweetsSharingVideoThumbnail ({ commit }, { thumbnail, index }) {
      commit('setThreadedTweetsSharingVideoThumbnail', { thumbnail, index })
    },

    setThreadedTweetsSharingImage ({ commit }, { image, index }) {
      commit('setThreadedTweetsSharingImage', { image, index })
    },

    setThreadedTweetsSharingNumberOfImages ({ commit }, { images, index }) {
      commit('setThreadedTweetsSharingNumberOfImages', { images, index })
    },

    setThreadedTweetsImagePercentage ({ commit }, { percent, index }) {
      commit('setThreadedTweetsImagePercentage', { percent, index })
    },
    setUploadThreadedTweetsSharingImageLoader ({ commit }, { loader, index }) {
      commit('setUploadThreadedTweetsSharingImageLoader', { loader, index })
    },

    setUploadThreadedTweetsSharingVideoLoader ({ commit }, { loader, index }) {
      commit('setUploadThreadedTweetsSharingVideoLoader', { loader, index })
    },

    setFetchThreadedTweetsShortLinksLoaders ({ commit }, { status, index }) {
      commit('setFetchThreadedTweetsShortLinksLoaders', { status, index })
    },

    setFacebookOptions ({ commit }, facebookOptions) {
      commit('setFacebookOptions', facebookOptions)
    },
    resetFacebookPostedAs ({ commit }) {
      commit('resetFacebookPostedAs')
    },
    setFacebookPostedAs ({ commit }, { posted_id, account_id }) {
      commit('setFacebookPostedAs', { posted_id, account_id })
    },
    setIsCarouselPost ({ commit }, isCarouselPost) {
      commit('setIsCarouselPost', isCarouselPost)
    },
    setCarouselOptionsAccounts ({ commit }, accounts) {
      commit('setCarouselOptionsAccounts', accounts)
    },
    setCarouselOptions ({ commit }, carouselPost) {
      commit('setCarouselOptions', carouselPost)
    },
    resetCarouselOptions ({ commit }) {
      commit('resetCarouselOptions')
    },

  },
  mutations: {
    setHasThreadedTweets (state, hasThreadedTweets) {
      state.twitter_options.has_threaded_tweets = hasThreadedTweets
    },

    setThreadedTweetsMessage (state, threadedTweetsMessage) {
      state.twitter_options.threaded_tweets.message = threadedTweetsMessage
    },

    setThreadedTweetsSharingLink (state, url) {
      state.twitter_options.threaded_tweets.url = url
    },

    setThreadedTweetsSharingVideo (state, { video, index }) {
      if (video) {
        state.twitter_options.threaded_tweets[index].video = video
      } else {
        state.twitter_options.threaded_tweets[index].video = getDefaultVideoSharingDetails()
      }
    },

    setThreadedTweetsSharingVideoThumbnail (state, { thumbnail, index }) {
      state.twitter_options.threaded_tweets[index].video.thumbnail = thumbnail
    },

    setThreadedTweetsSharingImage (state, { image, index }) {
      state.twitter_options.threaded_tweets[index].image = image
    },

    setThreadedTweetsSharingNumberOfImages (state, { images, index }) {
      state.twitter_options.threaded_tweets[index].progressBar.numberOfImages = images
    },

    setThreadedTweetsImagePercentage (state, { percent, index }) {
      state.twitter_options.threaded_tweets[index].progressBar.image = percent
    },

    setThreadedTweetsSharingVideoPercentage (state, { percent, index }) {
      state.twitter_options.threaded_tweets[index].progressBar.video = percent
    },

    setUploadThreadedTweetsSharingImageLoader (state, { loader, index }) {
      state.twitter_options.threaded_tweets[index].uploadThreadedTweetsSharingImage = loader
    },

    setUploadThreadedTweetsSharingVideoLoader (state, { loader, index }) {
      state.twitter_options.threaded_tweets[index].upload_thread_tweets_sharing_video = loader
    },

    setFetchThreadedTweetsShortLinksLoaders (state, { status, index }) {
      state.twitter_options.threaded_tweets[index].fetchThreadedTweetsShortLinks = status
    },

    [social.SET_GMB_ACCOUNT_SELECTION] (state, accounts) {
      state.selection.gmb = accounts
    },

    [social.SET_GMB_PREVIEW_ACCOUNT_SELECTION] (state, accounts) {
      state.previewAccount.gmb = accounts
    },

    [social.SET_GMB_OPTIONS_START_DATE] (state, date) {
      state.gmb_options.start_date = date
    },

    [social.SET_GMB_OPTIONS_END_DATE] (state, date) {
      state.gmb_options.end_date = date
    },

    [social.SET_GMB_OPTIONS] (state, options) {
      if (options == null) {
        state.gmb_options = JSON.parse(JSON.stringify(getDefaultGmbOptions))
      } else {
        state.gmb_options = options
      }
    },

    [social.SET_YOUTUBE_OPTIONS] (state, options) {
      if (options == null) {
        state.youtube_options = JSON.parse(JSON.stringify(getDefaultYoutubeOptions))
      } else {
        state.youtube_options = options
      }
    },
    [social.SET_PINTEREST_OPTIONS] (state, options) {
      if (options == null) {
        state.pinterest_options = JSON.parse(JSON.stringify(getDefaultPinterestOptions))
      } else {
        state.pinterest_options = options
      }
    },
    [social.SET_TWITTER_OPTIONS] (state, options) {
      if (options == null) {
        state.twitter_options = JSON.parse(JSON.stringify(getDefaultTwitterOptions))
      } else {
        state.twitter_options = options
      }
    },

    [social.SET_YOUTUBE_PLAYLISTS] (state, data) {
      state.youtube_options.playlist = data
    },

    [social.SET_YOUTUBE_TAGS] (state, tags) {
      state.youtube_options.tags = tags
    },

    [social.SET_YOUTUBE_ACCOUNT_SELECTION] (state, accounts) {
      state.selection.youtube = accounts
    },

    [social.SET_FACEBOOK_ACCOUNT_SELECTION] (state, value) {
      state.selection.facebook = value
    },
    [social.SET_TWITTER_ACCOUNT_SELECTION] (state, value) {
      state.selection.twitter = value
    },
    [social.SET_LINKEDIN_ACCOUNT_SELECTION] (state, value) {
      state.selection.linkedin = value
    },
    [social.SET_PINTEREST_ACCOUNT_SELECTION] (state, value) {
      state.selection.pinterest = value
    },
    [social.SET_INSTAGRAM_ACCOUNT_SELECTION] (state, value) {
      state.selection.instagram = value
    },
    [social.SET_CONTENT_CATEGORY_ACCOUNT_SELECTION] (state, value) {
      ['facebook', 'linkedin', 'gmb', 'instagram', 'pinterest', 'threads', 'bluesky'].forEach(item => {
        state.selection[item] = value && value[item] ? value[item] : []
      })
    },
    [social.SET_ACCOUNT_SELECTION] (state, selection) {
      // set the default arrays

      socialChannelsNameArray.forEach(item => {
        state.selection[item] = []
      })

      // set the select value
      if (selection) {
        socialChannelsArray.forEach(item => {
          console.log(item, selection[item.name], selection)
          if (selection[item.name]) state.selection[item.name] = selection[item.name].map(value => value[item.key])
        })
      }
    },

    [social.RESET_ACCOUNT_SELECTION] (state) {
      socialChannelsNameArray.forEach(item => {
        state.selection[item] = []
      })
    },

    [social.RESET_CAROUSEL_OPTIONS] (state) {
      state.carousel_options = JSON.parse(JSON.stringify(getDefaultCarouselOptions))
    },

    setAccountSelection (state, selection) {
      // if (!selection || !selection['queued']) {
      state.selection.facebook = []
      state.selection.twitter = []
      state.selection.pinterest = []
      state.selection.linkedin = []
      state.selection.tumblr = []
      state.selection.instagram = []
      state.selection.gmb = []
      state.selection.youtube = []
      state.selection.tiktok = []
      state.selection.threads = []
      // }

      if (selection) {
        if (selection.facebook) {
          selection.facebook.forEach(function (account) {
            state.selection.facebook.push(account.facebook_id)
          })
        }

        if (selection.threads) {
          selection.threads.forEach(function (account) {
            state.selection.threads.push(account.platform_identifier)
          })
        }

        if (selection.twitter) {
          selection.twitter.forEach(function (account) {
            state.selection.twitter.push(account.twitter_id)
          })
        }

        if (selection.linkedin) {
          selection.linkedin.forEach(function (account) {
            state.selection.linkedin.push(account.linkedin_id)
          })
        }

        if (selection.pinterest) {
          selection.pinterest.forEach(function (account) {
            state.selection.pinterest.push(account.board_id)
          })
        }

        if (selection.tumblr) {
          selection.tumblr.forEach(function (account) {
            state.selection.tumblr.push(account.platform_identifier ? account.platform_identifier : account.name)
          })
        }

        if (selection.instagram) {
          selection.instagram.forEach(function (account) {
            state.selection.instagram.push(account.instagram_id)
          })
        }

        if (selection.gmb) {
          selection.gmb.forEach(function (account) {
            state.selection.gmb.push(account.name)
          })
        }

        if (selection.youtube) {
          selection.youtube.forEach(function (account) {
            state.selection.youtube.push(account.platform_identifier)
          })
        }
        if (selection.tiktok) {
          selection.tiktok.forEach(function (account) {
            state.selection.tiktok.push(account.platform_identifier)
          })
        }
      }
    },

    setTwitterAccountSelection (state, accounts) {
      state.selection.twitter = accounts
    },

    setFacebookPreviewAccount (state, account) {
      state.previewAccount.facebook = account
    },

    setTwitterPreviewAccount (state, account) {
      state.previewAccount.twitter = account
    },

    setLinkedinPreviewAccount (state, account) {
      state.previewAccount.linkedin = account
    },

    setPinterestPreviewAccount (state, account) {
      state.previewAccount.pinterest = account
    },

    setTumblrPreviewAccount (state, account) {
      state.previewAccount.tumblr = account
    },

    setInstagramPreviewAccount (state, account) {
      state.previewAccount.instagram = account
    },

    setYoutubePreviewAccount (state, account) {
      state.previewAccount.youtube = account
    },
    setTiktokPreviewAccount (state, account) {
      state.previewAccount.tiktok = account
    },

    setThreadedTweetsSharingVideoInitialization (state, index) {
      state.twitter_options.threaded_tweets[index].title = ''
      state.twitter_options.threaded_tweets[index].description = ''
      state.twitter_options.threaded_tweets[index].url = ''
      state.twitter_options.threaded_tweets[index].website = ''
      state.twitter_options.threaded_tweets[index].image = []
      state.twitter_options.threaded_tweets[index].image_suggestions = []
      state.twitter_options.threaded_tweets[index].video = getDefaultVideoSharingDetails()
      state.twitter_options.threaded_tweets[index].progressBar.video = 0
    },
    resetThreadedTweets (state) {
      state.twitter_options = JSON.parse(JSON.stringify(getDefaultTwitterOptions))
    },
    setPinterestDescription (state, desc) {
      state.pinterest_options.postDescription = desc
    },

    setFacebookOptions (state, fbOptions) {
      state.facebook_options = fbOptions
    },

    resetFacebookPostedAs (state) {
      state.facebook_options.posted_as = {}
    },
    setFacebookPostedAs (state, { posted_id, account_id }) {
      const postedAs = state.facebook_options.posted_as
      postedAs[account_id] = posted_id
      state.facebook_options.posted_as = {...postedAs}
    },
    setCarouselOptionsAccounts (state, accounts) {
      state.carousel_options.accounts = accounts
    },
    setCarouselOptions (state, carouselOptions) {
      state.carousel_options = JSON.parse(JSON.stringify(carouselOptions))
    },
    setIsCarouselPost (state, hasCarouselPost) {
      state.carousel_options.is_carousel_post = hasCarouselPost
    },
    resetCarouselOptions (state) {
      state.carousel_options = JSON.parse(JSON.stringify(getDefaultCarouselOptions))
    },

  },
  getters: {

    getAccountSelection: state => {
      return state.selection
    },

    getPreviewAccount: state => {
      return state.previewAccount
    },

    getGmbOptions: state => {
      return state.gmb_options
    },

    getYoutubeOptions: state => {
      return state.youtube_options
    },
    getTwitterOptions: state => {
      return state.twitter_options
    },
    getPinterestOptions: state => {
      return state.pinterest_options
    },
    getThreadedTweetsDetails: state => {
      return state.twitter_options.threaded_tweets
    },
    getThreadedTweetsSharingDetails: state => {
      return state.twitter_options.threaded_tweets
    },
    getThreadedTweetsCancelledLink: state => {
      return state.twitter_options.threaded_tweets.cancelledLink
    },
    getThreadedTweetsProgressBar: state => {
      return state.twitter_options.threaded_tweets.progressBar
    },
    getFacebookPostedAs: state => {
      return state.facebook_options.posted_as
    },
    getFacebookOptions: state => {
      return state.facebook_options
    },
    getCarouselOptions: state => {
      return state.carousel_options
    },
    getIsCarouselPost: state => {
      return state.carousel_options.is_carousel_post
    },
    getCarouselOptionsAccounts: state => {
      return state.carousel_options.accounts
    },

  }
}
