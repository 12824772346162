<template>
  <!--  Modal Wrapper-->
  <ModalWrapper
    modal_heading="Generate AI Images"
    :modal-close="
      () => {
        commonMethods.toggleHelpDropdown(false)
      }
    "
    :modal-shown="handleShown"
  >
    <!--    Modal Body-->
    <template v-slot>
      <div class="flex h-full">
        <!--      left side -->
        <AiImageGenerator
          ref="searchPromptRef"
          :prompt-details="promptDetails"
          :generate-images="generateImages"
          :prompt-errors="promptErrors"
          :limits="limits"
          :is-generating="isGenerating"
          :media-uploading="mediaUploading"
        />
        <!-- right side -->
        <div class="flex-1 h-full overflow-hidden bg-white">
          <GenerationHistory
            v-if="showHistory"
          />
          <ImagesListing
            v-else
            :ai-images="aiImages"
            :is-generating="isGenerating"
            :completed="completed"
            :upload-images-from-link="uploadImagesFromLink"
            :loading-completed="loadingCompleted"
            :media-uploading="mediaUploading"
          />
        </div>
      </div>
    </template>
  </ModalWrapper>
</template>

<script>
// libraries
import { defineComponent, onMounted, computed, inject, nextTick } from 'vue'
import { useStore } from '@state/base'
import proxy from '@common/lib/http-common'

// components
import ModalWrapper from '@common/components/modal-wrapper/ModalWrapper.vue'
import {
  ImagesListing,
  AiImageGenerator,
} from '@src/modules/composer_v2/components/EditorBox/ImageGenerator/index'

// constants
import { EventBus } from '@common/lib/event-bus'
import { aiImageGenerationApiUrl } from '@src/config/api-utils'
import { commonMethods } from '@common/store/common-methods'
import GenerationHistory from './GenerationHistory.vue'
import useImageGeneration from '@/src/modules/composer_v2/composables/useImageGeneration'

export default defineComponent({
  name: 'AiImageGeneratorModal',
  components: { ModalWrapper, ImagesListing, AiImageGenerator, GenerationHistory },
  props: {
    uploadImagesFromLink: {
      type: Function,
      default: () => {},
    },
  },
  setup: function () {
    const store = useStore()
    const root = inject('root')
    
    const { promptDetails, aiImages, showHistory, toggleHistory, completed, mediaUploading, oldComposer,
      isGenerating, limits, searchPromptRef,imagesListingRef } = useImageGeneration()

    // mounted
    onMounted(() => {
      EventBus.$on('imageGeneratorModal', ({ isOldComposer }) => {
        root.$bvModal.show('image_generator_modal')
        oldComposer.value = isOldComposer
        commonMethods.toggleHelpDropdown(true)
      })
      EventBus.$on('clear-modal-state', () => {
        clearState()
      })
    })

    // computed properties
    /**
     *  When value of prompt details changes it will validate input
     * @type {ComputedRef<{quantity, prompt: boolean}>}
     */
    const promptErrors = computed(() => ({
      prompt: promptDetails.value.prompt === '',
      quantity: !(
        promptDetails.value.quantity > 0 && promptDetails.value.quantity <= 5
      ),
    }))

    // methods
    /**
     * generate images on the basis of prompt
     * @returns {Promise<void>}
     */
    const generateImages = async () => {
      // if prompt is invalid then it will return
      if (promptErrors.value.quantity || promptErrors.value.prompt) {
        return
      }
      const payload = {
        workspace_id: store.getters.getActiveWorkspace._id,
        prompt: promptDetails.value.prompt,
        number_of_images: parseInt(promptDetails.value.quantity),
        aspect_ratio: promptDetails.value.aspectRatio,
        image_style: promptDetails.value.style,
      }
      aiImages.value = []
      isGenerating.value = true
      try {
        const response = await proxy.post(aiImageGenerationApiUrl, payload)
        isGenerating.value = false
        completed.value = false
        if (!response.data.status) {
          await store.dispatch('toastNotification', {
            message: response?.data?.message || 'Unable to generate image',
            type: 'error',
          })
          return
        }
        store.getters.getPlan.used_limits.image_generation_credit =
          response?.data?.limits?.used
        if (response?.data?.images?.length > 0) {
          aiImages.value = response.data?.images?.map((item) => ({
            ...item,
            select: false,
          }))
        }
      } catch (error) {
        completed.value = false
        isGenerating.value = false
        await root.$store.dispatch('toastNotification', {
          message: error?.response?.data?.message,
          type: 'error',
        })
      }
    }
    /**
     * reinitialize states
     */
    const clearState = () => {
      Object.assign(promptDetails.value, {
        prompt: '',
        resolution: '512x512',
        quantity: '3',
        aspectRatio: '16:9',
        style: 'none',
      })
      isGenerating.value = false
      aiImages.value = []
      completed.value = false
    }
    const loadingCompleted = () => {
      completed.value = true
    }
    const handleShown = () => {
      nextTick(() => {
        searchPromptRef.value?.promptRef?.focus()
      })
    }

    return {
      promptDetails,
      generateImages,
      promptErrors,
      limits,
      isGenerating,
      aiImages,
      loadingCompleted,
      completed,
      mediaUploading,
      clearState,
      oldComposer,
      handleShown,
      searchPromptRef,
      imagesListingRef,
      showHistory,
      toggleHistory,
    }
  },
  computed: {
    commonMethods() {
      return commonMethods
    },
  },
})
</script>
