<template>
  <LimitExceeds v-if="isLimitExceeded" modal_heading="AI Images" />
  <div
    v-else
    class="search-prompt relative w-full min-w-[30rem] max-w-[30rem] overflow-y-auto flex flex-col px-4 pt-2 pb-4"
  >
    <div class="max-h-[92%] h-full overflow-y-auto no-scrollbar mt-2 px-2">
      <div class="mt-2">
        <textarea
          ref="promptRef"
          v-model="promptDetails.prompt"
          :placeholder="placeHolderText"
          rows="4"
          class="resize-none rounded-xl w-full p-3 border-0 color-border cst-caption-editor"
          :maxlength="limits.promptLength"
        >
        </textarea>
        <span class="text-xs block w-100 text-right text-gray-700"
          >Maximum 1000 characters</span
        >
      </div>

      <!-- Number of Images -->
      <div class="flex items-center gap-x-8 mt-6 mb-8">
        <span class="font-normal text-sm text-black-400 block">Number of Images</span>
        <div class="relative inline-flex">
          <div class="relative flex w-full rounded-md bg-gray-100 p-1.5 gap-x-2">
            <div
              v-for="num in [1, 2, 3, 4, 5]"
              :key="num"
              :class="[
                'segment-item relative transition-all duration-200',
                'cursor-pointer flex items-center justify-center',
                'h-6 w-6 rounded-md',
                promptDetails.quantity === num.toString()
                  ? 'bg-white shadow-[0_2px_4px_rgba(0,0,0,0.1)] rounded-md border border-[#4a4a4a]'
                  : '',
              ]"
              @click="promptDetails.quantity = num.toString()"
            >
              <input
                v-model="promptDetails.quantity"
                type="radio"
                :value="num.toString()"
                name="variations"
                class="sr-only"
              />
              <span
                class="select-none font-medium text-gray-900 "
              >
                {{ num }}
              </span>
            </div>
          </div>
        </div>
      </div>


      <!-- Aspect Ratio -->
      <div class="mt-4">
        <div
          class="flex items-center justify-between bg-[#f7f7f7] hover:bg-[#f0f7ff] mb-4 rounded-md px-4 py-3 cursor-pointer"
          :class="{ '!bg-[#f0f7ff]': isAspectRatioOpen }"
          @click="isAspectRatioOpen = !isAspectRatioOpen"
        >
          <div class="flex items-center gap-x-2">
            <img src="@assets/img/common/aspect-ratio.svg" alt="" class="w-4 h-4" />
            <span class="font-medium text-gray-900 pt-0.5">Aspect Ratio</span>
          </div>
          <div class="flex items-center gap-x-1">
            <span class="text-gray-900 text-sm">{{ getSelectedRatioLabel }}</span>
            <svg
              class="w-4 h-4 text-gray-700 transform transition-transform duration-200"
              :class="{ 'rotate-180': isAspectRatioOpen }"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M6 9l6 6 6-6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
        <div
          class="overflow-hidden transition-all duration-200 ease-in-out"
          :style="{
            maxHeight: isAspectRatioOpen ? '500px' : '0',
            opacity: isAspectRatioOpen ? 1 : 0,
            marginBottom: isAspectRatioOpen ? '2rem' : '0',
          }"
        >
          <div class="grid grid-cols-4 gap-3 p-4 rounded-xl">
            <div
              v-for="ratio in aspectRatios"
              :key="ratio.value"
              class="flex flex-col items-center"
            >
              <div
                :class="[
                  'aspect-ratio-item cursor-pointer p-2 rounded-lg transition-all duration-200 mb-2',
                  'border w-full',
                  promptDetails.aspectRatio === ratio.value
                    ? '!border-[#0066FF]'
                    : '!border-gray-300 bg-white hover:border-gray-300',
                ]"
                @click="promptDetails.aspectRatio = ratio.value"
              >
                <div class="flex flex-col items-center">
                  <div class="w-full h-[40px] flex items-center justify-center">
                    <div
                      :class="[
                        'aspect-ratio-preview rounded-md border',
                        promptDetails.aspectRatio === ratio.value
                          ? '!border-[#0066FF]'
                          : '!border-gray-300',
                      ]"
                      :style="{
                        width: getPreviewDimensions(ratio.value).width + 'px',
                        height: getPreviewDimensions(ratio.value).height + 'px',
                      }"
                    ></div>
                  </div>
                  <div class="text-center mt-1">
                    <div
                      :class="[
                        'text-xs font-normal',
                        promptDetails.aspectRatio === ratio.value
                          ? 'text-[#0066FF]'
                          : 'text-gray-900',
                      ]"
                    >{{ ratio.value }}</div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <div class="text-[10px] leading-tight whitespace-pre-line text-gray-700" :class="{ 'text-gray-900': promptDetails.aspectRatio === ratio.value }">
                  {{ ratio.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Image Style  -->
      <div class="mt-4">
        <div
           class="flex items-center justify-between bg-[#f7f7f7] hover:bg-[#f0f7ff] mb-4 rounded-md px-4 py-3 cursor-pointer"
          :class="{ '!bg-[#f0f7ff]': isStyleOpen }"
          @click="isStyleOpen = !isStyleOpen"
        >
          <div class="flex items-center gap-x-2">
            <img src="@assets/img/common/style.svg" class="w-4 h-4" alt="style" />
            <span class="font-medium text-gray-900 pt-0.5">Style</span>
          </div>
          <div class="flex items-center gap-x-1">
            <span class="text-gray-900 text-sm">{{ getSelectedStyleLabel }}</span>
            <svg
              class="w-4 h-4 text-gray-700 transform transition-transform duration-200"
              :class="{ 'rotate-180': isStyleOpen }"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path d="M6 9l6 6 6-6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>

        <div
          class="overflow-hidden transition-all duration-200 ease-in-out"
          :style="{
            maxHeight: isStyleOpen ? '800px' : '0',
            opacity: isStyleOpen ? 1 : 0,
            marginBottom: isStyleOpen ? '2rem' : '0',
          }"
        >
          <div class="grid grid-cols-4 gap-3 p-4 rounded-xl">
            <div
              v-for="style in imageStyles"
              :key="style.value"
              class="flex flex-col"
            >
              <!-- None  -->
              <div v-if="style.value === 'none'" >
                <div
                  :class="[
                    'cursor-pointer transition-all duration-200',
                    'w-full rounded-xl overflow-hidden relative  hover:!bg-gray-200',
                    promptDetails.style === style.value
                      ? 'ring-1 ring-[#2a6ffb]'
                      : 'border border-gray-200',
                  ]"
                  @click="promptDetails.style = style.value"
                >
                  <div class=" flex flex-col items-center justify-center w-full h-full py-3 gap-y-2.5">
                    <i class="fas fa-ban text-gray-900 text-3xl font-normal" :class="{ 'text-[#2a6ffb]': promptDetails.style === style.value }"></i>
                    <span class="text-center text-gray-900 text-xs font-medium" :class="{ 'text-[#2a6ffb]': promptDetails.style === style.value }">
                      {{ style.label }}
                    </span>
                  </div>
                </div>
              </div>
              <div
                v-else
                :class="[
                  'cursor-pointer transition-all duration-200',
                  'w-full rounded-xl overflow-hidden relative hover:!bg-gray-200',
                  promptDetails.style === style.value
                    ? 'ring-2 ring-offset-1 ring-[#2a6ffb]'
                    : '',
                ]"
                @click="promptDetails.style = style.value"
              >
                <div class=" w-full relative ">
                  <img
                    loading="lazy"
                    :src="style.image"
                    :alt="style.label"
                    class="w-full h-full object-fit"
                  />
                  <div class="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
                  <div class="absolute bottom-1  flex items-center justify-center px-1.5">
                    <span class="text-center text-white text-xs font-semibold text-wrap" >{{ style.label }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="absolute bottom-1 left-0 px-4 py-2 w-full">
      <CstButton
        class="w-full"
        size="default"
        :disabled="
          promptErrors.quantity ||
          promptErrors.prompt ||
          !promptDetails.prompt.trim() ||
          !promptDetails.quantity ||
          !promptDetails.resolution ||
          !promptDetails.aspectRatio ||
          isGenerating ||
          mediaUploading
        "
        :loading="isGenerating"
        @click="handleGenerateImages"
      >
        <img src="@assets/img/common/magic.svg" alt="sparkle" class="h-5 w-5 mr-2">
        <span class="">Generate</span>
      </CstButton>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from '@state/base'
import CstButton from '@ui/Button/CstButton.vue'
import LimitExceeds from '@src/modules/composer_v2/components/EditorBox/ImageGenerator/LimitExceeds.vue'
import useImageGeneration from '@/src/modules/composer_v2/composables/useImageGeneration'

const props = defineProps({
  promptDetails: {
    type: Object,
    default: () => ({
      prompt: '',
      quantity: '3',
      aspectRatio: '16:9',
      style: 'none'
    }),
  },
  generateImages: {
    type: Function,
    default: () => {},
  },
  promptErrors: {
    type: Object,
    default: () => ({
      prompt: true,
      quantity: true,
    }),
  },
  limits: {
    type: Object,
    default: () => ({}),
  },
  isGenerating: {
    type: Boolean,
    default: false,
  },
  mediaUploading: {
    type: Boolean,
    default: false,
  },
})

const store = useStore()
const promptRef = ref(null)
const placeHolderText = 'Describe the image you need...'

/**
 * Controls the visibility of the aspect ratio section
 */
const isAspectRatioOpen = ref(true)
const isStyleOpen = ref(true)

const {
  aspectRatios,
  imageStyles,
  getPreviewDimensions,
  showHistory,
} = useImageGeneration()

/**
 * Computed property to check if user has exceeded image generation limits
 */
const isLimitExceeded = computed(() => {
  if (store.getters.getPlan.subscription.limits?.image_generation_credit) {
    return (
      store.getters.getPlan.used_limits.image_generation_credit >=
      store.getters.getPlan.subscription.limits.image_generation_credit
    )
  }
  return true
})

const getSelectedRatioLabel = computed(() => {
  const selectedRatio = aspectRatios.find((ratio) => ratio.value === props.promptDetails.aspectRatio)
  return selectedRatio ? selectedRatio.label : ''
})

const getSelectedStyleLabel = computed(() => {
  const selectedStyle = imageStyles.find((style) => style.value === props.promptDetails.style)
  return selectedStyle ? selectedStyle.label : ''
})

const handleGenerateImages = () => {
  props.generateImages()
  showHistory.value = false
}
</script>

<style lang="scss" scoped>
.search-prompt {
  .aspect-ratio-preview {
    background-color: #fff;
    display: inline-block;
  }

  .aspect-ratio-item {
    &:hover {
      background-color: #fff;
    }
  }
  .variation_input {
    position: relative;

    input[type='radio'] {
      -webkit-appearance: none;
      appearance: none;
      position: absolute;
      opacity: 0;
    }

    .check_btn {
      position: absolute;
      top: 0;
      left: 0;
      padding: 6px 20px;
      border-radius: 6px;
      font-weight: 400;
      font-size: 0.9rem;
      width: max-content;
      border: 1px solid #eee;
    }

    &:hover .check_btn {
      background-color: #f2f4f6;
      cursor: pointer;
    }

    input[type='radio']:checked ~ .check_btn {
      border: 1px solid #2196f3;
      background-color: #2196f30a;
      color: #2196f3;
    }
  }
  .cst-caption-editor {
    &:hover,
    &:focus {
      border-color: #409aff;
      box-shadow: 0 0 0 1px rgba(64, 154, 255, 0.8);
    }

    textarea::placeholder {
      color: #a4a8ac;
      font-size: 0.875rem;
    }
  }
}

.segment-item {
  &:hover {
    border: 1px solid #4a4a4a;
  }
}


</style>
