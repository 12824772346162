import {
  plannerBaseUrl,
  storageBaseUrl,
  settingsBaseUrl,
} from '@src/config/api-utils'

export const twitterPostLimit = 280
export const twitterPremiumPostLimit = 25000
export const pinterestPostLimit = 500
export const threadsPostLimit = 500

export const blueskyPostLimit = 300

export const linkedinPostLimit = 3000
export const gmbPostLimit = 1500
export const instagramPostLimit = 2200
export const facebookPostLimit = 5000
export const youtubePostLimit = 5000
export const tiktokPostLimit = 2200
export const canvaAPIKey = process.env.VUE_APP_CANVA_API_KEY
export const fetchBlogOptionsURL = plannerBaseUrl + 'fetchBlogOptions'
export const fetchBlogSiteDomainsURL = plannerBaseUrl + 'fetchBlogSiteDomains'
export const fetchBlogFullCollectionURL =
  plannerBaseUrl + 'fetchBlogFullCollection'
export const fetchShortLinksUrl = plannerBaseUrl + 'fetchShortLinks'
export const fetchLinkPreviewUrl = plannerBaseUrl + 'fetchLinkPreview'
export const fetchLinkCaptionUrl = plannerBaseUrl + 'fetchLinkCaption'

export const fetchAiCaption = plannerBaseUrl + 'fetchAiCaption'
export const irrelevantCaptionUrl = plannerBaseUrl + 'irrelevantCaption'
export const seoPredictionUrl = plannerBaseUrl + 'seoPredictions'

export const uploadSharingImageUrl = plannerBaseUrl + 'uploadSharingImage'
export const uploadSharingVideoUrl = plannerBaseUrl + 'uploadSharingVideo'
export const uploadImageURL = storageBaseUrl + 'uploadImage'
export const removeGCSMediaURL = storageBaseUrl + 'removeGCSMedia'
export const fetchStorageLinkUrl = storageBaseUrl + 'fetchStorageLink'
export const fetchMediaBlob = storageBaseUrl + 'mediaBlob'


export const fetchGCSLinkURL = storageBaseUrl + 'fetchGCSLink'
export const processBlogPostUrl = plannerBaseUrl + 'processBlogPost'
export const processSocialShareUrl = plannerBaseUrl + 'processSocialShare'
export const processPlannerBulkEditUrl =
  plannerBaseUrl + 'processPlannerBulkEdit'
export const processSocialContentCategoryPostURL =
  plannerBaseUrl + 'content_category/post/store'
export const processRetweetShareUrl = plannerBaseUrl + 'processRetweetShare'
export const fetchPlanUrl = plannerBaseUrl + 'fetchPlan'
export const fetchReplugLinksUrl = plannerBaseUrl + 'fetchReplugLinks'
export const fetchLongLinkUrl = plannerBaseUrl + 'fetchLongLink'
export const fetchSharingMentionSuggestionsUrl =
  plannerBaseUrl + 'fetchSharingMentionSuggestions'

export const fetchSocialMentionSuggestionsUrl =
  plannerBaseUrl + 'fetchSocialMentionSuggestions'
export const fetchSharingHashTagsSuggestionsUrl =
  plannerBaseUrl + 'fetchSharingHashTagsSuggestions'
export const fetchHashTagsVelocityUrl = plannerBaseUrl + 'fetchHashTagsVelocity'
export const fetchPostHashTagsUrl = plannerBaseUrl + 'fetchPostHashTags'
export const fetchAIHashTags = plannerBaseUrl + 'fetchAiHashtags'

export const processContentRewritingUrl =
  plannerBaseUrl + 'processContentRewriting'
export const fetchUploadedFilesUrl = plannerBaseUrl + 'fetchUploadedFiles'
export const uploadWidgetFilesUrl = plannerBaseUrl + 'uploadWidgetFiles'
export const plannerDefaultView =
  settingsBaseUrl + 'preferences/setPlannerDefaultView'
export const plannerDefaultSort =
  settingsBaseUrl + 'preferences/setPlannerDefaultSort'
export const plannerDefaultCalendarView =
  settingsBaseUrl + 'preferences/setPlannerDefaultCalendarView'
export const resizeImgForInstagramUrl = plannerBaseUrl + 'resizeImgForInstagram'

export const allowedImageMimeTypes = [
  'image/gif',
  'image/jpg',
  'image/jpeg',
  'image/png',
]
export const allowedVideoMimeTypes = [
  'video/mp4',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-m4v',
]
export const processHideFromclient = plannerBaseUrl + 'hideClientAction'
export const fetchGlobalCategoryChannelsUrl =
  settingsBaseUrl + 'global/categories/fetchGlobalCategoryChannels'
export const searchLocationsURL = plannerBaseUrl + 'searchLocations'
export const getMediaFromLinkUrl = plannerBaseUrl + 'getMediaFromLink'

export const createMediaFolderUrl =
  storageBaseUrl + 'media_library/folder/create'
export const fetchMediaFolderUrl =
  storageBaseUrl + 'media_library/folder/fetch/all'
export const fetchMediaStatsUrl =
  storageBaseUrl + 'media_library/folder/fetch/stats'
export const UpdateMediaFolderUrl =
  storageBaseUrl + 'media_library/folder/update'
export const deleteMediaFolderUrl =
  storageBaseUrl + 'media_library/folder/delete'

export const uploadMediaAssetUrl =
  storageBaseUrl + 'media_library/assets/upload'

export const uploadMediaV2Url = storageBaseUrl + 'media_library/v2/upload'
export const uploadDocumentAssetUrl =
  storageBaseUrl + 'media_library/assets/uploadDocument'
export const uploadMediaAssetByLinkUrl =
  storageBaseUrl + 'media_library/assets/uploadByLink'
export const fetchMediaAssetsUrl = storageBaseUrl + 'media_library/assets/fetch'
export const deleteMediaAssetsUrl =
  storageBaseUrl + 'media_library/assets/remove'
export const archiveMediaAssetsUrl =
  storageBaseUrl + 'media_library/assets/archive'
export const moveMediaAssetsUrl = storageBaseUrl + 'media_library/assets/move'
export const moveMediaLimitsUrl = storageBaseUrl + 'media_library/assets/limits'

export const createMediaCSV =
  storageBaseUrl + 'media_library/export/export-media-csv'

export const discoveryDefaultView =
  settingsBaseUrl + 'preferences/setDiscoveryDefaultView'

export const saveLocationURL = plannerBaseUrl + 'account/defaultLocation'
