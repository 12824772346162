import { UNKNOWN_ERROR } from '@common/constants/messages'
import { parseFilesMimeTypes } from '@common/lib/helper'
import { uploadMediaAssetUrl } from '@src/modules/publish/config/api-utils'

import {
  gmb,
  bluesky,
  instagramTypes,
  linkedinSharingTypes,
  sharingTypes,
  social,
  youtubeTypes,
  tiktokTypes,
} from '@src/modules/publish/store/states/mutation-types'
import { appContainer } from '@src/main'

const getDefaultSharingDetails = () => {
  return {
    post_id: '',
    tagsSuggestions: {
      replaceKeyword: true,
      items: [],
    },
    mentionsSuggestions: {
      type: 'Facebook',
      keyword: '',
      facebook: [],
      twitter: [],
      dropdown: {
        status: false,
        left: 0,
        top: 0,
      },
    },
    hashTagsSuggestions: {
      keyword: '',
      data: [],
      box: 'Common',
      dropdown: {
        status: false,
        left: 0,
        top: 0,
      },
    },
    activeTab: '',
    drag_over_status: {
      common: false,
      facebook: false,
      twitter: false,
      linkedin: false,
      pinterest: false,
      tumblr: false,
      instagram: false,
      youtube: false,
      gmb: false,
      tiktok: false,
    },
    sharing_media_details: {},
    first_comment: {
      has_first_comment: false,
      first_comment_message: '',
      first_comment_location: null,
      first_comment_accounts: [],
    },
  }
}

export default {
  state: {
    selection: getDefaultSharingDetails(),
    video: {
      warning: false,
    },
  },
  actions: {
    resetSharingDetails({ commit }) {
      commit('resetSharingDetails')
    },

    setSocialSharingPostId({ commit }, postId) {
      commit('setSocialSharingPostId', postId)
    },

    setSharingTagsReplaceStatus({ commit }, status) {
      commit('setSharingTagsReplaceStatus', status)
    },

    setSocialSharingTagsSuggestionsItems({ commit }, suggestions) {
      commit('setSocialSharingTagsSuggestionsItems', suggestions)
    },

    setSharingActiveTab({ commit }, activeTab) {
      commit('setSharingActiveTab', activeTab)
    },

    setSharingMentionsType({ commit }, type) {
      commit('setSharingMentionsType', type)
    },

    setSharingMentionsKeyword({ commit }, keyword) {
      commit('setSharingMentionsKeyword', keyword)
    },

    /**
     * This method will set the first comment toggle state
     * @param commit
     * @param hasFirstComment
     */
    setHasFirstComment({ commit }, hasFirstComment) {
      commit('setHasFirstComment', hasFirstComment)
    },

    /**
     * This method will set the first comment message
     * @param commit
     * @param hasFirstComment
     */
    setFirstCommentMessage({ commit }, firstCommentMessage) {
      commit('setFirstCommentMessage', firstCommentMessage)
    },

    /**
     * This method will set the first comment location
     * @param commit
     * @param firstCommentLocation
     */
    setFirstCommentLocation({ commit }, firstCommentLocation) {
      commit('setFirstCommentLocation', firstCommentLocation)
    },

    /**
     * This method will set the first comment Accounts
     * @param commit
     * @param setFirstCommentAccount
     */
    setFirstCommentAccount({ commit }, firstCommentAccount) {
      commit('setFirstCommentAccount', firstCommentAccount)
    },

    /**
     * This method will set the first comment object
     * @param commit
     * @param setFirstComment
     */
    setFirstComment({ commit }, setFirstComment) {
      commit('setFirstComment', setFirstComment)
    },

    /**
     * This method will check the warning & confirmation before the video upload and then call the uploading method.
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     **event(change event of file input)
     **type(type of box)
     */
    uploadSharingVideo({ commit, dispatch, getters }, payload) {
      console.debug('Action:uploadSharingVideo', payload)
      const threadedTweetIndex = payload.threadedTweetIndex
        ? payload.threadedTweetIndex
        : 0
      // get file data and add to the form data
      let file = []
      if (event.type === 'paste') {
        const itemImages = []
        const items = (event.clipboardData || event.originalEvent.clipboardData)
          .items
        for (let i = 0; i <= items.length - 1; i++) {
          itemImages.push(items[i].getAsFile())
        }
        file = itemImages
        console.debug('Method::parseFilesMimeTypes ~ Files -> ', file)
      } else {
        file = payload.event.target.files || payload.event.dataTransfer.files
      }
      if (file[0]) {
        const formData = new FormData()
        formData.append('media_assets[]', file[0])
        formData.append('folder_id', null)
        formData.append(
          'workspace_id',
          getters.getWorkspaces.activeWorkspace._id
        )
        // checking file warning, different file is already added. Like Image or Gif,
        // and we try to change the media like video upload

        let warningCheck = false
        switch (payload.type) {
          case 'Common':
            if (
              getters.getCommonSharingDetails.url === '' &&
              getters.getCommonSharingDetails.image.length > 0
            ) {
              warningCheck = true
            }
            break
          case 'Facebook':
            if (
              getters.getFacebookSharingDetails.url === '' &&
              getters.getFacebookSharingDetails.image.length > 0
            ) {
              warningCheck = true
            }
            break
          case 'Twitter':
            if (
              getters.getTwitterSharingDetails.url === '' &&
              getters.getTwitterSharingDetails.image.length > 0
            ) {
              warningCheck = true
            }
            break
          case 'ThreadedTweets':
            if (
              getters.getThreadedTweetsSharingDetails[threadedTweetIndex]
                .url === '' &&
              getters.getThreadedTweetsSharingDetails[threadedTweetIndex].image
                .length > 0
            ) {
              warningCheck = true
            }
            break
          case 'Linkedin':
            if (
              getters.getLinkedinSharingDetails.url === '' &&
              getters.getLinkedinSharingDetails.image.length > 0
            ) {
              warningCheck = true
            }
            break
          case 'Pinterest':
            if (
              getters.getPinterestSharingDetails.url === '' &&
              getters.getPinterestSharingDetails.image
            ) {
              warningCheck = true
            }
            break
          case 'Tumblr':
            if (
              getters.getTumblrSharingDetails.url === '' &&
              getters.getTumblrSharingDetails.image
            ) {
              warningCheck = true
            }
            break
          case 'Instagram':
            if (
              getters.getInstagramSharingDetails.url === '' &&
              getters.getInstagramSharingDetails.image.length > 0
            ) {
              warningCheck = true
            }
            break
          case 'Youtube':
            if (
              getters.getYoutubeSharingDetails.url === '' &&
              getters.getYoutubeSharingDetails.image
            ) {
              warningCheck = true
            }
            break
          case 'Tiktok':
            if (
              getters.getTiktokSharingDetails.url === '' &&
              getters.getTiktokSharingDetails.image
            ) {
              warningCheck = true
            }
            break
          case 'Gmb':
            if (
              getters.getGmbSharingDetails.url === '' &&
              getters.getGmbSharingDetails.image
            ) {
              warningCheck = true
            }
            break
          default:
        }
        console.debug(
          'Method::parseFilesMimeTypes ~ warningCheck -> ',
          warningCheck
        )
        // if image already added then we will show the confirmation dialog box to user.
        if (warningCheck) {
          // eslint-disable-next-line no-undef
          $('#replaceImageConfirmation').modal('show')
          commit('setConfirmActionData', {
            event: payload.event,
            type: payload.type,
            formData,
          })
        } else {
          // dispatch action to upload video
          dispatch('processUploadSocialVideo', {
            formData,
            type: payload.type,
            threadedTweetIndex,
          })
        }

        payload.event.target.value = ''
      } else {
        dispatch('toastNotification', {
          message: 'No file selected',
          type: 'error',
        })
      }
    },

    /**
     * This method will check the confirmation status and process the video upload if user confirmed the action.
     * @param commit
     * @param dispatch
     * @param getters
     * @param confirmation
     **status(boolean)
     **type(id of the confirmation model)
     */
    uploadSharingVideoConfirmation(
      { commit, dispatch, getters },
      confirmation
    ) {
      console.debug(
        'Action:uploadSharingVideoConfirmation',
        confirmation,
        getters.getConfirmActionDetails.data
      )
      if (confirmation.status) {
        dispatch(
          'processUploadSocialVideo',
          getters.getConfirmActionDetails.data
        )
      }
      // eslint-disable-next-line no-undef
      $('#' + confirmation.type).modal('hide')
    },

    /**
     * This method will start the video uploading.
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     **formData(containing array of files)
     **type(type of box)
     */
    processUploadSocialVideo({ commit, dispatch, getters }, payload) {
      console.debug('Action:processUploadSocialVideo', payload)

      const threadedTweetIndex = payload.threadedTweetIndex
        ? payload.threadedTweetIndex
        : 0

      // setting loaders for video upload
      switch (payload.type) {
        case 'Common':
          commit('SET_COMMON_SHARING_VIDEO_INITIALIZATION')
          commit('SET_UPLOAD_COMMON_SHARING_VIDEO_LOADER', true)
          break
        case 'Facebook':
          commit('SET_FACEBOOK_SHARING_VIDEO_INITIALIZATION')
          commit('SET_UPLOAD_FACEBOOK_SHARING_VIDEO_LOADER', true)
          break
        case 'Twitter':
          commit('SET_TWITTER_SHARING_VIDEO_INITIALIZATION')
          commit('SET_UPLOAD_TWITTER_SHARING_VIDEO_LOADER', true)
          break
        case 'ThreadedTweets':
          commit(
            'setThreadedTweetsSharingVideoInitialization',
            threadedTweetIndex
          )
          commit('setUploadThreadedTweetsSharingVideoLoader', {
            loader: true,
            index: threadedTweetIndex,
          })
          break
        case 'Linkedin':
          commit(linkedinSharingTypes.SET_SHARING_VIDEO_INITIALIZATION)
          commit('SET_UPLOAD_LINKEDIN_SHARING_VIDEO_LOADER', true)
          break
        case 'Pinterest':
          commit('SET_PINTEREST_SHARING_VIDEO_INITIALIZATION')
          commit('SET_UPLOAD_PINTEREST_SHARING_VIDEO_LOADER', true)
          break
        case 'Tumblr':
          commit('SET_TUMBLR_SHARING_VIDEO_INITIALIZATION')
          commit('SET_UPLOAD_TUMBLR_SHARING_VIDEO_LOADER', true)
          break
        case 'Instagram':
          commit(instagramTypes.SET_SHARING_VIDEO_INITIALIZATION)
          commit('SET_UPLOAD_INSTAGRAM_SHARING_VIDEO_LOADER', true)
          break
        case 'Youtube':
          commit(youtubeTypes.SET_SHARING_VIDEO_INITIALIZATION)
          commit('SET_UPLOAD_YOUTUBE_SHARING_VIDEO_LOADER', true)
          break
        case 'Tiktok':
          commit(tiktokTypes.SET_SHARING_VIDEO_INITIALIZATION)
          commit('SET_UPLOAD_TIKTOK_SHARING_VIDEO_LOADER', true)
          break
        case 'Gmb':
          commit(gmb.SET_SHARING_VIDEO_INITIALIZATION)
          commit(gmb.SET_UPLOAD_SHARING_VIDEO_LOADER, true)
          break
        default:
      }

      const ajax = new XMLHttpRequest()

      ajax.upload.addEventListener('progress', function (progressEvent) {
        dispatch('videoFileUploadProgressHandler', {
          event: progressEvent,
          type: payload.type,
          threadedTweetIndex,
        })
      })

      ajax.addEventListener('load', function (loadEvent) {
        dispatch('videoFileUploadCompleteHandler', {
          event: loadEvent,
          type: payload.type,
          threadedTweetIndex,
        })
      })

      ajax.addEventListener('error', function (loadEvent) {
        dispatch('videoFileUploadErrorHandler', {
          event: loadEvent,
          type: payload.type,
          threadedTweetIndex,
        })
      })

      ajax.open('POST', uploadMediaAssetUrl)
      ajax.setRequestHeader('Authorization', 'Bearer ' + getters.getJWTToken)
      ajax.send(payload.formData)
    },

    /**
     * This method will update the progress(percentage) of video uploading
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     **event
     **type
     */
    videoFileUploadProgressHandler({ commit, dispatch, getters }, payload) {
      console.debug('Action:videoFileUploadProgressHandler', payload)
      const threadedTweetIndex = payload.threadedTweetIndex
        ? payload.threadedTweetIndex
        : 0
      let percent = (payload.event.loaded / payload.event.total) * 100
      percent = Math.round(percent)

      // setting video upload percentage
      switch (payload.type) {
        case 'Common':
          commit('SET_COMMON_SHARING_VIDEO_PERCENTAGE', percent)
          break
        case 'Facebook':
          commit('SET_FACEBOOK_SHARING_VIDEO_PERCENTAGE', percent)
          break
        case 'Twitter':
          commit('SET_TWITTER_SHARING_VIDEO_PERCENTAGE', percent)
          break
        case 'ThreadedTweets':
          commit('setThreadedTweetsSharingVideoPercentage', {
            percent,
            index: threadedTweetIndex,
          })
          break
        case 'Linkedin':
          commit(linkedinSharingTypes.SET_SHARING_VIDEO_PERCENTAGE, percent)
          break
        case 'Pinterest':
          commit('SET_PINTEREST_SHARING_VIDEO_PERCENTAGE', percent)
          break
        case 'Tumblr':
          commit('SET_TUMBLR_SHARING_VIDEO_PERCENTAGE', percent)
          break
        case 'Instagram':
          commit(instagramTypes.SET_SHARING_VIDEO_PERCENTAGE, percent)
          break
        case 'Youtube':
          commit(youtubeTypes.SET_SHARING_VIDEO_PERCENTAGE, percent)
          break
        case 'Tiktok':
          commit(tiktokTypes.SET_SHARING_VIDEO_PERCENTAGE, percent)
          break
        case 'Gmb':
          commit(gmb.SET_SHARING_VIDEO_PERCENTAGE, percent)
          break
        default:
      }
    },

    /**
     * This method will be called when video uploaded completely.
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     **event
     **type
     */
    videoFileUploadCompleteHandler({ commit, dispatch, getters }, payload) {
      console.debug('Action:videoFileUploadCompleteHandler', payload)
      const threadedTweetIndex = payload.threadedTweetIndex
        ? payload.threadedTweetIndex
        : 0

      try {
        let response = payload.event.target.responseText

        response = JSON.parse(response)

        response.media = response.media_container

        if (response.status === true) {
          dispatch('updateSocialSharingMedia', response.media)
          // setting state of uploaded video details
          switch (payload.type) {
            case 'Common':
              commit('SET_COMMON_SHARING_VIDEO', response.media[0])
              commit(social.SET_GMB_OPTIONS, null)
              break
            case 'Facebook':
              commit('SET_FACEBOOK_SHARING_VIDEO', response.media[0])
              break
            case 'Twitter':
              commit('SET_TWITTER_SHARING_VIDEO', response.media[0])
              break
            case 'ThreadedTweets':
              commit('setThreadedTweetsSharingVideo', {
                video: response.media[0],
                index: threadedTweetIndex,
              })
              break
            case 'Linkedin':
              commit(linkedinSharingTypes.SET_SHARING_VIDEO, response.media[0])
              break
            case 'Pinterest':
              commit('SET_PINTEREST_SHARING_VIDEO', response.media[0])
              break
            case 'Tumblr':
              commit('SET_TUMBLR_SHARING_VIDEO', response.media[0])
              break
            case 'Instagram':
              commit(instagramTypes.SET_SHARING_VIDEO, response.media[0])
              break
            case 'Youtube':
              commit(youtubeTypes.SET_SHARING_VIDEO, response.media[0])
              break
            case 'Tiktok':
              commit(tiktokTypes.SET_SHARING_VIDEO, response.media[0])
              break
            case 'Gmb':
              commit(gmb.SET_SHARING_VIDEO, response.media[0])
              commit(social.SET_GMB_OPTIONS, null)
              break
            default:
          }
        } else if (response.storageFull) {
          commit('setMediaStorageLimit', response.usedStorage)
          appContainer.$bvModal.show('media-storage-limits-exceeded-modal')
        } else if (
          response.message &&
          response.message.includes('format is not correct')
        ) {
          dispatch('toastNotification', {
            message: response.message,
            type: 'error',
          })
        } else {
          dispatch('toastNotification', {
            message: 'Unable to upload video.',
            type: 'error',
          })
        }
      } catch (e) {
        console.debug('Exception::Action:videoFileUploadCompleteHandler', e)
        dispatch('toastNotification', {
          message: 'Unable to upload video.',
          type: 'error',
        })
      }

      // hide loader of video upload
      switch (payload.type) {
        case 'Common':
          commit('SET_UPLOAD_COMMON_SHARING_VIDEO_LOADER', false)
          break
        case 'Facebook':
          commit('SET_UPLOAD_FACEBOOK_SHARING_VIDEO_LOADER', false)
          break
        case 'Twitter':
          commit('SET_UPLOAD_TWITTER_SHARING_VIDEO_LOADER', false)
          break
        case 'ThreadedTweets':
          commit('setUploadThreadedTweetsSharingVideoLoader', {
            loader: false,
            index: threadedTweetIndex,
          })
          break
        case 'Linkedin':
          commit('SET_UPLOAD_LINKEDIN_SHARING_VIDEO_LOADER', false)
          break
        case 'Pinterest':
          commit('SET_UPLOAD_PINTEREST_SHARING_VIDEO_LOADER', false)
          break
        case 'Tumblr':
          commit('SET_UPLOAD_TUMBLR_SHARING_VIDEO_LOADER', false)
          break
        case 'Instagram':
          commit('SET_UPLOAD_INSTAGRAM_SHARING_VIDEO_LOADER', false)
          break
        case 'Youtube':
          commit('SET_UPLOAD_YOUTUBE_SHARING_VIDEO_LOADER', false)
          break
        case 'Tiktok':
          commit('SET_UPLOAD_TIKTOK_SHARING_VIDEO_LOADER', false)
          break
        case 'Gmb':
          commit(gmb.SET_UPLOAD_SHARING_VIDEO_LOADER, false)
          break
        default:
      }
    },

    /**
     * This method will be called if any exception is generated in the process of video upload
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     **event
     **type
     */
    videoFileUploadErrorHandler({ commit, dispatch, getters }, payload) {
      console.debug('Action:videoFileUploadErrorHandler', payload)
      const threadedTweetIndex = payload.threadedTweetIndex
        ? payload.threadedTweetIndex
        : 0
      dispatch('toastNotification', { message: UNKNOWN_ERROR, type: 'error' })
      // hide loader of video upload
      switch (payload.type) {
        case 'Common':
          commit('SET_UPLOAD_COMMON_SHARING_VIDEO_LOADER', false)
          commit('SET_COMMON_SHARING_VIDEO_PERCENTAGE', 0)
          break
        case 'Facebook':
          commit('SET_UPLOAD_FACEBOOK_SHARING_VIDEO_LOADER', false)
          commit('SET_FACEBOOK_SHARING_VIDEO_PERCENTAGE', 0)
          break
        case 'Twitter':
          commit('SET_UPLOAD_TWITTER_SHARING_VIDEO_LOADER', false)
          commit('SET_TWITTER_SHARING_VIDEO_PERCENTAGE', 0)
          break
        case 'ThreadedTweets':
          commit('setUploadThreadedTweetsSharingVideoLoader', {
            loader: false,
            index: threadedTweetIndex,
          })
          commit('setThreadedTweetsSharingVideoPercentage', {
            percent: 0,
            index: threadedTweetIndex,
          })
          break
        case 'Linkedin':
          commit('SET_UPLOAD_LINKEDIN_SHARING_VIDEO_LOADER', false)
          commit(linkedinSharingTypes.SET_SHARING_VIDEO_PERCENTAGE, 0)
          break
        case 'Pinterest':
          commit('SET_UPLOAD_PINTEREST_SHARING_VIDEO_LOADER', false)
          commit('SET_PINTEREST_SHARING_VIDEO_PERCENTAGE', 0)
          break
        case 'Tumblr':
          commit('SET_UPLOAD_TUMBLR_SHARING_VIDEO_LOADER', false)
          commit('SET_TUMBLR_SHARING_VIDEO_PERCENTAGE', 0)
          break
        case 'Instagram':
          commit('SET_UPLOAD_INSTAGRAM_SHARING_VIDEO_LOADER', false)
          commit(instagramTypes.SET_SHARING_VIDEO_PERCENTAGE, 0)
          break
        case 'Youtube':
          commit('SET_UPLOAD_YOUTUBE_SHARING_VIDEO_LOADER', false)
          commit(youtubeTypes.SET_SHARING_VIDEO_PERCENTAGE, 0)
          break
        case 'Tiktok':
          commit('SET_UPLOAD_TIKTOK_SHARING_VIDEO_LOADER', false)
          commit(tiktokTypes.SET_SHARING_VIDEO_PERCENTAGE, 0)
          break
        case 'Gmb':
          commit(gmb.SET_UPLOAD_SHARING_VIDEO_LOADER, false)
          commit(gmb.SET_SHARING_VIDEO_PERCENTAGE, 0)
          break
        case 'Bluesky':
          commit(bluesky.SET_UPLOAD_SHARING_VIDEO_LOADER, false)
          commit(bluesky.SET_SHARING_VIDEO_PERCENTAGE, 0)
          break
        default:
      }
    },

    // composer drag drop actions start

    /**
     * This method is called on file leave drag over
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     */
    leaveFileDragOver({ commit, dispatch, getters }, payload) {
      console.debug('Action::leaveFileDragOver', payload)
      payload.event.stopPropagation()
      payload.event.preventDefault()
      commit(sharingTypes.SET_DRAG_OVER_STATUS, {
        type: payload.type,
        status: false,
      })
    },

    /**
     * This method is called on file drag over
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     */
    onFileDragOver({ commit, dispatch, getters }, payload) {
      console.debug('Action::onFileDragOver', payload)
      payload.event.stopPropagation()
      payload.event.preventDefault()
      commit(sharingTypes.SET_DRAG_OVER_STATUS, {
        type: payload.type,
        status: true,
      })
    },

    /**
     * This method is called on file drop
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     */
    onFileDrop({ commit, dispatch, getters }, payload) {
      console.debug('Action::onFileDrop', payload.eventType)
      payload.event.stopPropagation()
      payload.event.preventDefault()
      commit(sharingTypes.SET_DRAG_OVER_STATUS, {
        type: payload.type,
        status: false,
      })

      if (
        payload.type !== 'Common' &&
        getters.getSocialSharingSelection.activeTab !==
          payload.type.toLowerCase() + '_tab'
      ) {
        dispatch('toastNotification', {
          message: 'Please select the ' + payload.type + ' box to drop file.',
          type: 'error',
        })
        return false
      }

      const types = parseFilesMimeTypes(payload.event)

      // checking for the the files other than image or video
      if (types.others) {
        dispatch('toastNotification', {
          message: 'Your are allowed to upload only image or video file.',
          type: 'error',
        })
        return false
      }

      // checking for the the multiple gifs
      if (types.gifs > 1) {
        dispatch('toastNotification', {
          message: 'Your are allowed to upload only 1 GIF image.',
          type: 'error',
        })
        return false
      }

      // checking for the multiple videos
      if (types.videos > 1) {
        dispatch('toastNotification', {
          message: 'Your are allowed to upload only 1 video.',
          type: 'error',
        })
        return false
      }

      // checking for the multiple files types
      if (
        (types.gifs && types.images) ||
        (types.gifs && types.videos) ||
        (types.images && types.videos)
      ) {
        dispatch('toastNotification', {
          message: 'Please upload one file type at a time.',
          type: 'error',
        })
        return false
      }

      // calling method for uploading image
      if (types.gifs || types.images) {
        appContainer.uploadSharingImage(event, payload.type)
      }

      // calling method for uploading video
      if (types.videos) {
        dispatch('uploadSharingVideo', { event, type: payload.type })
      }
    },

    onPasteFromClipboard({ commit, dispatch, getters }, payload) {
      payload.event.stopPropagation()
      payload.event.preventDefault()

      const types = parseFilesMimeTypes(payload.event)

      // checking for the the files other than image or video
      if (types.others) {
        dispatch('toastNotification', {
          message: 'Your are allowed to upload only image or video file.',
          type: 'error',
        })
        return false
      }

      // checking for the the multiple gifs
      if (types.gifs > 1) {
        dispatch('toastNotification', {
          message: 'Your are allowed to upload only 1 GIF image.',
          type: 'error',
        })
        return false
      }

      // checking for the multiple videos
      if (types.videos > 1) {
        dispatch('toastNotification', {
          message: 'Your are allowed to upload only 1 video.',
          type: 'error',
        })
        return false
      }

      // checking for the multiple files types
      if (
        (types.gifs && types.images) ||
        (types.gifs && types.videos) ||
        (types.images && types.videos)
      ) {
        dispatch('toastNotification', {
          message: 'Please upload one file type at a time.',
          type: 'error',
        })
        return false
      }

      // calling method for uploading image
      if (types.gifs || types.images) {
        appContainer.uploadSharingImage(event, payload.type)
      }

      // calling method for uploading video
      if (types.videos) {
        dispatch('uploadSharingVideo', { event, type: payload.type })
      }
    },

    // composer drag drop actions end

    updateSocialSharingMedia({ commit, dispatch, getters }, media, index = 0) {
      console.debug('Action::updateSocialSharingMedia', media)
      if (media && media.constructor === Array) {
        const sharingMedia = JSON.parse(
          JSON.stringify(getters.getSocialSharingMediaDetails)
        )
        media.forEach((item) => {
          if (item.link && !sharingMedia[item.link]) {
            const requiredFields = {
              size: item.size,
              mime_type: item.mime_type,
            }
            if (item.name) requiredFields.name = item.name
            if (item.zapier) requiredFields.zapier = item.zapier
            if (item.duration_seconds) {
              requiredFields.duration_seconds = item.duration_seconds
            }
            if (item.h) requiredFields.height = item.h
            if (item.w) requiredFields.width = item.w
            if (item.a) requiredFields.aspect_ratio = item.a
            sharingMedia[item.link] = requiredFields
          }
        })
        commit(sharingTypes.SET_SOCIAL_SHARING_MEDIA_DETAILS, sharingMedia)
      }
    },
  },
  mutations: {
    resetSharingDetails(state) {
      Object.assign(state.selection, getDefaultSharingDetails())
    },
    [sharingTypes.SET_SHARING_ACTIVE_TAB](state, value) {
      state.selection.activeTab = value
    },

    [sharingTypes.SET_MENTIONS_DROPDOWN_STATUS](state, status) {
      state.selection.mentionsSuggestions.dropdown.status = status
    },

    [sharingTypes.SET_MENTIONS_DROPDOWN_LEFT_POSITION](state, position) {
      state.selection.mentionsSuggestions.dropdown.left = position
    },

    [sharingTypes.SET_MENTIONS_DROPDOWN_TOP_POSITION](state, position) {
      state.selection.mentionsSuggestions.dropdown.top = position
    },

    [sharingTypes.SET_FACEBOOK_MENTIONS_SUGGESTIONS](state, suggestions) {
      state.selection.mentionsSuggestions.facebook = suggestions
    },

    [sharingTypes.SET_TWITTER_MENTIONS_SUGGESTIONS](state, suggestions) {
      state.selection.mentionsSuggestions.twitter = suggestions
    },

    [sharingTypes.SET_DRAG_OVER_STATUS](state, payload) {
      state.selection.drag_over_status[payload.type.toLowerCase()] =
        payload.status
    },
    [sharingTypes.RESET_SHARING_TAGS_SUGGESTION_ITEMS](state) {
      state.selection.tagsSuggestions.items = []
    },

    setHasFirstComment(state, hasFirstComment) {
      state.selection.first_comment.has_first_comment = hasFirstComment
    },

    setFirstCommentMessage(state, firstCommentMessage) {
      state.selection.first_comment.first_comment_message = firstCommentMessage
    },

    setFirstCommentLocation(state, firstCommentLocation) {
      state.selection.first_comment.first_comment_location =
        firstCommentLocation
    },

    setFirstCommentAccount(state, selectedAccounts) {
      state.selection.first_comment.first_comment_accounts = selectedAccounts
    },

    setFirstComment(state, firstComment) {
      state.selection.first_comment = firstComment
    },

    setSocialSharingPostId(state, postId) {
      state.selection.post_id = postId
    },

    setSharingTagsReplaceStatus(state, status) {
      state.selection.tagsSuggestions.replaceKeyword = status
    },

    setSocialSharingTagsSuggestionsItems(state, suggestions) {
      state.selection.tagsSuggestions.items = suggestions
    },

    setSharingActiveTab(state, activeTab) {
      state.selection.activeTab = activeTab
    },

    setSharingMentionsType(state, type) {
      state.selection.mentionsSuggestions.type = type
    },

    setSharingMentionsKeyword(state, keyword) {
      state.selection.mentionsSuggestions.keyword = keyword
    },

    [sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS](state, status) {
      state.selection.hashTagsSuggestions.dropdown.status = status
    },
    [sharingTypes.SET_HASHTAGS_SUGGESTIONS](state, data) {
      state.selection.hashTagsSuggestions.data = data
    },
    [sharingTypes.SET_HASHTAGS_DROPDOWN_LEFT_POSITION](state, left) {
      state.selection.hashTagsSuggestions.dropdown.left = left
    },
    [sharingTypes.SET_HASHTAGS_DROPDOWN_TOP_POSITION](state, top) {
      state.selection.hashTagsSuggestions.dropdown.top = top
    },
    [sharingTypes.SET_HASHTAGS_SEARCH_KEYWORD](state, keyword) {
      state.selection.hashTagsSuggestions.keyword = keyword
    },
    [sharingTypes.SET_HASHTAGS_ACTIVE_BOX](state, box) {
      state.selection.hashTagsSuggestions.box = box
    },

    [sharingTypes.SET_SOCIAL_SHARING_MEDIA_DETAILS](state, media) {
      state.selection.sharing_media_details = media
    },
  },
  getters: {
    getSocialSharingSelection: (state) => state.selection,
    getSocialSharingMediaDetails: (state) =>
      state.selection.sharing_media_details,
    getSocialSharingFirstComment: (state) => state.selection.first_comment,
  },
}
