import { mapGetters } from 'vuex'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import startCase from 'lodash/startCase'

export default {
  computed: {
    ...mapGetters([
      'getFacebookAccounts',
      'getTwitterAccounts',
      'getPinterestBoards',
      'getLinkedinAccounts',
      'getTumblrAccounts',
      'getGmbAccounts',
      'getInstagramAccounts',
      'getThreadsAccounts',
      'getBlueskyAccounts'
    ]),
  },
  methods: {
    /**
     *
     * @param accountId string
     * @param type string
     * @returns {*}
     */
    channelItem(accountId, type, account = null) {
      switch (type) {
        case 'facebook':
          return this.getFacebookAccounts.items.find(
            (item) =>
              item.facebook_id === accountId ||
              item.facebook_id === parseInt(accountId) ||
              (account && item.facebook_id === account.facebook_id)
          )
        case 'threads':
          return this.getThreadsAccounts.items.find((item)=> item.platform_identifier === accountId)
        case 'bluesky':
          return this.getBlueskyAccounts.items.find((item)=> item.platform_identifier === accountId)
        case 'twitter':
          return this.getTwitterAccounts.items.find(
            (item) => item.twitter_id === accountId
          )
        case 'pinterest':
          return this.getPinterestBoards.find(
            (item) =>
              item.board_id === accountId ||
              (account && item.board_id === account.board_id)
          )
        case 'linkedin':
          return this.getLinkedinAccounts.items.find(
            (item) =>
              item.linkedin_id === accountId ||
              item.linkedin_id === parseInt(accountId) ||
              (account && item.linkedin_id === account.linkedin_id)
          )
        case 'tumblr_profile':
          return this.getTumblrAccounts.find(
            (item) =>
              item.name === accountId || (account && item.name === account.name)
          )
        case 'gmb':
          return this.getGmbAccounts.items.find(
            (item) => item.name === accountId
          )
        case 'instagram':
          return this.getInstagramAccounts.items.find(
            (item) =>
              item.instagram_id === accountId ||
              (account && item.instagram_id === account.instagram_id)
          )
        case 'youtube':
          return this.getYoutubeAccounts.items.find(
            (item) =>
              item.platform_identifier === accountId ||
              (account &&
                item.platform_identifier === account.platform_identifier)
          )
        case 'tiktok':
          return this.getTiktokAccounts.items.find((item)=> item.platform_identifier === accountId)
      }
      return account
    },

    channelBackgroundImage(account, channelName) {
      return `url('${this.channelImage(
        account,
        channelName
      )}'), url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg')`
    },

    channelName(account, type) {
      let name = ''
      switch (type) {
        case 'gmb':
          if (account.platform_name) return account.platform_name
          if (account.location_name) {
            name = account.location_name + ' - ' + account.locality
            return account.postal_code
              ? name + ' - ' + account.postal_code
              : name
          }
          break
        case 'pinterest':
          if (account.pinterest_id)
            return `${account.first_name} ${account.last_name}`
          break
        case 'tiktok':
        case 'youtube':
        case 'threads':
        case 'bluesky':
          return account.platform_name
      }
    },

    /**
     *
     * @param account object
     * @param type string
     */
    channelImage(account, type) {
      const image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
      const gmbImage =
        'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
      if (!account) return image

      if (account && account.platform_logo) return account.platform_logo
      account = this.channelItem(null, type, account)
        ? this.channelItem(null, type, account)
        : account
      switch (type) {
        case 'facebook':
          return account.image
            ? account.image
            : account.picture && account.picture.url
            ? account.picture.url
            : image
        case 'twitter':
        case 'linkedin':
        case 'pinterest':
          return account.image && account.image.large && account.image.large.url
            ? account.image.large.url
            : account.image
            ? account.image
            : image
        case 'instagram':
          return account.image
            ? account.image
            : account.profile_picture
            ? account.profile_picture
            : image
        case 'medium':
          return account.imageUrl ? account.imageUrl : image
        case 'gmb':
          return account.location_name ? gmbImage : image
        case 'threads':
        case 'bluesky':
          return account.image
              ? account.image
              : account.platform_logo
              ? account.platform_logo
              : image
      }
    },
    channelTooltipHTML(channelType, channel, onlyName = false) {
      // console.debug('Method::channelTooltipHTML', channelType, channel)
      let name = ''
      let handler = ''
      let type = ''
      let image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

      if (blogIntegrationsNames.includes(channelType)) {
        name = channel.platform_url
          ? channel.platform_url
          : channel.platform_name
        image = channel.platform_logo
        if (channel.type) type = startCase(channel.type)
      } else {
        switch (channelType) {
          case 'gmb':
            image = this.channelImage(channel, channelType)
            name = this.channelName(channel, channelType)
            type = 'Location'
            break
          case 'facebook':
            image = this.channelImage(channel, channelType)
            type = channel.type
            break
          case 'twitter':
            image = this.channelImage(channel, channelType)
            handler = `<span class='handler'>@${channel.screen_name}</span>`
            type = 'Profile'
            break
          case 'linkedin':
          case 'pinterest':
            image = this.channelImage(channel, channelType)
            type = channel.type || 'Board'
            break
          case 'instagram':
            image = this.channelImage(channel, channelType)
            type = 'Profile'
            break
          case 'threads':
            image = this.channelImage(channel, channelType)
            name = this.channelName(channel, channelType)
            type = 'Profile'
            break
          case 'youtube':
            image = this.channelImage(channel, channelType)
            name = this.channelName(channel, channelType)
            type = 'Channel'
            break
          case 'tiktok':
            image = this.channelImage(channel, channelType)
            handler = `<span class='handler'>@${channel.platform_name}</span>`
            name = this.channelName(channel, channelType)
            type = 'Profile'
            break
          case 'influencer':
            image = channel.profile_image
            type = 'Followers'
            name = `${channel.name}<span class="screen_name">@${channel.screen_name}</span>`
            handler = `<strong> ${channel.follower_count}</strong>`
            break
          case 'bluesky':
            image = this.channelImage(channel, channelType)
            name = this.channelName(channel, channelType)
            type = 'Profile'
            break
        }
      }

      name = name || channel.name

      if (onlyName) return name
      return `<div class='team_detail_tooltip'>
                  <div class='t_inner d-flex align-items-center'>
                      <div class='img' style='background-image: url(${image}), url( "https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg");'></div>
                      <div class='t_content'>
                          <h3>${name}</h3>
                          <p>${type}${handler}</p>
                      </div>
                  </div>
              </div>`
    },
    // to show multiple tooltips for more than 1 social profiles selected
    channelTooltipMultipleHTML(channelType, channels) {
      let divContainerString = '' // string used to append multiple tooltip divs

      const cutOff = 3 // cutoff is used to show the number of item to show .. the rest will be shown as "xyz more accounts."
      const channelsCount = channels.length > cutOff ? cutOff : channels.length // used to split the data as per cutoff specified

      for (let x = 0; x < channelsCount; x++) {
        // will loop over channels count based on cutoff requirement
        const channel = channels[x] // channel object
        // variable declaration
        let name = ''
        let type = ''
        let image =
          'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

        // image for the channel object
        image = this.channelImage(channel, channelType)
        // type of social channel
        type = 'Profile'

        // select name as empty string if .name does not exist
        name = name || channel.name

        // the div container that shows the channel details
        const container = `<div class='team_detail_tooltip'>
                  <div class='t_inner d-flex align-items-center'>
                      <div class='img' style='background-image: url(${image}), url( "https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg");'></div>
                      <div class='t_content'>
                          <h3>${name}</h3>
                          <p>${type}</p>
                      </div>
                  </div>
              </div>`

        // concat the container to divContainerString
        divContainerString = container + divContainerString
      }

      // if the channels are more than cutoff
      if (channels.length > cutOff) {
        // the remainder length of objects that are cut off
        const remainder = channels.length - cutOff
        // the div container that shows the channel details
        const container = `<div class='team_detail_tooltip'>
                  <div class='t_inner d-flex align-items-center'>
                      <div class='t_content'>
                          <h3><u>${remainder}</u> more ${
          remainder === 1 ? 'account' : 'accounts'
        }.</h3>
                      </div>
                  </div>
              </div>`
        // concat the container to divContainerString
        divContainerString = container + divContainerString
      }

      // returns the final div container string to be used in the code
      return divContainerString
    },
    getModifiedChannelName(name) {
      switch (this.type) {
        case 'tumblr':
          return name.replace('profile_', '')
        default:
          return name
      }
    },
    channelType(type) {
      switch (this.type) {
        case 'twitter':
        case 'instagram':
          return type === 'personal' ? 'Personal' : 'Business'
        case 'tumblr':
          return 'Profile'
        case 'gmb':
          return 'Location'
        case 'tumblrBlog':
          return 'Blog'
        case 'youtube':
          return 'Channel'
      }
      return type
    },
    getChannelItems(channel) {
      const getter = this.$store.getters[channel.getter]
      return 'items' in getter ? getter.items : getter
    },
    /**
     * @deprecated
     * This method is defined in useSocialChannel.js composable.
     * To-Do: remove this method if not used anywhere to avoid any verbose code duplication.
     * @param type
     * @param account
     * @returns {*}
     */
    getFreshAccount(type, account) {
      return this.channelItem(null, type, account)
        ? this.channelItem(null, type, account)
        : account
    },
  },
}
