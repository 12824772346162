<template>
  <div
    ref="historyContainer"
    class="w-full min-w-[30rem] max-w-[30rem] h-full overflow-y-auto flex flex-col px-4 pt-2 pb-4 transition-all duration-200"
    @scroll="handleScroll"
  >
    <!-- History Heading -->
    <div class="text-[#4a4557] text-base font-semibold leading-tight mt-2 mb-4">
      History
    </div>

    <div v-for="(section, date) in historyItems" :key="date" class="history-section">
      <!-- Date Header -->
      <div class="date-divider">
        <div class="divider-line"></div>
        <div class="date-pill">
          <div class="date-text">{{ formatDate(date) }}</div>
        </div>
        <div class="divider-line"></div>
      </div>

      <!-- History Items -->
      <div class="space-y-6 ">
        <div
          v-for="(item, index) in section"
          :key="index"
          class="p-[15px] bg-[#fbfbfb] rounded-lg border border-[#eff0f1] flex-col justify-start items-start gap-2.5 flex cst-editor cursor-pointer"
          @click="HandleHistoryClick(item)"
        >
          <!-- Prompt Text -->
          <div class="self-stretch text-[#4a4557] text-[13px] font-normal leading-[19px] mb-3.5">
            {{ item.prompt.text }}
          </div>

          <!-- Generated Images -->
          <div class="grid grid-cols-3 gap-4 w-full">
            <div
              v-for="(image, imageIndex) in item.response"
              :key="imageIndex"
              class="relative h-[118px] rounded-[11.03px] border border-[#e4e4e4]"
            >
              <img
                :src="image.base64 ? `data:image/png;base64,${image.base64.trim()}` : ''"
                :alt="image.alt"
                class="w-full h-full object-contain"
              />
            </div>
          </div>

          <!-- Tags -->
          <div class="justify-start items-start gap-3.5 inline-flex mt-3.5">
            <div
              v-if="item.prompt?.aspect_ratio"
              class="aspect-pill px-3 py-[5px] rounded-full justify-center items-center gap-2.5 flex"
            >
              <div class="text-[#3a4557] text-[13px] font-normal leading-tight">
                Aspect Ratio ( {{ item.prompt?.aspect_ratio }} )
              </div>
            </div>
            <div
              v-if="item.prompt?.style"
              class="style-pill px-3 py-[5px] rounded-full justify-center items-center gap-2.5 flex"
            >
              <div class="text-[#3a4557] text-[13px] font-normal leading-tight">
                Style: {{ item.prompt?.style }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Loading Skeleton like history card -->
    <div v-if="isLoading" class="flex justify-center py-4">
      <beat-loader :color="'#436aff'"></beat-loader>
    </div>

    <!-- Empty State -->
    <div v-if="!hasHistory && !isLoading" class="flex flex-col items-center justify-center py-12">
      <i class="fa fa-history text-4xl text-gray-400 mb-3"></i>
      <p class="text-[#4a4557] text-[13px] font-normal leading-tight">
        You have not generated any images yet. Generate some images now!
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import useImageGeneration from '@/src/modules/composer_v2/composables/useImageGeneration'
import useDateFormat from '@/src/modules/common/composables/useDateFormat';

const { momentWrapper } = useDateFormat()

const historyContainer = ref(null)
const {
  historyItems,
  hasHistory,
  isLoading,
  hasMore,
  fetchHistory,
  loadMore,
  setPromptDetails,
} = useImageGeneration()

// Initial load
onMounted(() => {
  fetchHistory()
})

// Handle scroll for infinite loading
const handleScroll = () => {
  if (!historyContainer.value) return

  const container = historyContainer.value
  const { scrollTop, scrollHeight, clientHeight } = container
  const scrollPosition = scrollHeight - scrollTop - clientHeight

  if (scrollPosition < 50 && !isLoading.value && hasMore.value) {
    loadMore()
  }
}

// Format date for display
const formatDate = (date) => {
  // Check for today
  if (momentWrapper(date).format('YYYY-MM-DD') === momentWrapper().format('YYYY-MM-DD')) {
    return 'Today'
  }

  // Check for yesterday
  if (momentWrapper(date).format('YYYY-MM-DD') === momentWrapper().subtract(1, 'days').format('YYYY-MM-DD')) {
    return 'Yesterday'
  }

  return momentWrapper(date).formatDate()
}

const HandleHistoryClick = (item) => {
  const { prompt, response } = item
  setPromptDetails(prompt, response)
}
</script>

<style scoped>
.history-section {
  margin-bottom: 2rem;
}

.history-section:last-child {
  margin-bottom: 0;
}

.history-item {
  background: #fbfbfb;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Ensure images maintain aspect ratio */
.aspect-square {
  aspect-ratio: 1;
}

.style-pill{
  background-color: rgba(252, 103, 38, 0.1) !important;
  border: 1px solid rgba(252, 103, 38, 0.4) !important;
}

.aspect-pill{
  background-color: rgba(227, 174, 0, 0.1) !important;
  border: 1px solid rgba(227, 174, 0, 0.4) !important;
}

.date-divider {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;
  margin-bottom: 1.25rem;
}

.divider-line {
  flex: 1 1 0;
  height: 1px;
  border-top: 1px solid #e9e9e9;
}

.date-pill {
  padding: 5px 12px;
  background-color: #ecf5ff;
  border-radius: 100px;
  border: 1px solid rgba(105, 180, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}

.date-text {
  color: #3a4557;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.2;
}
</style>
