const moment = require('moment-timezone/builds/moment-timezone-with-data.min')

export const defaultAccountSelection = {
  facebook: [],
  twitter: [],
  linkedin: [],
  pinterest: [],
  tumblr: [],
  instagram: [],
  gmb: [],
  youtube: [],
  tiktok: [],
  threads: []
}

export const defaultCommonLocation = {
  facebook: {
    id: '',
    name: '',
  },
  twitter: {
    id: '',
    name: '',
  },
  instagram: {
    id: '',
    name: '',
  },
}

export const videoDefaultSharingDetails = {
  name: '',
  title: '',
  mime_type: '',
  link: '',
  duration: '00:00:00.00',
  size: 0,
  thumbnail: '',
  thumbnails_suggestions: [],
  converted_video: '',
  converted_link: '',
  converted_mime_type: '',
  converted_size: 0,
}

export const defaultFirstComment = {
  has_first_comment: false,
  first_comment_message: '',
  first_comment_accounts: [],
}

export const defaultCommonSharingDetails = {
  message: '',
  title: '',
  twitter_title: '',
  pinterest_title: '',
  description: '',
  twitter_description: '',
  image: [],
  twitter_image: [],
  video: videoDefaultSharingDetails,
  url: '',
  facebook_url: '',
  twitter_url: '',
  linkedin_url: '',
  pinterest_url: '',
  tumblr_url: '',
  instagram_url: '',
  threads_url: '',
  gmb_url: '',
  youtube_url: '',
  source_url: '',
  cta_link: '',
  website: '',
  twitter_website: '',
  image_suggestions: [],
  mentions: [],
  location: defaultCommonLocation,
  image_tagging_details: {},
  multimedia: []
}

export const defaultFacebookSharingDetails = {
  message: '',
  title: '',
  description: '',
  image: [],
  video: videoDefaultSharingDetails,
  url: '',
  website: '',
  image_suggestions: [],
  mentions: [],
  location: {
    id: '',
    name: '',
  },
  multimedia: [],
}

export const defaultTwitterSharingDetails = {
  message: '',
  title: '',
  description: '',
  image: [],
  video: videoDefaultSharingDetails,
  url: '',
  website: '',
  image_suggestions: [],
  mentions: [],
  location: {
    id: '',
    name: '',
  },
}

export const defaultInstagramSharingDetails = {
  message: '',
  image: [],
  video: videoDefaultSharingDetails,
  url: '',
  location: {
    id: '',
    name: '',
  },
  multimedia: [],
  image_tagging_details: {},
}

export const defaultLinkedinSharingDetails = {
  message: '',
  title: '',
  description: '',
  image: [],
  video: videoDefaultSharingDetails,
  url: '',
  website: '',
  image_suggestions: [],
}

export const defaultPinterestSharingDetails = {
  message: '',
  pinterest_title: '',
  description: '',
  // single image in array
  image: [],
  video: videoDefaultSharingDetails,
  url: '',
  source_url: '',
}

export const defaultTumblrSharingDetails = {
  message: '',
  // single image in array
  image: [],
  video: videoDefaultSharingDetails,
  url: '',
}

export const defaultGmbSharingDetails = {
  message: '',
  // single image in array
  image: [],
  video: videoDefaultSharingDetails,
  url: '',
  cta_link: '',
}

export const defaultYoutubeSharingDetails = {
  message: '',
  video: videoDefaultSharingDetails,
  url: '',
}

export const defaultTiktokSharingDetails = {
  message: '',
  video: videoDefaultSharingDetails,
  image: []
}

export const defaultThreadsSharingDetails = {
  message: '',
  image: [],
  video: videoDefaultSharingDetails,
  url: '',
  multimedia: [],
  title: '',
  description: '',
  website: '',
  image_suggestions: [],
}

export const defaultBlueskySharingDetails = {
  message: '',
  image: [],
  video: videoDefaultSharingDetails,
  url: '',
  title: '',
  description: '',
  website: '',
  image_suggestions: [],
}


export const defaultSharingDetails = {
  common_sharing_details: defaultCommonSharingDetails,
  facebook_sharing_details: defaultFacebookSharingDetails,
  twitter_sharing_details: defaultTwitterSharingDetails,
  instagram_sharing_details: defaultInstagramSharingDetails,
  linkedin_sharing_details: defaultLinkedinSharingDetails,
  pinterest_sharing_details: defaultPinterestSharingDetails,
  tumblr_sharing_details: defaultTumblrSharingDetails,
  gmb_sharing_details: defaultGmbSharingDetails,
  youtube_sharing_details: defaultYoutubeSharingDetails,
  tiktok_sharing_details: defaultTiktokSharingDetails,
  threads_sharing_details: defaultThreadsSharingDetails,
  bluesky_sharing_details: defaultBlueskySharingDetails
}

// Default Sharing Options
export const defaultTwitterOptions = {
  has_threaded_tweets: false,
  threaded_tweets: [{ ...defaultTwitterSharingDetails }],
  threaded_tweets_accounts: [],
}

export const defaultThreadsOptions = {
  has_multi_threads: false,
  multi_threads: [{ ...defaultThreadsSharingDetails }]
}

export const defaultBlueskyOptions = {
  has_multi_bluesky: false,
  multi_bluesky: [{ ...defaultBlueskySharingDetails }]
}

// default linkedIn carousel options
export const defaultLinkedinOptions = {
  title: '',
  is_carousel: false,
  accounts: [],
  document_added: false,
  document: null,
}

export const defaultGmbOptions = {
  topic_type: 'STANDARD',
  start_date: '',
  end_date: '',
  title: '',
  coupon_code: '',
  redeem_online_url: '',
  terms_conditions: '',
  action_type: '',
}

export const defaultYoutubeOptions = {
  title: '',
  privacy_status: 'public',
  category: '',
  playlist: '',
  tags: [],
  license: 'youtube',
  embeddable: true,
  notify_subscribers: true,
  made_for_kids: false,
  post_type: 'video',
}

export const defaultCarouselOptions = {
  is_carousel_post: false,
  cards: [],
  accounts: [],
  call_to_action: 'NO_BUTTON',
  end_card: false,
  end_card_url: '',
}

export const defaultFacebookOptions = {
  posted_as: {},
}
export const defaultPinterestOptions = {
  postDescription: '',
}

export const defaultPublishTimeOptions = {
  time_type: 'now',
  post_date: moment().format('MMMM DD, YYYY HH:mm'), // getDateTimeFormat(Date.now(), 'MMMM DD, YYYY hh:mm A'),
  repeat: '',
  repeat_type: 'Day',
  repeat_times: 1,
  repeat_gap: 3,
  evergreen: '',
  plan_status: 'Approved',
}

export const defaultPublishTimeOptionsForSchedulePost = {
  time_type: 'schedule',
  repeat: '',
  repeat_type: 'Day',
  repeat_times: 1,
  repeat_gap: 1,
  evergreen: '',
  plan_status: 'Approved',
}

export const firstCommentChannels = [
  'facebook',
  'instagram',
  'linkedin',
  'youtube',
]

export const defaultTiktokOptions = {
  post_type: 'video',
  publishing_method: 'direct',
  privacy_level: '',
  disable_comment: true,
  disable_duet: true,
  disable_stitch: true,
  auto_add_music: false,
}
