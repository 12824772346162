<template>
  <b-modal
      id="add-bluesky-modal"
      @hidden="onModalHide"
      hide-header
      hide-footer
      centered
      dialog-class="!w-[484px]"
      no-close-on-backdrop
  >
    <div class="relative">
      <!-- Modal header with close button -->
      <div class="w-full flex justify-between px-4 pt-5">
        <div>
          <div class="w-full flex items-center gap-x-[8px]">
            <p class="font-medium text-lg text-[#202324] select-none">Connect Bluesky Account</p>
          </div>
          <div class="w-full flex items-center gap-x-[8px]">
            <p class="text-sm text-[#757A8A] select-none">Connect your Bluesky account to start posting.</p>
          </div>
        </div>
        <button
            v-tooltip="'Close'"
            @click="$bvModal.hide('add-bluesky-modal')"
            class="btn h-max text-gray-400 hover:shadow px-2 py-0.5 bg-[#3A45570D]"
        >
          <i class="font-light fas fa-times"></i>
        </button>
      </div>

      <!-- Main modal body -->
      <div class="px-4 w-full flex flex-col justify-center items-center">
        <div class="w-full space-y-6 py-5">
          <!-- Username input -->
          <div class="w-full">
            <label for="bluesky-name" class="block mb-2 font-medium text-gray-900">
              Username <span class="text-red-600">*</span>
              <i
                  v-tooltip="'On Bluesky, find your username (handle) below your display name. Enter it without the @symbol, like: handle.bsky.social'"
                  class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
              ></i>
            </label>
            <input
                id="bluesky-name"
                type="text"
                v-model="state.name"
                placeholder="Enter your Bluesky handle (e.g. example.bsky.social, without @)"
                class="w-full border border-gray-300 rounded-lg !text-gray-900 px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-100 color-border cst-editor"
                @input="validateField('name')"
            />
            <p v-if="state.errors.name.error" class="mt-1 text-sm text-red-600">{{ state.errors.name.message }}</p>
          </div>

          <!-- Password input -->
          <div class="w-full">
            <label for="bluesky-password" class="block mb-2 font-medium text-gray-900">
              Password <span class="text-red-600">*</span>
              <i
                  v-tooltip="'Use an App Password instead of your main password. Create one in Bluesky Settings > App Passwords. This keeps your account secure.'"
                  class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
              ></i>
            </label>
            <input
                id="bluesky-password"
                type="password"
                v-model="state.password"
                placeholder="Your App Password from Bluesky Settings"
                class="w-full border border-gray-300 rounded-lg !text-gray-900 px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-100 color-border cst-editor"
                @input="validateField('password')"
            />
            <p v-if="state.errors.password.error" class="mt-1 text-sm text-red-600">{{ state.errors.password.message }}</p>
          </div>

          <!-- Follow checkbox -->
          <div v-if="!account" class="field_group">
            <div class="checkbox_container">
              <label class="pl-[2rem]" for="follow_us_bluesky" style="line-height: normal;">
                Follow ContentStudio
                <input
                    id="follow_us_bluesky"
                    v-model="state.follow_us"
                    type="checkbox"
                    checked="checked"
                />
                <span class="check"></span>
              </label>
            </div>
          </div>

          <!-- Action button -->
          <div class="flex justify-end items-center pt-4">
            <CstButton @click.prevent="addGroup" :disabled="state.showLoader">
              <span>{{ action }} Account</span>
              <clip-loader
                  v-if="state.showLoader"
                  class="spinner ml-2"
                  :color="'#e0dfdf'"
                  :size="'12px'"
              ></clip-loader>
            </CstButton>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { inject, reactive, watch } from 'vue'
import CstButton from '@ui/Button/CstButton.vue'
import { integrationsBaseURL } from '@src/config/api-utils'
import { useStore } from '@state/base'
import proxy from '@common/lib/http-common'
import { UNKNOWN_ERROR } from '@common/constants/messages'

const root = inject('root')
const { $bvModal } = root
const { getters, dispatch } = useStore()

const emit = defineEmits(['close'])

const props = defineProps({
  account: {
    type: Object,
    default: null,
  },
  action: {
    type: String,
    default: 'Add',
  },
  token: {
    type: String,
    default: '',
  }
})

const state = reactive({
  id: '',
  name: '',
  password: '',
  workspace_id: '',
  follow_us: false,
  showLoader: false,
  errors: {
    name: {
      error: false,
      message: ''
    },
    password: {
      error: false,
      message: ''
    }
  }
})

const validateField = (field) => {
  state.errors[field].error = false
  state.errors[field].message = ''

  if (field === 'name') {
    if (!state.name.trim()) {
      state.errors.name.error = true
      state.errors.name.message = 'Username is required.'
      return false
    }
  }

  if (field === 'password') {
    if (!state.password.trim()) {
      state.errors.password.error = true
      state.errors.password.message = 'Password is required.'
      return false
    }
  }

  return true
}

const validateForm = () => {
  const isNameValid = validateField('name')
  const isPasswordValid = validateField('password')
  return isNameValid && isPasswordValid
}

watch(
    () => props.account,
    (newValue) => {
      if (newValue) {
        state.id = newValue.id || ''
        state.name = newValue.name || ''
      }
    }
)

const addGroup = async () => {
  if (!validateForm()) {
    return
  }

  const payload = {
    id: state.id,
    name: state.name,
    password: state.password,
    workspace_id: getters.getActiveWorkspace._id || '',
    follow_us: state.follow_us,
  }

  state.showLoader = true

  const apiURI = props.token?.length ? `${integrationsBaseURL}addBlueskyProfile/easy-connect` : `${integrationsBaseURL}bluesky/addProfile`
  const config = props.token?.length ? {headers: {'external-link-token': props.token}} : {}

  try {
    const response = await proxy.post(apiURI, payload, config)
    handleResponse(response.data)
  } catch (error) {
    console.error('Error while adding Bluesky account', error)
    dispatch('toastNotification', {
      message: error.response?.data?.message || error.message || UNKNOWN_ERROR,
      type: 'error',
    })
  }

  state.showLoader = false
}

const handleResponse = (response) => {
  if (response.status) {
    $bvModal.hide('add-bluesky-modal')

    if(props.token?.length) {
      window.location.reload()
      return
    }

    dispatch('fetchSocialAccounts', true)
    dispatch('toastNotification', {
      message: response.message,
      type: 'success',
    })
    dispatch('fetchPlatformsList')
  } else {
    dispatch('toastNotification', {
      message: response.message || UNKNOWN_ERROR,
      type: 'error',
    })
  }
}

const onModalHide = () => {
  state.name = ''
  state.password = ''
  state.follow_us = false
  state.showLoader = false
  state.errors = {
    name: {error: false, message: ''},
    password: {error: false, message: ''}
  }
  emit('close')
}
</script>
