<template>
  <div
    class="bg-white p-5 rounded-md w-full relative flex"
    :class="{ 'color-border cst-editor': !isModal }"
  >
    <div class="flex-1" :class="customClass">
      <div class="mb-6 w-full flex items-center gap-4">
        <slot v-if="!isModal" name="card-header" :is-modal="isModal" />
        <slot name="modal-header" :is-modal="isModal" />

        <div class="ml-auto flex justify-center items-center bg-transparent">
          <!-- Ai Insights Button-->
          <CstButton
            v-if="showInsightsButton"
            class="!text-black-100 !rounded-md !bg-[#FCFCFC] hover:!bg-gray-100"
            size="small"
            variant="outlined"
            @click="showModal"
          >
            <img
              src="@src/assets/img/analytics/ai-insights.svg"
              alt="ai-insights"
              class="w-5 h-5 mr-1 transition-transform group-hover:scale-105"
            />
            <span class="text-nowrap"> AI Insights </span>
          </CstButton>
          <div
            v-if="!isModal && enableModal && !isReportView"
            v-tooltip="{
              content: 'Maximize',
              theme: 'light',
            }"
            class="cursor-pointer w-10 h-10 flex justify-center items-center bg-gray-50 hover:bg-gray-100 active:bg-gray-300 rounded-full"
            @click="showModal"
          >
            <img
              src="@src/assets/img/full_screen.svg"
              alt="fullScreen"
              class="w-5 h-5"
            />
          </div>
          <div class="absolute w-[1vw] h-[1vh] top-[3vh] left-[95vw] p-[2vh] rounded-xl bg-[#3A45570D] flex justify-center items-center">
            <div
              v-if="isModal && !isReportView"
              v-tooltip="{
                content: 'Minimize',
                theme: 'light',
              }"
              class="cursor-pointer w-[1vw] h-[1vh] flex justify-center items-center rounded-full"
              @click="hideModal"
            >
              <i class="fa fa-times fa-lg" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      <slot
        name="card-body"
        :is-modal="isModal"
        :show-modal="showModal"
        :hide-modal="hideModal"
      />
      <b-modal
        :id="`analytics-wrapper-${type}-modal`"
        centered
        hide-footer
        hide-header
        content-class="mt-2 !rounded-none h-screen overflow-scroll"
        dialog-class="cst-modal"
        @hide="commonMethods.toggleWidgets(false)"
        @shown="commonMethods.toggleWidgets(true)"
      >
        <AnalyticsCardWrapper
          :is-modal="true"
          :enable-modal="enableModal"
          :type="type"
          class="flex flex-col overflow-hidden"
          @toggle-is-modal="toggleIsModal"
        >
          <template v-slot:modal-header>
            <slot name="card-header" />
          </template>
          <template v-slot:card-body="slotProps">
            <div class="flex flex-col h-full">
              <!-- Graph section - top half -->
              <div class="h-1/2 pb-4 border-b border-gray-200">
                <slot
                  name="card-body"
                  :is-modal="slotProps.isModal"
                  :show-modal="slotProps.showModal"
                  :hide-modal="slotProps.hideModal"
                  :is-insights="showInsights"
                />
              </div>
              <!-- AI Insights section - bottom half -->
              <div class="mt-4 flex gap-2 justify-start">
                <CstSwitch
                  id="insights-toggle"
                  :value="showInsights"
                  size="small"
                  @input="toggleInsights"
                />
                <label class="text-[13px] font-medium" for="insights-toggle">Insights</label>
              </div>
            </div>
            <!-- AI Insights section - bottom half -->
            <transition name="slide-fade">
              <div v-show="showInsights" class="h-1/2 pt-4">
                <AiInsightsCard :section="type" :selected-dropdown-option="selectedDropdownOption"/>
              </div>
            </transition>
          </template>
        </AnalyticsCardWrapper>
      </b-modal>
    </div>
    <slot name="card-sidebar" :is-modal="isModal" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, inject, ref } from 'vue'
import CstButton from '@ui/Button/CstButton.vue'
import CstSwitch from '@ui/Switch/CstSwitch'
import { commonMethods } from '@common/store/common-methods'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import AiInsightsCard from './AiInsightsCard.vue'

const emit = defineEmits(['toggleIsModal'])

const root = inject('root')
const { $bvModal } = root
const { isReportView } = useAnalytics()

const showInsights = ref(true)

const toggleInsights = (value) => {
  showInsights.value = value
}

const props = defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  enableModal: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: '',
  },
  customClass: {
    type: String,
    default: '',
  },
  showInsightsButton: {
    type: Boolean,
    default: false,
  },
  selectedDropdownOption: {
    type: String,
    default: '',
  },
})

const showModal = () => {
  $bvModal.show(`analytics-wrapper-${props.type}-modal`)
  toggleIsModal(true)
}

const hideModal = () => {
  $bvModal.hide(`analytics-wrapper-${props.type}-modal`)
  toggleIsModal(false)
}

const toggleIsModal = (value) => {
  emit('toggleIsModal', value)
}
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1);
  max-height: 1000px;
  opacity: 1;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
  transform: translateY(-30px);
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  max-height: 1000px;
  opacity: 1;
  transform: translateY(0);
}
</style>
