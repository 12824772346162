<template>
  <div class="chart-container" ref="chartContainer"></div>
</template>

<script setup>
import { computed, watch, onMounted, onUnmounted, ref, nextTick } from 'vue'
import * as echarts from 'echarts/core'
import { LineChart } from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([
  CanvasRenderer,
  LineChart,
  GridComponent,
  TooltipComponent,
  TitleComponent
])

const props = defineProps({
  data: {
    type: Object,
    required: true,
    validator: (value) => {
      console.log('Chart data:', value)
      return value.buckets && value.values &&
             Array.isArray(value.buckets) &&
             Array.isArray(value.values) &&
             value.buckets.length === value.values.length
    }
  }
})

const chartContainer = ref(null)
let chartInstance = null

const formatDate = (date) => {
  if (date.includes('-')) {
    return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
  }
  return date
}

const getChartOptions = () => {
  const formattedDates = props.data.buckets.map(formatDate)
  console.log('Formatted dates:', formattedDates)
  console.log('Values:', props.data.values)

  return {
    animation: false,
    grid: {
      top: 40,
      right: 20,
      bottom: 40,
      left: 60,
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const [param] = params
        return `${param.name}<br/>${param.value}`
      }
    },
    xAxis: {
      type: 'category',
      data: formattedDates,
      axisLabel: {
        fontSize: 12,
        color: '#4A4A4A',
        rotate: 0
      },
      axisTick: {
        alignWithLabel: true
      }
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        fontSize: 12,
        color: '#4A4A4A'
      },
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: '#E5E7EB'
        }
      }
    },
    series: [
      {
        data: props.data.values,
        type: 'line',
        smooth: true,
        showSymbol: true,
        symbolSize: 6,
        lineStyle: {
          width: 2,
          color: '#2563EB'
        },
        itemStyle: {
          color: '#2563EB'
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(37, 99, 235, 0.2)'
              },
              {
                offset: 1,
                color: 'rgba(37, 99, 235, 0)'
              }
            ]
          }
        }
      }
    ]
  }
}

const initChart = async () => {
  if (!chartContainer.value) return

  // Dispose of existing instance if any
  if (chartInstance) {
    chartInstance.dispose()
    chartInstance = null
  }

  // Wait for next tick to ensure container is in DOM
  await nextTick()

  // Force container dimensions before initialization
  const parentWidth = chartContainer.value.parentElement.clientWidth
  const parentHeight = chartContainer.value.parentElement.clientHeight || 300
  chartContainer.value.style.width = `${parentWidth}px`
  chartContainer.value.style.height = `${parentHeight}px`

  // Initialize chart with explicit dimensions
  chartInstance = echarts.init(chartContainer.value, null, {
    width: parentWidth,
    height: parentHeight
  })

  const options = getChartOptions()
  chartInstance.setOption(options, true)

  // Force another resize to ensure dimensions are correct
  chartInstance.resize({
    width: parentWidth,
    height: parentHeight
  })
}

const handleResize = () => {
  if (chartInstance) {
    chartInstance.resize()
  }
}

// Watch for data changes
watch(() => props.data, async (newData) => {
  console.log('Data changed:', newData)
  if (newData.buckets && newData.values) {
    await initChart()
  }
}, { deep: true, immediate: true })

let resizeObserver = null

onMounted(async () => {
  await initChart()
  
  // Set up resize observer
  resizeObserver = new ResizeObserver(() => {
    if (chartInstance && chartContainer.value) {
      const width = chartContainer.value.parentElement.clientWidth
      const height = chartContainer.value.parentElement.clientHeight || 300
      chartInstance.resize({
        width,
        height
      })
    }
  })
  
  if (chartContainer.value) {
    resizeObserver.observe(chartContainer.value.parentElement)
  }
  
  window.addEventListener('resize', handleResize)
})

onUnmounted(() => {
  if (chartInstance) {
    chartInstance.dispose()
    chartInstance = null
  }
  if (resizeObserver) {
    resizeObserver.disconnect()
    resizeObserver = null
  }
  window.removeEventListener('resize', handleResize)
})
</script>

<style scoped>
.chart-container {
  width: 100%;
  height: 100%;
  min-height: 300px;
}
</style>
