<template>
  <div
    :class="{
      'h-[40vh]': isLoadingStates.ai_insights,
      'h-auto': !isLoadingStates.ai_insights,
    }"
    class="bg-gray-cs-50 rounded-lg border border-gray-200 relative"
  >
    <!-- Loading state -->
    <div
      v-if="isLoadingStates.ai_insights"
      class="flex items-center justify-center h-full"
    >
      <ClipLoader size="32px" color="#1F2937" />
    </div>

    <!-- Empty state -->
    <div
      v-else-if="!aiInsightsData || !aiInsightsData.length"
      class="absolute inset-0 flex items-center justify-center text-gray-500"
    >
      No insights available at the moment
    </div>

    <!-- Content when loaded -->
    <div v-else class="space-y-4 pb-4">
      <div
        v-for="sectionData in aiInsightsData"
        :key="sectionData.category"
        class="mt-6 ml-6 mr-6 flex flex-col gap-2 color-border rounded-xl bg-white shadow-[0px_3px_10px_0px_rgba(211,217,236,0.30)]"
        @click="toggleSection(sectionData.category)"
      >
        <CstCollapsible
          :id="sectionData.category"
          :open="openCollapsible(sectionData.category)"
        >
          <template v-slot:header>
            <div class="flex items-center gap-2">
              <img
                :src="FollowersGrowthIcon"
                alt="Followers Growth"
                class="w-6 h-6"
              />
              <h3 class="font-medium" style="font-size: 16px">
                {{ sectionData.category }}
              </h3>
            </div>
          </template>
          <div
            v-for="insight in sectionData.insights"
            :key="insight.title"
            class="pl-8 pt-16 pb-16 ml-6"
          >
            <h5 class="font-medium" style="font-size: 14px">{{
              insight.title
            }}</h5>
            <p class="text-gray-900 pt-1" style="font-size: 13px">
              {{ insight.description }}
            </p>
            <ul v-if="insight.recommendations" class="list-disc pl-5 mt-2">
              <li
                v-for="(recommendation, index) in insight.recommendations"
                :key="index"
                class="text-gray-900"
              >
                {{ recommendation }}
              </li>
            </ul>
            <div
              v-if="insight?.data && insight?.data?.buckets"
              class="mt-4 chart-wrapper"
            >
              <ForecastLineChart :data="insight.data" />
            </div>
          </div>
        </CstCollapsible>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, watch } from 'vue'
import CstCollapsible from '@ui/Collapse/CstCollapsible'
import ForecastLineChart from '@src/modules/analytics_v3/components/ForecastLineChart.vue'
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import ClipLoader from '@src/modules/common/components/spinner/ClipLoader'
import FollowersGrowthIcon from '@/src/assets/img/icons/analytic/colored/followers-growth-icon.svg'

const props = defineProps({
  section: {
    type: String,
    required: true,
  },
  selectedDropdownOption: {
    type: String,
    required: false,
    default: '',
  },
})

const { fetchMedia, routes, isLoadingStates, aiInsightsData } =
  useFacebookAnalytics()

const activeSection = ref(null)

const sectionMapping = {
  'impressions-chart-fb': 'page_impressions',
  'engagements-chart-fb': 'page_engagement',
  'Publishing Behaviour by Impressions': 'publishing_behaviour_impressions',
  'Publishing Behaviour by Engagements': 'publishing_behaviour_engagements',
  'Publishing Behaviour by Reach': 'publishing_behaviour_reach',
  'audience-growth-fb': 'audience_growth',
  'Video Insights by Views': 'video_views',
  'Video Insights by Watch Time': 'video_watch_time',
  'Video Insights by Engagements': 'video_engagements',
  'Reels Performance by Initial Plays': 'reels_initial_plays',
  'Reels Performance by Watch Time (sec)': 'reels_watch_time',
  'Reels Performance by Engagement': 'reels_engagement',
}

const fetchInsights = async () => {
  await fetchMedia(routes.AI_INSIGHTS, {
    type:
      sectionMapping[props.section] ??
      sectionMapping[props.selectedDropdownOption],
  })
  if (aiInsightsData.value && aiInsightsData.value.success) {
    aiInsightsData.value = aiInsightsData.value.insights.key_insights
    activeSection.value = aiInsightsData.value[0]?.category
  }
}

onMounted(fetchInsights)

// Watch for changes in selectedDropdownOption
watch(
  () => props.selectedDropdownOption,
  (newValue) => {
    if (newValue) {
      fetchInsights()
    }
  }
)

const openCollapsible = (section) => {
  return activeSection.value === section
}

const toggleSection = (section) => {
  if (section === activeSection.value) {
    activeSection.value = null
    return
  }
  activeSection.value = section
}
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
}
.chart-wrapper {
  height: 300px;
}
.list-disc {
  list-style-type: none;
}
.list-disc li::before {
  content: '\2022';
  color: #2563eb; /* Custom bullet color */
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
h5 {
  padding-bottom: 2px;
  margin-bottom: 8px;
}
.pl-8 {
  padding-top: 16px;
  padding-bottom: 16px;
  margin-right: 16px;
}
.pl-8:not(:last-child) {
  border-bottom: 1px solid #70707029;
  margin-bottom: 0;
}
</style>
