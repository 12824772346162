import {POST_STATE, SUPPORTED_PLATFORMS} from '@src/modules/common/constants/composer'
import * as _ from 'underscore'
import moment from 'moment'
import { useStore } from '@/src/store/base'

export default function useSocialMediaUtils() {
  const store = useStore()
  const isPlanPublished = (plan) => plan?.post_state === POST_STATE.PUBLISHED
  const isPlanFailed = (plan) => plan?.post_state === POST_STATE.FAILED
  const isPlanScheduled = (plan) => plan?.post_state === POST_STATE.SCHEDULED
  const isPlanDraft = (plan) => plan?.post_state === POST_STATE.DRAFT
  const isPlanMissedReview = (plan) => plan?.post_state === POST_STATE.MISSED_REVIEW
  const isPlanInReview = (plan) => plan?.post_state === POST_STATE.REVIEWED
  const isPlanRejected = (plan) => plan?.post_state === POST_STATE.REJECTED
  const isPlanInProcessing = (plan) => plan?.post_state === POST_STATE.PROCESSING || plan?.post_state === POST_STATE.QUEUED

  const channelImage = (account, type = undefined) => {
    if (!type) {
      type = account.channel_type
    }

    const image =
      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    const gmbImage =
      'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
    if (!account) return image

    if (account && account.platform_logo) return account.platform_logo

    switch (type) {
      case SUPPORTED_PLATFORMS.FACEBOOK:
        return account.image
          ? account.image
          : account.picture && account.picture.url
          ? account.picture.url
          : image
      case SUPPORTED_PLATFORMS.TWITTER:
      case SUPPORTED_PLATFORMS.LINKEDIN:
      case SUPPORTED_PLATFORMS.PINTEREST:
        return account.image && account.image.large && account.image.large.url
          ? account.image.large.url
          : account.image
          ? account.image
          : image
      case SUPPORTED_PLATFORMS.INSTAGRAM:
        return account.image
          ? account.image
          : account.profile_picture
          ? account.profile_picture
          : image
      case 'medium':
        return account.imageUrl ? account.imageUrl : image
      case 'gmb':
        return account.location_name ? gmbImage : image
    }
  }

  const onError = (event) => {
    event.target.src =
      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
  }

  const getSocialImage = (platform) => {
    switch (platform) {
      case 'facebook':
        return 'facebook-rounded.svg'
      case 'twitter':
        return 'twitter-x-rounded.svg'
      case 'linkedin':
        return 'linkedin-rounded.svg'
      case 'instagram':
        return 'instagram-rounded.svg'
      case 'pinterest':
        return 'pinterest-rounded.svg'
      case 'youtube':
        return 'youtube-rounded.png'
      case 'gmb':
        return 'gmb-rounded.svg'
      case 'tumblr':
        return 'tumblr-rounded.svg'
      case 'tiktok':
        return 'tiktok-rounded.svg'
      case 'threads':
        return 'threads-rounded.svg'
      case 'bluesky':
        return 'bluesky-rounded.svg'
    }
  }

  const accountIdentifier = (account, platformName) => {
    if (!account || !platformName) return null

    let key = ''
    switch (platformName) {
      case 'pinterest':
        key = account.board_id ? 'board_id' : 'pinterest_id'
        break
      case 'tumblr':
      case 'tiktok':
      case 'youtube':
      case 'threads':
      case 'bluesky':
        key = 'platform_identifier'
        break
      case 'gmb':
        key = 'name'
        break
      default:
        key = platformName.toLowerCase() + '_id'
    }
    return account[key]
  }

  const getInstagramAccountsWithPermissionsIssue = () => {
    const selectedAccounts = [] // will contain all the details for the IG accounts selected
    for (const acc in store.getters.getInstagramAccounts.all_items) {
      if (Object.hasOwn(store.getters.getInstagramAccounts.all_items, 'acc')) {
        selectedAccounts.push(store.getters.getInstagramAccounts.all_items[acc])
      }
    }

    const allAccessAccounts = [] // will contain profiles with IG publishing permissions
    const noAccessAccounts = [] // will contain profiles which do not have IG publishing permissions

    // if selected accounts are found ...
    if (selectedAccounts.length > 0) {
      for (let x = 0; x < selectedAccounts.length; x++) {
        // loop over each account and segregate into seperate arrays accordingly
        const item = selectedAccounts[x]
        if (!hasInstaDirectAPIPermission(item)) {
          noAccessAccounts.push(item)
        } else {
          allAccessAccounts.push(item)
        }
      }
    }

    // update the instagramSelection variable from data to the new selected data
    return {
      no_access_accounts: noAccessAccounts,
      all_access_accounts: allAccessAccounts,
    }
  }

  const hasInstaDirectAPIPermission = (channel) => {
    if (channel.permissions) {
      const publishAPIPermission = _.findWhere(channel.permissions, {
        permission: 'instagram_content_publish',
      })
      return publishAPIPermission
        ? publishAPIPermission.status === 'granted'
        : false
    }
    return false
  }

  const cardHasExpired = () => {
    if (store.getters.getBillingDetails.payment_information) {
      const expiryDate =
        store.getters.getBillingDetails.payment_information.expiry_date
      if (expiryDate) {
        const now = moment()
        const f = moment(expiryDate, 'MM/YYYY')
        const diff = f.diff(now, 'days')

        if (diff > 0 && diff <= 30) {
          // has not expired yet .. still 30 days to go
          return false
        } else if (diff <= 0) {
          // card has expired or will expire today
          return true
        } else {
          return null
        }
      }
    }

    return null
  }

  return {
    cardHasExpired,
    getInstagramAccountsWithPermissionsIssue,
    hasInstaDirectAPIPermission,
    channelImage,
    onError,
    isPlanPublished,
    isPlanFailed,
    isPlanInProcessing,
    isPlanInReview,
    isPlanRejected,
    isPlanMissedReview,
    isPlanScheduled,
    getSocialImage,
    accountIdentifier,
    isPlanDraft
  }
}
