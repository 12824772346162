<template>
  <div class="threads-carousel w-full h-full" :class="{ 'threads-carousel-reply': isReply }">
    <Slick ref="carousel" :key="carouselKey" :options="slickOptions">
      <template v-for="(image, index) in computedMediaItems" :key="`threads_media_${index}`">
        <div
            v-if="typeof image === 'string' && !image"
            class="max-h-[470px] w-full block relative"
        >
          <div class="block overflow-hidden relative bg-white pt-[100%]">
            <img
                class="w-full h-full absolute top-0 left-0"
                src="@src/assets/img/no_data_images/no_media_found.svg"
                alt=""
            />
          </div>
        </div>
        <div
            v-else-if="typeof image === 'string'"
            class="max-h-[470px] w-full block relative"
        >
          <div class="block overflow-hidden relative pt-[100%]">
            <img
                v-tooltip="{
                      content: 'Image Preview',
                      placement: 'auto',
                     }"
                class="w-full h-full absolute top-0 left-0 border object-cover rounded-md"
                :src="image"
                alt=""
                @click.prevent="$emit('display-file', 'image', [image], 0)"
                @error="
                      (event) => {
                        event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                      }
                    "
            />
          </div>
        </div>
        <div
            v-else
            class="block overflow-hidden relative pt-[100%] text-white text-center"
        >
          <img
              class="w-full h-full absolute top-0 border left-0 object-cover rounded-md"
              :src="getVideoThumbnail(image)"
              alt="thumbnail"
          />
          <i
              class="fas fa-play cursor-pointer absolute top-1/2 left-1/2 pl-[1.2rem] pt-4 pb-4 pr-4 rounded-full bg-[#00000070] border-solid -translate-x-1/2 -translate-y-1/2"
              @click.prevent="$emit('display-file', 'video',
                image.converted_video || image.link,
                0)
                "
          ></i>
        </div>
      </template>
    </Slick>
  </div>
</template>

<script setup>
import Slick from 'vue-slick'
import {computed, defineProps, ref, watch} from 'vue'

// Create a ref for the carousel
const carousel = ref(null)
// Key to force re-render
const carouselKey = ref(0);
const slickOptions = ref({
  centerMode: true,
  slidesToShow: 1,
  autoplay: false,
  infinite: false,
  draggable: false,
  prevArrow:
      '<button class="h-8 w-8 bg-[#202324] hover:bg-black text-white border rounded-full focus:outline-none absolute shadow" style="top: 50%; transform: translateY(-50%); left: 14px; z-index: 9; background: rgba(130, 125, 118, 0.57) none repeat scroll 0% 0%"><i class="fas fa-chevron-left"></i></button>',
  nextArrow:
      '<button class="h-8 w-8 bg-[#202324] hover:bg-black text-white border rounded-full focus:outline-none absolute shadow" style="top: 50%; transform: translateY(-50%); right: 14px; z-index: 9; background: rgba(130, 125, 118, 0.57) none repeat scroll 0% 0%"><i class="fas fa-chevron-right"></i></button>',
})

defineEmits(['change-load-more', 'display-file'])

const props = defineProps({
  detail: {
    type: Object,
    default: () => ({}),
    required: false,
  },
  hideFooter: {
    type: Boolean,
    default: false,
    required: false,
  },
  isReply: {
    type: Boolean,
    default: false
  }
})

const computedMediaItems = computed(() => {
  return props.detail?.multimedia?.length ? props.detail.multimedia : (props.detail?.image?.length ? props.detail.image : props.detail?.video?.link ? [props.detail.video] :  [''])
})

const getVideoThumbnail = (item) => {
  let thumbnail = item?.thumbnails_suggestions?.length ? item?.thumbnails_suggestions[0] : item.thumbnail
  if (thumbnail)
      // custom thumbnail is supported only for reels
    return thumbnail
  // If the thumbnail url includes the /media_library/, then it is a custom thumbnail.
  // If the thumbnail url includes the ?offset= then it is 'choose from video' thumbnail.
  // If its custom thumbnail and not 'choose from video' then use the first thumbnail
  // from the suggestions as custom thumbnail is not supported in instagram.
  if (
      thumbnail.indexOf('/media_library/') > -1 &&
      thumbnail.indexOf('?offset=') === -1
  ) {
    thumbnail = item.thumbnails_suggestions[0]
  }
  return thumbnail
}

watch(computedMediaItems, () => {
  // Force re-render by changing the key
  carouselKey.value++;
}, {deep: true})


</script>

<style lang="less">
.threads-carousel {
  .slick-slider {
    .slick-list {
      padding: 0 14px !important;

      .slick-track {
        .slick-slide {
          margin-right: 4px;
        }
      }
    }
  }
}

.threads-carousel-reply {
  .slick-slider {
    margin-left: 3rem;
  }
}
</style>
