import { bluesky } from '@src/modules/integration/store/states/mutation-types'

const getDefaultBlueskyAccountsState = () => {
    return {
        items: [],
        all_items: []
    }
}

export default {
    state: {
        accounts: getDefaultBlueskyAccountsState()
    },
    getters: {
        getBlueskyAccounts: (state) => state.accounts,
    },
    actions: {},
    mutations: {
        [bluesky.SET_ACCOUNTS] (state, account) {
            state.accounts.items = account.sort((a,b)=> a.platform_name.toLowerCase().localeCompare(b.platform_name.toLowerCase()))
        },

        [bluesky.SET_ALL_ACCOUNTS] (state, account) {
            state.accounts.all_items = account.sort((a,b)=> a.platform_name.toLowerCase().localeCompare(b.platform_name.toLowerCase()))
        },

        [bluesky.SET_DEFAULT] (state) {
            state.accounts = getDefaultBlueskyAccountsState()
        },
    }
}
