import { computed, ref } from 'vue'
import proxy from '@common/lib/http-common'
import useDateFormat from '@common/composables/useDateFormat'
import { useStore } from '@state/base'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@common/composables/useNumber'
import EyeIcon from '@src/assets/img/icons/analytic/eye-icon.svg'
import PointSelectIcon from '@src/assets/img/icons/analytic/point-select.svg'
import GraphUpIcon from '@src/assets/img/icons/analytic/graph-up.svg'
import ImageIcon from '@src/assets/img/icons/analytic/image-icon.svg'
import SaveIcon from '@src/assets/img/icons/analytic/save.svg'

import FollowersGrowthIcon from '@/src/assets/img/icons/analytic/colored/outlined/followers-color-outlined-icon.svg'
import EngagementsColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/engagement-green-color-outlined-icon.svg'
import ImpressionPageIcon from '@/src/assets/img/icons/analytic/colored/outlined/impressions-pink-color-outlined-icon.svg'
import PostClickIcon from '@/src/assets/img/icons/analytic/colored/outlined/clicks-color-outlined-icon.svg'
import OutboundClickIcon from '@/src/assets/img/icons/analytic/colored/outlined/link-click-color-outlined-icon.svg'
import SavesColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/save-color-outlined-icon.svg'

// constants
const selectedAccount = ref(null)
const { momentWrapper } = useDateFormat()
const routes = {
  OVERVIEW_SUMMARY: 'overviewSummary',
  FOLLOWERS_TREND: 'overviewFollowers',
  IMPRESSIONS_TREND: 'overviewImpressions',
  ENGAGEMENTS_TREND: 'overviewEngagement',
  PIN_POSTING_PER_DAY: 'overviewPinPostingPerDay',
  PIN_POSTING_ROLLUP: 'overviewPinPostingRollup',
  TOP_PERFORMING_PINS: 'overviewTopPins',
  LEAST_PERFORMING_PINS: 'overviewLeastPins',
  PIN_POSTING_PERFORMANCE: 'overviewPinPostingPerformance',
  TOP_PINS_DATA: 'overviewTopPins',
}

const performanceFilterCheckbox = [
  { label: 'All' },
  { label: 'Image' },
  { label: 'Video' },
]
const PostingByDayDropdown = [
  { label: 'All' },
  { label: 'Image' },
  { label: 'Video' },
]

const TopLeastEngagementDropdown = [
  { label: 'Impressions', api_key: 'impressions' },
  { label: 'Engagements', api_key: 'engagement_rate' },
  { label: 'Pin Clicks', api_key: 'pin_clicks' },
  { label: 'Outbound Clicks', api_key: 'outbound_clicks' },
  { label: 'Saves', api_key: 'saves' },
]

const postModalFields = [
  {
    label: 'Impressions',
    key: 'impressions',
    iconSrc: EyeIcon,
    tooltip: `The total number of times your pin has been displayed on users' screens during the selected time period, regardless of the pin publication date.`,
  },
  {
    label: 'Total Engagement',
    key: 'engagement',
    iconSrc: PointSelectIcon,
    tooltip: `The total number of engagement actions (pin clicks, outbound clicks, saves) received during the selected time period, regardless of the pin publication date.`,
  },
  {
    label: 'Engagement Rate',
    key: 'engagementRate',
    iconSrc: GraphUpIcon,
    tooltip: `The average number of engagement actions (pin clicks, outbound clicks, saves) per view during the selected time period, expressed as a percentage.`,
  },
  {
    label: 'Pin Clicks',
    key: 'pinClicks',
    iconSrc: PointSelectIcon,
    tooltip: `The total number of times users have clicked on your pin during the selected time period, regardless of the pin publication date.`,
  },
  {
    label: 'Outbound Clicks',
    key: 'outboundClicks',
    iconSrc: PointSelectIcon,
    tooltip: `The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.`,
  },
  {
    label: 'Saves',
    key: 'saves',
    iconSrc: SaveIcon,
    tooltip: `The total number of times users have pinned or saved your pin to their boards, during the selected time period, regardless of the pin publication date.`,
  },
  {
    label: 'Type',
    key: 'mediaType',
    iconSrc: ImageIcon,
    tooltip: `Image or Video.`,
  },
]

// state
const { formatNumber } = useNumber()
const { DEFAULT_DATE_RANGE } = useAnalytics()
const dateRange = ref(DEFAULT_DATE_RANGE)
const postsLimit = ref(10)
const cardsData = ref({})
const followersData = ref({})
const engagementsData = ref({})
const impressionsData = ref({})
const pinsPostingPerformance = ref({})
const pinsPerDay = ref({
  all: {},
  image: {},
  video: {},
})
const miniCardsData = ref({
  all: {},
  image: {},
  video: {},
})

const topAndLeastEngagingPosts = ref({
  top_posts: {
    impressions: [],
    engagement_rate: [],
    pin_clicks: [],
    outbound_clicks: [],
    saves: [],
  },
  least_posts: {
    impressions: [],
    engagement_rate: [],
    pin_clicks: [],
    outbound_clicks: [],
    saves: [],
  },
})

const performanceSelectedCheckbox = ref([performanceFilterCheckbox[0].label])

const selectedPostingByDayType = ref([
  PostingByDayDropdown.find((i) => i.label === 'All')?.label ||
    PostingByDayDropdown[0].label,
])

const selectedTopLeastSortType = ref({
  top: TopLeastEngagementDropdown.find((i) => i.label === 'Impressions'),
  least: TopLeastEngagementDropdown.find((i) => i.label === 'Impressions'),
})

const cards = ref([
  {
    title: 'Followers',
    key: 'follower_count',
    get tooltip() {
      return `The total number of people who have followed your Pinterest ${
        selectedAccount.value?.type ?? 'profile'
      }  up to the end of the selected time period`
    },
    icon: FollowersGrowthIcon,
  },
  {
    title: 'Impressions',
    key: 'impressions',
    tooltip: `The total number of times your pins have been displayed on users' screens during the selected time period, regardless of the pins publication date.`,
    icon: ImpressionPageIcon,
  },
  {
    title: 'Engagements',
    key: 'total_engagement',
    tooltip:
      'The total number of engagement actions (pin clicks, outbound clicks, saves) received during the selected time period, on all the pins regardless of their publication date.',
    icon: EngagementsColorIcon,
  },
  {
    title: 'Clicks',
    key: 'pin_clicks',
    tooltip:
      'The total number of times users have clicked on your pins to view them, during the selected time period, regardless of the pins publication date.',
    icon: PostClickIcon,
  },
  {
    title: 'Outbound Clicks',
    key: 'outbound_clicks',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
    icon: OutboundClickIcon,
  },
  {
    title: 'Saves',
    key: 'saves',
    tooltip:
      'The total number of times users have pinned or saved your pins to their boards, during the selected time period, regardless of the pins publication date.',
    icon: SavesColorIcon,
  },
])

const miniStatsCardKeys = ref([
  {
    title: 'Total Pins',
    key: 'total_pins',
    tooltip:
      'The total number of pins published during the selected time period.',
  },
  {
    title: 'Impressions',
    key: 'impressions',
    tooltip:
      "The total number of times your pins, published during the selected time period, have been displayed on users' screens.",
  },
  {
    title: 'Pin Clicks',
    key: 'pin_clicks',
    tooltip:
      'The total number of times users have clicked on your pins published during the selected time period.',
  },
  {
    title: 'Outbound Clicks',
    key: 'outbound_clicks',
    tooltip:
      'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  },
  {
    title: 'Saves',
    key: 'saves',
    tooltip:
      'The total number of times users have pinned or saved your pins published during the selected time period.',
  },
  {
    title: 'Video Views',
    key: 'video_views',
    tooltip:
      'The total number of times your video pins, published during the selected time period, has been watched for at least 2 seconds with 50% of the video visible on the screen.',
  },
  {
    title: 'Total 10 Sec Play',
    key: 'video_10s_view',
    tooltip:
      'The number of times your video pins, published during the selected time period, was viewed for at least 10 seconds or more.',
  },
  {
    title: 'Avg. Play Time',
    key: 'avg_watch_time',
    tooltip:
      'The average time someone spent playing the video and static image cards included in your pins published during the selected time period.',
  },
])

const getFormattedDateRange = () => {
  return dateRange.value
    .map((date) => momentWrapper(date).format('YYYY-MM-DD'))
    .join(' - ')
}

const getCardData = (card) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: 'Default tooltip',
    icon: card?.icon,
  }

  if (!cardsData.value || !Object.keys(cardsData.value)?.length) {
    return defaultValue
  }

  const { current, percentage, difference } = cardsData.value

  return {
    title: card.title,
    total: current?.[card.key] || defaultValue.total,
    growth: percentage?.[card.key] || defaultValue.growth,
    difference: difference?.[card.key] || defaultValue.difference,
    originalValue: current?.[card.key] || defaultValue.originalValue,
    tooltip: card.tooltip,
    icon: card?.icon,
  }
}
const getMiniCardData = (card, selectedType = 'all') => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: '',
  }

  if (!miniCardsData.value || !Object.keys(miniCardsData.value).length) {
    return defaultValue
  }

  const { current, percentage, difference } =
    getSelectedDailyRollup(selectedType)

  return {
    title: card.title,
    total: current?.[card.key] || defaultValue.total,
    growth: formatNumber(percentage?.[card.key]) || defaultValue.growth,
    difference: difference?.[card.key] || defaultValue.difference,
    originalValue: current?.[card.key] || defaultValue.originalValue,
    tooltip: card?.tooltip || defaultValue.tooltip,
  }
}

const getSelectedDailyRollup = (type) => {
  return miniCardsData.value[type.toLowerCase()]
}

const canFetchMedia = computed(() => {
  return !!(
    selectedAccount.value !== undefined &&
    selectedAccount.value !== null &&
    dateRange.value.length > 0
  )
})

const topEngagingPosts = computed(
  () => topAndLeastEngagingPosts.value.top_posts
)

const leastEngagingPosts = computed(
  () => topAndLeastEngagingPosts.value.least_posts
)
const validPostsTableHeaders = [
  'thumbnail',
  'board',
  'engagementRate',
  'engagement',
  'impressions',
  'clicks',
  'outboundClicks',
  'saves',
]

const nonSortableItems = ['thumbnail', 'description', 'board']

// object mapping of header values to their corresponding titles
const headerTitles = {
  thumbnail: 'Posts',
  board: 'Board',
  engagement: 'Engagement',
  engagementRate: 'Engagement Rate',
  impressions: 'Impressions',
  clicks: 'Clicks',
  outboundClicks: 'Outbound Clicks',
  saves: 'Saves',
}
// object mapping of header api keys to their corresponding titles
const validPostsTableApiKeys = {
  engagement: 'total_engagement',
  engagementRate: 'engagement_rate',
  impressions: 'impressions',
  clicks: 'pin_clicks',
  outboundClicks: 'outbound_clicks',
  saves: 'saves',
}

const headerTooltips = {
  engagementRate:
    "The engagement rate of your pin published during the selected time period, based on the pin's lifetime performance.",
  engagement:
    "The total number of engagement actions (pin clicks, outbound clicks, saves) received on your pin published during the selected time period, based on the pin's lifetime performance.",
  impressions:
    "The total number of times your pin published during the selected time period has been displayed on users' screens, based on the pin's lifetime performance.",
  clicks:
    "The total number of times users have clicked on your pin published during the selected time period, based on the pin's lifetime performance.",
  outboundClicks:
    'The total number of times users have clicked during the selected time period, on your pins to visit the linked website, regardless of the pins publication date.',
  saves:
    "The total number of times users have pinned or saved your pin published during the selected time period, based on the pin's lifetime performance.",
}

// Object mapping of header values to their corresponding formatting methods
const bodyValuesMap = {
  engagement: (value) => formatNumber(value),
  engagementRate: (value) => `${formatNumber(value)}%`,
  thumbnail: (value) => value,
  description: (value) => value,
  board: (value) => value,
  impressions: (value) => formatNumber(value),
  clicks: (value) => formatNumber(value),
  outboundClicks: (value) => formatNumber(value),
  saves: (value) => formatNumber(value),
}

const DUAL_GRAPHS_TITLE_AND_TOOLTIPS = {
  FOLLOWER: {
    daily: {
      title: 'Daily Followers Change',
      tooltip:
        'Inspect the number of new followers gained or lost on each specific day during the selected time period.',
    },
    cumulative: {
      title: 'Cumulative Followers Trend',
      tooltip:
        'Examine the overall growth or decline in your Pinterest followers over time, aggregating daily changes to show a running total, throughout the selected time period.',
    },
  },
}

const DUAL_GRAPHS = {
  FOLLOWER: 'FOLLOWER',
  ENGAGEMENT: 'ENGAGEMENT',
  IMPRESSION: 'IMPRESSION',
}

const getDualGraphTitle = (graphName = '', viewMode = '') => {
  const loweredViewMode = viewMode.toLowerCase()

  switch (graphName) {
    case DUAL_GRAPHS.FOLLOWER:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.FOLLOWER[loweredViewMode]?.title || ''
      )
    default:
      return 'Title here'
  }
}

const getDualGraphTooltip = (graphName = '', viewMode = '') => {
  const loweredViewMode = viewMode.toLowerCase()

  switch (graphName) {
    case DUAL_GRAPHS.FOLLOWER:
      return (
        DUAL_GRAPHS_TITLE_AND_TOOLTIPS?.FOLLOWER[loweredViewMode]?.tooltip || ''
      )
    default:
      return 'ToolTip here'
  }
}

const PERFORMANCE_CHART_TYPES = {
  IMPRESSIONS: 'impressions',
  ENGAGEMENTS: 'engagements',
}

const getPerformanceChartPayload = () => {
  const filterPayload = {}
  const filter = performanceSelectedCheckbox.value[0]

  if (filter === 'Image' || filter === 'Video') {
    filterPayload.filter_by = filter.toLowerCase()
  }
  return filterPayload
}

const getPostingByDayChartPayload = () => {
  const filterPayload = {}
  const filter = selectedPostingByDayType.value[0]

  if (filter === 'Image' || filter === 'Video') {
    filterPayload.filter_by = filter.toLowerCase()
  }
  return filterPayload
}

const getTopLeastEngagingPayload = (type, topPostsLimit = 10) => {
  const filterPayload = {}
  switch (type) {
    case 'top':
      filterPayload.limit = 5
      filterPayload.order_by = selectedTopLeastSortType.value.top.api_key

      break
    case 'least':
      filterPayload.limit = 5
      filterPayload.order_by = selectedTopLeastSortType.value.least.api_key
      break
    case 'report':
      filterPayload.limit = topPostsLimit
      filterPayload.order_by = TopLeastEngagementDropdown.find(
        (i) => i.label === 'Engagements'
      ).api_key
      break
  }
  return filterPayload
}

// method to get the title corresponding to a given header value
const getHeaderTitles = (header = '') => {
  return headerTitles[header] || ''
}

// method to get the tooltip corresponding to a given header value
const getHeaderTooltips = (header = '') => {
  return headerTooltips[header] || ''
}

// method to get the api key corresponding to a given header value
const getHeaderApiKey = (header = '') => {
  return validPostsTableApiKeys[header] || ''
}

// method to get the formatted value for a given header and its corresponding value
const getBodyValues = (header = '', value = '') => {
  return bodyValuesMap[header] ? bodyValuesMap[header](value) : ''
}

const isPostDataLoading = ref(false)

const resetDefaults = () => {
  performanceSelectedCheckbox.value = [performanceFilterCheckbox[0].label]
  selectedPostingByDayType.value = [
    PostingByDayDropdown.find((i) => i.label === 'All')?.label ||
      PostingByDayDropdown[0].label,
  ]
}

export default function usePinterestAnalytics(defaultLabel = 'Data') {
  const { getters } = useStore()

  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    lineChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
  } = useAnalytics(defaultLabel)

  const fetchMedia = async (type, extraPayload = {}, append = false) => {
    if (canFetchMedia.value) {
      try {
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          date: getFormattedDateRange(),
          pinterest_id: selectedAccount.value?.profile_id ?? '',
          timezone: getters.getActiveWorkspace.timezone,
          ...extraPayload,
        }

        if (selectedAccount.value?.type === 'Board') {
          payload.board_id = selectedAccount.value?.board_id ?? ''
        }

        const { data } = await proxy.post(
          BASE_ENDPOINT + 'pinterest/' + type,
          payload
        )

        let leastPosts
        let topPosts

        switch (type) {
          case routes.OVERVIEW_SUMMARY:
            cardsData.value = data.overview
            break
          case routes.FOLLOWERS_TREND:
            followersData.value = data
            break
          case routes.IMPRESSIONS_TREND:
            impressionsData.value = data
            break
          case routes.ENGAGEMENTS_TREND:
            engagementsData.value = data
            break
          case routes.PIN_POSTING_PERFORMANCE:
            pinsPostingPerformance.value = data

            break
          case routes.PIN_POSTING_PER_DAY:
            switch (extraPayload.filter_by) {
              case 'image':
                pinsPerDay.value.image = data
                break
              case 'video':
                pinsPerDay.value.video = data
                break
              default:
                pinsPerDay.value.all = data
                break
            }
            break
          case routes.PIN_POSTING_ROLLUP:
            switch (extraPayload.filter_by) {
              case 'image':
                miniCardsData.value.image = data
                break
              case 'video':
                miniCardsData.value.video = data
                break
              default:
                miniCardsData.value.all = data
                break
            }
            break
          case routes.TOP_PERFORMING_PINS:
            topPosts = data?.top ?? []
            topAndLeastEngagingPosts.value.top_posts[extraPayload?.order_by] =
              topPosts.length > 0 ? transformObject(topPosts) : []
            break
          case routes.LEAST_PERFORMING_PINS:
            leastPosts = data?.least ?? []
            topAndLeastEngagingPosts.value.least_posts[extraPayload?.order_by] =
              leastPosts.length > 0 ? transformObject(leastPosts) : []
            break
          default:
            break
        }
      } catch (e) {
        console.error('FETCH DATA:::', e)
      }
    }
  }

  const transformObject = (data) => {
    return data.map((post) => {
      return {
        id: post.pin_id,
        coverImage: post.cover_image_url,
        thumbnail: post.cover_image_url,
        boardOwner: post.board_owner,
        mediaType: post.media_type,
        creativeType: post.creative_type,
        description: post.description,
        dominantColor: post.dominant_color,
        engagement: +post?.total_engagement || 0,
        impressions: +post.impressions,
        outboundClicks: +post.outbound_clicks,
        link: post.permalink,
        pinClicks: +post.pin_clicks,
        productTags: post.product_tags,
        saves: +post.saves,
        title: post.title,
        engagementRate: +post.engagement_rate,
        embedLink: post.embed_link,
        board: post?.board_name ?? '',
        width: post?.width,
        height: post?.height,
        createdAt: post?.created_at,
      }
    })
  }

  const pinsAndEngagementCharts = ref([
    {
      name: 'Clicks',
      stack: 'Engagements',
      color: '#8AC1A2',
      position: 'left',
      api_response_key: 'pin_clicks',
      yIndex: 0,
    },
    {
      name: 'Outbound Clicks',
      stack: 'Engagements',
      color: '#8081B9',
      position: 'left',
      api_response_key: 'outbound_clicks',
      yIndex: 0,
    },
    {
      name: 'Saves',
      stack: 'Engagements',
      color: '#F2CA6B',
      position: 'left',
      api_response_key: 'saves',
      yIndex: 0,
    },
    {
      name: 'Pins',
      stack: 'Pins',
      color: '#8ABAF3',
      position: 'right',
      api_response_key: 'pins_count',
      yIndex: 1,
    },
  ])

  const pinsAndImpressionsCharts = ref([
    {
      name: 'Impressions',
      stack: 'impressions',
      color: '#F2CA6B',
      position: 'left',
      api_response_key: 'impressions',
      yIndex: 0,
    },
    {
      name: 'Pins',
      stack: 'Pins',
      color: '#8ABAF3',
      position: 'right',
      api_response_key: 'pins_count',
      yIndex: 1,
    },
  ])

  const uniqueStacks = [
    ...new Set(pinsAndEngagementCharts.value.map((chart) => chart.stack)),
  ]
  const uniqueStacksImpressions = [
    ...new Set(pinsAndImpressionsCharts.value.map((chart) => chart.stack)),
  ]

  const generateYAxisName = (stack, performanceChartType = '') => {
    let mappingObject = {}
    if (performanceChartType === PERFORMANCE_CHART_TYPES.ENGAGEMENTS) {
      mappingObject = pinsAndEngagementCharts
    } else if (performanceChartType === PERFORMANCE_CHART_TYPES.IMPRESSIONS) {
      mappingObject = pinsAndImpressionsCharts
    }
    let string = ''
    const titleString = `{b|${stack}}`
    mappingObject.value.forEach((chart) => {
      if (chart.stack === stack) {
        string += `{${chart?.name.split(' ').join('')}|} `
      }
    })
    return string + titleString
  }

  const generateRichtextStyle = (stack, performanceChartType = '') => {
    let mappingObject = {}
    if (performanceChartType === PERFORMANCE_CHART_TYPES.ENGAGEMENTS) {
      mappingObject = pinsAndEngagementCharts
    } else if (performanceChartType === PERFORMANCE_CHART_TYPES.IMPRESSIONS) {
      mappingObject = pinsAndImpressionsCharts
    }
    const richData = {}
    mappingObject.value.forEach((chart) => {
      if (chart.stack === stack) {
        richData[chart?.name.split(' ').join('')] = {
          backgroundColor: chart.color,
          width: 11,
          height: 11,
          borderRadius: 2,
          display: 'inline-block',
          textAlign: 'center',
          lineHeight: 14,
          fontSize: 12,
        }
      }
    })
    return {
      rich: {
        ...richData,
        b: {
          color: '#979CA0',
          fontSize: 12,
          padding: [0, 0, 0, 6],
        },
      },
    }
  }

  const yAxisConfigsEngagements = uniqueStacks.map((stack, i) => ({
    type: 'value',
    sort: 'ascending',
    name: generateYAxisName(stack, PERFORMANCE_CHART_TYPES.ENGAGEMENTS),
    nameTextStyle: generateRichtextStyle(
      stack,
      PERFORMANCE_CHART_TYPES.ENGAGEMENTS
    ),
    position: pinsAndEngagementCharts.value.find(
      (chart) => chart.stack === stack
    ).position,
    nameLocation: 'center',
    nameGap: 40,
    min: 'dataMin',
    axisLabel: {
      color: '#979CA0',
      fontSize: 12,
      fontWeight: 400,
      formatter: (value) => value?.toFixed(0),
    },
    axisTick: {
      show: true,
      alignWithLabel: true,
      interval: 'int',
      lineStyle: {
        color: '#E9EFF6',
      },
    },
    axisLine: {
      lineStyle: {
        color: '#F2F4F6',
      },
    },
    axisPointer: {
      label: {
        backgroundColor: pinsAndEngagementCharts.value.find(
          (chart) => chart.stack === stack
        ).color,
        color: 'white',
        formatter: (dataPoint) =>
          formatNumber(dataPoint?.value) ?? dataPoint.value,
      },
    },
    splitLine: {
      show: i === 0,
      lineStyle: {
        color: '#F2F4F6',
      },
    },
  }))

  const yAxisConfigsImpressions = uniqueStacksImpressions.map((stack, i) => ({
    type: 'value',
    sort: 'ascending',
    name: generateYAxisName(stack, PERFORMANCE_CHART_TYPES.IMPRESSIONS),
    nameTextStyle: generateRichtextStyle(
      stack,
      PERFORMANCE_CHART_TYPES.IMPRESSIONS
    ),
    position: pinsAndImpressionsCharts.value.find(
      (chart) => chart.stack === stack
    ).position,
    nameLocation: 'center',
    nameGap: 40,
    min: 'dataMin',
    axisLabel: {
      color: '#979CA0',
      fontSize: 12,
      fontWeight: 400,
      formatter: (value) => value?.toFixed(0),
    },
    axisTick: {
      show: true,
      alignWithLabel: true,
      interval: 'int',
      lineStyle: {
        color: '#E9EFF6',
      },
    },
    axisLine: {
      lineStyle: {
        color: '#F2F4F6',
      },
    },
    axisPointer: {
      label: {
        backgroundColor: pinsAndImpressionsCharts.value.find(
          (chart) => chart.stack === stack
        ).color,
        color: 'white',
        formatter: (dataPoint) =>
          formatNumber(dataPoint?.value) ?? dataPoint.value,
      },
    },
    splitLine: {
      show: i === 0,
      lineStyle: {
        color: '#F2F4F6',
      },
    },
  }))

  const pinsAndEngagementChartOptions = ref({
    tooltip: defaultChartOptions.tooltip,
    grid: barChartOptions.value.grid,
    dataZoom: barChartOptions.value.dataZoom,
    xAxis: barChartOptions.value.xAxis,
    legend: undefined,
    yAxis: yAxisConfigsEngagements,
    series: [],
  })

  const pinsAndImpressionsChartOptions = ref({
    tooltip: defaultChartOptions.tooltip,
    grid: barChartOptions.value.grid,
    dataZoom: barChartOptions.value.dataZoom,
    xAxis: barChartOptions.value.xAxis,
    legend: undefined,
    yAxis: yAxisConfigsImpressions,
    series: [],
  })

  return {
    analyticsDesignSystem,
    DUAL_GRAPHS,
    postModalFields,

    // variables
    routes,
    legendOptions,
    dataZoomOptions,

    isPostDataLoading,
    performanceFilterCheckbox,
    PostingByDayDropdown,
    TopLeastEngagementDropdown,
    validPostsTableHeaders,
    nonSortableItems,
    validPostsTableApiKeys,

    // state
    selectedAccount,
    cards,
    dateRange,
    topEngagingPosts,
    leastEngagingPosts,
    barChartOptions,
    lineChartOptions,
    pinsAndEngagementChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    pinsAndEngagementCharts,
    pinsAndImpressionsCharts,
    pinsAndImpressionsChartOptions,
    performanceSelectedCheckbox,
    selectedPostingByDayType,
    selectedTopLeastSortType,
    postsLimit,
    isReportView,
    screenWidth,

    cardsData,
    miniStatsCardKeys,
    followersData,
    engagementsData,
    impressionsData,
    pinsPostingPerformance,
    pinsPerDay,
    miniCardsData,
    topAndLeastEngagingPosts,

    // methods
    fetchMedia,
    getCardData,
    getMiniCardData,
    getFormattedDateRange,
    getDualGraphTitle,
    getDualGraphTooltip,
    isNoAnalyticsData,
    getPerformanceChartPayload,
    getPostingByDayChartPayload,
    getTopLeastEngagingPayload,
    getHeaderTitles,
    getHeaderTooltips,
    getHeaderApiKey,
    getBodyValues,
    resetDefaults,
    axisLabelFormatter,
  }
}
